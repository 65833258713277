import React, {useEffect, useState} from "react";
import {BoxModal} from "./BoxModal";
import {Booking, Profile} from "../types";
import {Button, ButtonGroup, CircularProgress} from "@mui/material";
import {GetProfile} from "../api";
import useLogger from "../hooks/useLogger";

interface Props {
  booking: Booking;
  onClose: () => void;
}

const RescheduleModal: React.VFC<Props> = ({booking, onClose}) => {
  const [patientProfile, setPatientProfile] = useState<Profile | null>(null);
  const logger = useLogger();
  useEffect(() => {
    GetProfile(booking.personalNumber)
      .then(setPatientProfile)
      .catch(logger.error);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  if (!patientProfile)
    return (
      <BoxModal open={true} onClose={onClose}>
        <CircularProgress />
      </BoxModal>
    );

  return (
    <BoxModal open={true} onClose={onClose}>
      <ButtonGroup
        fullWidth
        variant="contained"
        orientation="vertical"
        color="inherit">
        <Button
          target="_blank"
          href={`${process.env.REACT_APP_APP_URL}/bookings/${booking.id}/cancel`}>
          Avboka besök
        </Button>{" "}
        <Button
          target="_blank"
          href={`${process.env.REACT_APP_APP_URL}/calendar/${booking.slotKind}?bookingId=${booking.id}&profileId=${booking.bookedPerson.id}&patientProfileId=${patientProfile.id}`}>
          Omboka besök
        </Button>
      </ButtonGroup>
    </BoxModal>
  );
};

export default RescheduleModal;
