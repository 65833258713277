import {
  Button,
  FormControl,
  InputLabel,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Grid,
  ButtonGroup,
  CircularProgress,
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {GetProfiles} from "../api";
import {BoxModal} from "../components/BoxModal";
import {CreateProfileForm} from "../components/CreateProfileForm";
import {EditProfileForm} from "../components/EditProfileForm";
import {Profile} from "../types";
import {MedicalRolesFriendlyName, localizeBookingReason} from "../util";
import Nav from "../components/Nav";

const anyRoles = "Alla roller";
// TODO: DRY
const roles = ["admin", "medical", "customer"];

const Profiles = () => {
  const [profiles, setProfiles] = useState<Profile[] | null>(null);
  const [roleFilter, setRoleFilter] = useState<string | undefined>();
  const [skip, setSkip] = useState(0);
  const limit = 10;
  const [createProfileModalOpen, setCreateProfileModalOpen] =
    useState<boolean>(false);
  const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);

  const loadData = useCallback(async () => {
    const profiles = await GetProfiles({
      role: roleFilter === anyRoles ? "" : roleFilter || "",
      skip,
      limit,
    });
    setProfiles(profiles);
  }, [roleFilter, skip, limit]);

  const handleProfileSubmitted = () => {
    setCreateProfileModalOpen(false);
    loadData();
  };

  const handleProfileUpdated = () => {
    setSelectedProfile(null);
    loadData();
  };

  const handlePrevPageClicked = () => {
    setSkip(skip - limit);
  };

  const handleNextPageClicked = () => {
    setSkip(skip + limit);
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (!profiles) {
    return <Nav highlightedSection="profiles" content={<CircularProgress />} />;
  }

  return (
    <Nav
      highlightedSection="profiles"
      content={
        <>
          <BoxModal
            width={"50vw"}
            open={createProfileModalOpen}
            onClose={() => setCreateProfileModalOpen(false)}>
            <CreateProfileForm onSubmitted={handleProfileSubmitted} />
          </BoxModal>
          {selectedProfile && (
            <BoxModal
              width={"50vw"}
              open={true}
              onClose={() => setSelectedProfile(null)}>
              <EditProfileForm
                profile={selectedProfile}
                onSubmitted={handleProfileUpdated}
              />
            </BoxModal>
          )}
          <div style={{marginBottom: "20px"}}>
            <Grid container>
              <Grid item xs={6}>
                <FormControl fullWidth variant="filled">
                  <InputLabel id="role-label">Filtrera på roll</InputLabel>
                  <Select
                    labelId="role-label"
                    onChange={(evt: any) => setRoleFilter(evt.target.value)}>
                    {[anyRoles, ...roles].map((role) => (
                      <MenuItem
                        key={role}
                        value={role}
                        selected={role === roleFilter}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setCreateProfileModalOpen(true)}>
                    Skapa ny användare
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <ProfileList
            profiles={profiles}
            onProfileSelected={setSelectedProfile}
          />
          <Grid container justifyContent="flex-end" style={{marginTop: "20px"}}>
            <ButtonGroup variant="outlined">
              <Button
                variant="outlined"
                onClick={handlePrevPageClicked}
                disabled={skip === 0}>
                &laquo; Föregående sida
              </Button>
              <Button
                variant="outlined"
                onClick={handleNextPageClicked}
                disabled={profiles.length < limit}>
                Nästa sida &raquo;
              </Button>
            </ButtonGroup>
          </Grid>
        </>
      }
    />
  );
};

interface ProfileListProps {
  profiles: Profile[];
  onProfileSelected: (profile: Profile) => void;
}

const ProfileList: React.VFC<ProfileListProps> = ({
  profiles,
  onProfileSelected,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Efternamn</TableCell>
            <TableCell>Förnamn</TableCell>
            <TableCell>Personnummer</TableCell>
            <TableCell>E-postaddress</TableCell>
            <TableCell>Telefonnummer</TableCell>
            <TableCell>Arbetsroller</TableCell>
            <TableCell>Roll</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {profiles.map((profile, index) => (
            <TableRow key={index}>
              <TableCell>{profile.surname}</TableCell>
              <TableCell>{profile.givenName}</TableCell>
              <TableCell>
                <Link to={"/profiles/" + profile.personalNumber}>
                  {profile.personalNumber}
                </Link>
              </TableCell>
              <TableCell>{profile.email}</TableCell>
              <TableCell>{profile.telephoneNumber}</TableCell>
              <TableCell>
                {MedicalRolesFriendlyName(profile.medicalRoles)}
                {profile.bookingReasons.length > 0 && (
                  <>
                    {" "}
                    (
                    {profile.bookingReasons
                      .map(localizeBookingReason)
                      .join(",")}
                    )
                  </>
                )}
              </TableCell>
              <TableCell>{profile.role}</TableCell>
              <TableCell align="right">
                <ButtonGroup>
                  <Button
                    variant="contained"
                    onClick={() => onProfileSelected(profile)}>
                    Redigera
                  </Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Profiles;
