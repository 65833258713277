import {Link, useParams} from "react-router-dom";
import Nav from "../../components/Nav";
import {
  GetBookingPageConfigurationFromId,
  PutBookingPageConfiguration,
} from "../../api";
import {useEffect, useState} from "react";
import {BookingPageConfiguration} from "../../types";
import {Button, ButtonGroup, CircularProgress, Typography} from "@mui/material";

import {Divider} from "../../components/Divider";
import BookingPageConfigurationForm from "../../components/BookingPageConfigurationForm";

interface Params {
  id: string;
}

const BookingPageConfigurationEdit = () => {
  const {id} = useParams<Params>();
  const [isPosting, setIsPosting] = useState(false);
  const [bookingPageConfiguration, setBookingPageConfiguration] =
    useState<BookingPageConfiguration | null>(null);
  useEffect(() => {
    GetBookingPageConfigurationFromId(id).then(setBookingPageConfiguration);
  }, []);

  const saveBookingPageConfiguration = async () => {
    if (!bookingPageConfiguration) {
      return;
    }
    setIsPosting(true);
    try {
      await PutBookingPageConfiguration(id, bookingPageConfiguration);
      window.location.href = "/settings/booking-page-configurations";
    } catch (error) {
      window.alert(error);
      setIsPosting(false);
    }
  };

  if (!bookingPageConfiguration) {
    return (
      <Nav highlightedSection={"settings"} content={<CircularProgress />} />
    );
  }
  return (
    <Nav
      highlightedSection={"settings"}
      content={
        <>
          <Typography variant="h4">Uppdatera bokningssida</Typography>
          <Divider />
          <BookingPageConfigurationForm
            bookingPageConfiguration={bookingPageConfiguration}
            onChange={setBookingPageConfiguration}
          />
          <ButtonGroup variant="contained">
            <Button
              color="primary"
              onClick={saveBookingPageConfiguration}
              disabled={isPosting}>
              Spara ändringar
            </Button>
            <Button
              color="inherit"
              component={Link}
              to="/settings/booking-page-configurations">
              Avbryt
            </Button>
          </ButtonGroup>
        </>
      }
    />
  );
};

export default BookingPageConfigurationEdit;
