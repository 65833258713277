import {useContext, useEffect, useState} from "react";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import {GetFiles, GetMyTasks, ValidateAuth} from "./api";
import useLogger from "./hooks/useLogger";

import ViewQuizResult from "./pages/ViewQuizResult";
import ViewProfile from "./pages/ViewProfile";
import Ratings from "./pages/Ratings";
import MyProfiles from "./pages/MyProfiles";
import Profiles from "./pages/Profiles";
import Files from "./pages/Files";
import FollowUpQueue from "./pages/FollowUpQueue";
import Bookings from "./pages/Bookings";
import Call from "./pages/Call";
import Tasks from "./pages/Tasks";
import AddJournalRecord from "./pages/AddJournalRecord";
import InvoicingReport from "./pages/reports/InvoicingReport";
import QuizReport from "./pages/reports/QuizReport";
import BookingRateReport from "./pages/reports/BookingRateReport";
import ReportTypes from "./pages/reports/ReportTypes";
import {TasksContext} from "./contexts/TasksContext";
import {FileWithProfile, Task} from "./types";
import BookingsReport from "./pages/reports/BookingsReport";
import Home from "./pages/Home";
import {FilesContext} from "./contexts/FilesContext";
import useClaims from "./hooks/useClaims";
import SettingsIndex from "./pages/settings/SettingsIndex";
import MessageTemplates from "./pages/settings/MessageTemplates";
import Rebates from "./pages/settings/Rebates";
import RatingsReport from "./pages/reports/RatingsReport";
import TasksReport from "./pages/reports/TaskReports";
import BookingPageConfigurations from "./pages/settings/BookingPageConfigurations";
import BookingPageConfigurationEdit from "./pages/settings/BookingPageConfigurationEdit";
import BookingPageConfigurationCreate from "./pages/settings/BookingPageConfigurationCreate";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}
const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#EFEFEF",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: "700",
        },
      },
    },
  },
});

const App = () => {
  useLogger();
  const [isAuthenticated, setIsAuthenticated] = useState<Boolean | null>(null);
  const [tasks, setTasks] = useState<Task[] | null>(null);
  const [files, setFiles] = useState<FileWithProfile[] | null>(null);
  useEffect(() => {
    ValidateAuth().then(setIsAuthenticated);
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      const minute = 60 * 1000;
      const interval = setInterval(() => {
        ValidateAuth();
      }, 5 * minute);
      return () => clearInterval(interval);
    }
  }, [isAuthenticated]);

  if (isAuthenticated === null) {
    return <></>;
  }

  if (isAuthenticated === false) {
    window.location.assign(
      `${process.env.REACT_APP_APP_URL}/login?redirectURL=${encodeURIComponent(
        window.location.href
      )}`
    );
    return <>Unauthorized</>;
  }

  return (
    <StyledEngineProvider injectFirst>
      <TasksContext.Provider value={{tasks, setTasks}}>
        <FilesContext.Provider value={{files, setFiles}}>
          <ThemeProvider theme={theme}>
            <Router>
              <LocationChangeHandler />
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/my-profiles">
                  <MyProfiles />
                </Route>
                <Route path="/profiles/:personalNumber/add-journal-record">
                  <AddJournalRecord />
                </Route>
                <Route path="/profiles/:personalNumber">
                  <ViewProfile />
                </Route>
                <Route path="/bookings">
                  <Bookings />
                </Route>
                <Route path="/quiz-results/:id">
                  <ViewQuizResult />
                </Route>
                <Route path="/ratings">
                  <Ratings />
                </Route>
                <Route path="/tasks">
                  <Tasks />
                </Route>
                <Route path="/files">
                  <Files />
                </Route>
                <Route path="/profiles">
                  <Profiles />
                </Route>
                <Route path="/follow-up-queue">
                  <FollowUpQueue />
                </Route>
                <Route exact path="/reports">
                  <ReportTypes />
                </Route>
                <Route exact path="/reports/invoicing">
                  <InvoicingReport />
                </Route>
                <Route exact path="/reports/quiz">
                  <QuizReport />
                </Route>
                <Route exact path="/reports/booking-rate">
                  <BookingRateReport />
                </Route>
                <Route exact path="/reports/bookings">
                  <BookingsReport />
                </Route>
                <Route exact path="/reports/ratings">
                  <RatingsReport />
                </Route>
                <Route exact path="/reports/tasks">
                  <TasksReport />
                </Route>
                <Route exact path="/call">
                  <Call />
                </Route>
                <Route exact path="/settings">
                  <SettingsIndex />
                </Route>
                <Route exact path="/settings/message-templates">
                  <MessageTemplates />
                </Route>
                <Route exact path="/settings/rebates">
                  <Rebates />
                </Route>
                <Route exact path="/settings/booking-page-configurations">
                  <BookingPageConfigurations />
                </Route>
                <Route
                  exact
                  path="/settings/booking-page-configurations/create">
                  <BookingPageConfigurationCreate />
                </Route>
                <Route exact path="/settings/booking-page-configurations/:id">
                  <BookingPageConfigurationEdit />
                </Route>
              </Switch>
            </Router>
          </ThemeProvider>
        </FilesContext.Provider>
      </TasksContext.Provider>
    </StyledEngineProvider>
  );
};

const LocationChangeHandler = () => {
  const location = useLocation();
  const claims = useClaims();
  const {setFiles} = useContext(FilesContext);
  const {setTasks} = useContext(TasksContext);
  useEffect(() => {
    GetMyTasks({}).then(setTasks);
    if (claims && claims.role === "admin") {
      GetFiles({acknowledged: false}).then(setFiles);
    } else {
      setFiles([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);
  return <></>;
};

export default App;
