import {Dialog, Paper} from "@mui/material";

export interface BoxModalProps {
  open: boolean;
  onClose: () => void;
  width?: number | string | undefined;
}

// TODO: Deprecate this and use Dialog instead

export const BoxModal: React.FC<BoxModalProps> = ({
  open,
  onClose,
  children,
  width,
}) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <Paper style={{padding: "20px"}}> {children}</Paper>
    </Dialog>
  );
};
