import React, {useEffect, useState} from "react";
import Nav from "../../components/Nav";
import {
  DeleteMessageTemplate,
  GetMessageTemplates,
  PostMessageTemplates,
} from "../../api";
import {MessageTemplate} from "../../types";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";

const MessageTemplates = () => {
  const [selectedTemplate, setSelectedTemplate] = useState<
    MessageTemplate | undefined
  >(undefined);
  const [editMessageTemplateModalOpen, setEditMessageTemplateModalOpen] =
    useState<boolean>(false);
  const [messageTemplates, setMessageTemplates] = useState<MessageTemplate[]>(
    []
  );

  const loadTemplates = async () => {
    const messageTemplates = await GetMessageTemplates();
    setMessageTemplates(messageTemplates);
  };
  const onEditTemplateItemModalClosed = async () => {
    setSelectedTemplate(undefined);
    await loadTemplates();
    setEditMessageTemplateModalOpen(false);
  };

  const onDeleteTemplateClicked = async (messageTemplate: MessageTemplate) => {
    if (!window.confirm("Är du säker på att du vill ta bort denna mall?")) {
      return;
    }

    await DeleteMessageTemplate(messageTemplate.key);
    await loadTemplates();
  };

  const onEditTemplateButtonClicked = async (
    messageTemplate: MessageTemplate
  ) => {
    setSelectedTemplate(messageTemplate);
    setEditMessageTemplateModalOpen(true);
  };

  useEffect(() => {
    loadTemplates();
  }, []);

  return (
    <Nav
      highlightedSection={"settings"}
      content={
        <>
          <Grid container>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setEditMessageTemplateModalOpen(true)}>
                  Skapa ny mall
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {editMessageTemplateModalOpen && (
            <EditMessageTemplateModal
              onClose={onEditTemplateItemModalClosed}
              template={selectedTemplate}
            />
          )}
          <Table size={"small"} style={{marginTop: "10px"}}>
            <TableHead>
              <TableRow>
                <TableCell>Titel</TableCell>
                <TableCell>System ID</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {messageTemplates.map((mt) => (
                <>
                  <TableRow key={mt.key}>
                    <TableCell>{mt.title}</TableCell>
                    <TableCell>{mt.key}</TableCell>
                    <TableCell align="right">
                      <Button
                        color="error"
                        onClick={() => onDeleteTemplateClicked(mt)}
                        startIcon={<Delete />}>
                        Ta bort
                      </Button>
                      <Button
                        onClick={() => onEditTemplateButtonClicked(mt)}
                        startIcon={<Edit />}>
                        Redigera
                      </Button>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </>
      }
    />
  );
};

interface EditMessageTemplateModalProps {
  template?: MessageTemplate;
  onClose: () => void;
}

export const EditMessageTemplateModal: React.VFC<
  EditMessageTemplateModalProps
> = ({onClose, template}) => {
  const [key, setKey] = useState(template?.key || "");
  const [title, setTitle] = useState(template?.title || "");
  const [message, setMessage] = useState(template?.message || "");

  useEffect(() => {
    if (template) return;
    setKey(
      title
        .trim()
        .toLowerCase()
        .replaceAll(" ", "-")
        .replaceAll("åä", "a")
        .replaceAll("ö", "o")
        .replace(/[^a-z0-9-]+/gi, "")
    );
  }, [title]);

  const saveTemplate = async () => {
    await PostMessageTemplates({
      key,
      title,
      message,
    });
    onClose();
  };

  return (
    <Dialog open={true} maxWidth={"md"} fullWidth>
      <DialogTitle sx={{m: 0, p: 2}} style={{borderBottom: "1px solid #EEE"}}>
        <Typography variant="h5">Meddelandemall</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{paddingTop: "10px"}}>
        <Grid container spacing={2}>
          <Grid item sm={8}>
            <FormControl fullWidth>
              <InputLabel htmlFor="title">Titel</InputLabel>
              <Input
                fullWidth
                id="title"
                type="text"
                value={title}
                onChange={(evt) => setTitle(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item sm={4}>
            <FormControl fullWidth>
              <InputLabel htmlFor="key">Mallens system-ID</InputLabel>
              <Input
                fullWidth
                onChange={(evt) => setKey(evt.target.value)}
                id="key"
                type="text"
                disabled={template !== undefined}
                value={key}
              />
            </FormControl>
          </Grid>
        </Grid>

        <FormControl fullWidth>
          <TextareaAutosize
            minRows={5}
            value={message}
            onChange={(evt) => setMessage(evt.target.value)}
            placeholder="Skriv din meddelandemall här"
            style={{width: "100%", marginTop: "5px"}}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={saveTemplate}>
          Spara mall
        </Button>
        <Button color="inherit" onClick={onClose}>
          Avbryt
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessageTemplates;
