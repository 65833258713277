import {
  Typography,
  Grid,
  CircularProgress,
  Card,
  Input,
  Button,
  Alert,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {ChangeEvent, useEffect, useRef, useState} from "react";
import {Divider} from "../../components/Divider";
import {Question, Quiz, QuizResultAggregate} from "../../types";
import {GetQuiz, GetQuizResultsStats} from "../../api";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
import Nav from "../../components/Nav";
import {formatDateYYYYMMDD} from "../../util";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

const formatNumeric = (x: number) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

const barColors = [
  "#844597",
  "#C74E8E",
  "#F5687A",
  "#FF9365",
  "#FFC55C",
  "#F9F871",
];

const pieColors = ["#0078C1", "#F5687A"];

const QuizReport = () => {
  const [quiz, setQuiz] = useState<Quiz | null>(null);
  const [reloadRequired, setReloadRequired] = useState<boolean>(false);
  const [aggregates, setAggregates] = useState<QuizResultAggregate[] | null>(
    null
  );
  const [error, setError] = useState<any | null>(null);
  const [dateFrom, setDateFrom] = useState<Date>(
    new Date(new Date().getFullYear(), 0, 1, 1)
  );
  const [dateTo, setDateTo] = useState<Date>(new Date());

  const handleDateFromChanged = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const parsedDateEpoch = Date.parse(evt.target.value);
    if (isNaN(parsedDateEpoch)) {
      return;
    }
    const parsedDate = new Date(parsedDateEpoch);
    setDateFrom(parsedDate);
    setReloadRequired(true);
  };

  const handleDateToChanged = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const parsedDateEpoch = Date.parse(evt.target.value);
    if (isNaN(parsedDateEpoch)) {
      return;
    }
    const parsedDate = new Date(parsedDateEpoch);
    setDateTo(parsedDate);
    setReloadRequired(true);
  };

  useEffect(() => {
    GetQuiz("menopause").then(setQuiz);
  }, []);

  const loadQuizReport = async () => {
    try {
      setAggregates(null);
      const aggregates = await GetQuizResultsStats({
        from: dateFrom.toISOString(),
        to: dateTo.toISOString(),
        quizType: "menopause",
      });
      setAggregates(aggregates);
      setReloadRequired(false);
    } catch (error: any) {
      setError(error);
    }
  };

  useEffect(() => {
    loadQuizReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!quiz || !aggregates) {
    return <Nav highlightedSection="none" content={<CircularProgress />} />;
  }

  return (
    <Nav
      highlightedSection="none"
      content={
        <>
          {error && <Alert>Ett fel inträffade: {JSON.stringify(error)}</Alert>}
          <Grid container>
            <Grid item sm={8}>
              <Typography variant="h4">Självskattningstest</Typography>
            </Grid>
            <Grid item xs={4} justifyContent="flex-end">
              <div style={{float: "right"}}>
                <Input
                  style={{marginRight: "10px"}}
                  type="date"
                  value={formatDateYYYYMMDD(dateFrom)}
                  onChange={handleDateFromChanged}
                />
                <Input
                  style={{marginRight: "10px"}}
                  type="date"
                  value={formatDateYYYYMMDD(dateTo)}
                  onChange={handleDateToChanged}
                />
                <Button
                  disabled={!reloadRequired}
                  startIcon={<RefreshIcon />}
                  onClick={loadQuizReport}>
                  Uppdatera
                </Button>
              </div>
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2}>
            {quiz.questions?.map((question) => (
              <Grid item sm={12} key={question.id}>
                <QuestionStatSummary
                  question={question}
                  aggregates={aggregates}
                />
              </Grid>
            ))}
          </Grid>
        </>
      }
    />
  );
};

const calculateTotalAnswers = (
  aggregate: QuizResultAggregate,
  aggregates: QuizResultAggregate[],
  question: Question
): number => {
  let {totalNumAnswers} = aggregate;
  if (
    question.kind === "multichoice" &&
    question.visibility &&
    question.visibility.length > 0
  ) {
    const {questionId, values} = question.visibility[0];
    const dependantAgg = aggregates.find(
      (agg) => agg.questionId === questionId
    );
    if (dependantAgg) {
      totalNumAnswers = 0;
      values?.forEach((value) => {
        if (value === "TRUE" || value === "FALSE") {
          totalNumAnswers += dependantAgg[value.toLocaleLowerCase()];
        } else if (value.match(/[a-z]/i)) {
          totalNumAnswers += dependantAgg["option" + value];
        } else if (value.match(/[1-9]/i)) {
          totalNumAnswers += dependantAgg["numeric" + value];
        }
      });
    }
  }
  return totalNumAnswers;
};

interface QuestionStatSummaryProps {
  question: Question;
  aggregates: QuizResultAggregate[];
}

const QuestionStatSummary: React.VFC<QuestionStatSummaryProps> = ({
  question,
  aggregates,
}) => {
  if (question.visibility?.some((v) => v.values?.includes("HIDDEN"))) {
    return <></>;
  }
  const aggregate = aggregates.find((agg) => agg.questionId === question.id);
  if (!aggregate) {
    return <>Missing data for question {question.id}</>;
  }

  const totalNumAnswers = calculateTotalAnswers(
    aggregate,
    aggregates,
    question
  );

  return (
    <Card style={{padding: "0px 0px 10px 0px"}}>
      <div style={{padding: "10px 20px"}}>
        <Typography variant="h5">
          {question.id} - {question.title}
        </Typography>
        <Typography variant="body1">
          Antal svar: {formatNumeric(totalNumAnswers)}
        </Typography>
      </div>

      <Divider />
      {question.kind === "bool" && (
        <BoolChart question={question} aggregate={aggregate} />
      )}
      {question.kind === "singlechoice" && (
        <BarChartSingle question={question} aggregate={aggregate} />
      )}
      {question.kind === "multichoice" && (
        <BarChartMulti question={question} aggregate={aggregate} />
      )}
      {question.kind === "range" && (
        <BarChartRange question={question} aggregate={aggregate} />
      )}
    </Card>
  );
};

interface ChartProps {
  question: Question;
  aggregate: QuizResultAggregate;
}

const BoolChart: React.VFC<ChartProps> = ({question, aggregate}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      if (!ctx) return;
      const chart = new Chart(ctx, {
        type: "pie",
        data: {
          labels: [
            "Ja (" + aggregate.true + ")",
            "Nej (" + aggregate.false + ")",
          ],
          datasets: [
            {
              label: "",
              data: [
                ((aggregate.true / aggregate.totalNumAnswers) * 100).toFixed(2),
                ((aggregate.false / aggregate.totalNumAnswers) * 100).toFixed(
                  2
                ),
              ],
              backgroundColor: pieColors,
              borderWidth: 0,
            },
          ],
        },
        options: {
          responsive: false,
          maintainAspectRatio: true,
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  return context.parsed + "%";
                },
              },
            },
          },
        },
      });
      return () => {
        chart.destroy();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <canvas ref={canvasRef} style={{width: "100%", height: "400px"}} />;
};

const BarChartSingle: React.VFC<ChartProps> = ({question, aggregate}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      if (!ctx) return;
      const chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: question.options?.map((option) => option.title),
          datasets: [
            {
              label: "",
              data: question.options?.map(
                (option) => (aggregate["S" + option.value] as number) || 0
              ),
              backgroundColor: barColors,
              borderWidth: 0,
            },
          ],
        },
        options: {
          responsive: false,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
            x: {
              ticks: {
                callback: function (value) {
                  return question.options![value as number].title.substring(
                    0,
                    10
                  );
                },
              },
            },
          },
        },
      });
      return () => {
        chart.destroy();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <canvas ref={canvasRef} style={{width: "100%", height: "400px"}} />;
};

const BarChartMulti: React.VFC<ChartProps> = ({question, aggregate}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      if (!ctx) return;
      const chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: question.options?.map((option) => option.title),
          datasets: [
            {
              label: "",
              data: question.options?.map(
                (option) => (aggregate["M" + option.value] as number) || 0
              ),
              backgroundColor: barColors,
              borderWidth: 0,
            },
          ],
        },
        options: {
          responsive: false,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
            x: {
              ticks: {
                callback: function (value) {
                  return question.options![value as number].title.substring(
                    0,
                    10
                  );
                },
              },
            },
          },
        },
      });
      return () => {
        chart.destroy();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <canvas ref={canvasRef} style={{width: "100%", height: "400px"}} />;
};

const BarChartRange: React.VFC<ChartProps> = ({question, aggregate}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const ranges = new Array(question.max!).fill(0);
  const data = ranges.map((n, i) => (aggregate as any)["numeric" + (i + 1)]);
  const labels = ranges.map((n, i) => (i + 1).toString());
  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      if (!ctx) return;
      const chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              label: "",
              data: data,
              backgroundColor: barColors,
              borderWidth: 0,
            },
          ],
        },
        options: {
          responsive: false,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
            x: {},
          },
        },
      });
      return () => {
        chart.destroy();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <canvas ref={canvasRef} style={{width: "100%", height: "400px"}} />;
};

export default QuizReport;
