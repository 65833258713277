import {
  Booking,
  BookingPageConfiguration,
  BookingReason,
  Duration,
  InvoiceItem,
  MedicalRole,
  Profile,
  QuizType,
  SlotKind,
  TypeScores,
  Week,
} from "./types";

export const AllBookingReasons: BookingReason[] = [
  "menopause",
  "pms",
  "fertility",
];

export const AllMedicalRoles: MedicalRole[] = [
  "medical-doctor",
  "gynecologist",
  "midwife",
  "sexologist",
  "therapist",
  "tester",
];

export const MonthNames = [
  "Januari",
  "Februari",
  "Mars",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "Augusti",
  "September",
  "Oktober",
  "November",
  "December",
];

export const ageFromPersonalNumber = (personalNumber: string): number => {
  const first = personalNumber.slice(0, 10);
  const year = Number(first.slice(0, 4));
  const month = Number(first.slice(4, 6));
  const day = Number(first.slice(6, 8));
  const birthDate = new Date(year, month - 1, day);

  const ageDifMs = Date.now() - birthDate.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const ensureCountryCode = (telephoneNumber: string): string => {
  if (telephoneNumber.indexOf("+") !== 0) {
    if (telephoneNumber.startsWith("0")) {
      telephoneNumber = telephoneNumber.slice(1);
    }
    telephoneNumber = "+46" + telephoneNumber;
  }
  return telephoneNumber;
};

export const genderFromPersonalNumber = (
  personalNumber: string
): "male" | "female" => {
  const control = Number(personalNumber.slice(10, 11));
  return control % 2 === 0 ? "female" : "male";
};

export const getTypeScore = (typeScores: TypeScores) => {
  let typeWithHighesVal = "";
  let max = 0;
  for (const key of Object.keys(typeScores)) {
    const val = typeScores[key];
    if (val > max) {
      max = val;
      typeWithHighesVal = key;
    }
  }
  return typeWithHighesVal;
};

export const arrayIntersects = <T>(a: T[], b: T[]): boolean => {
  for (let ai of a) {
    for (let bi of b) {
      if (ai === bi) {
        return true;
      }
    }
  }
  return false;
};

export const decodeBase64Unicode = (str: string): string => {
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
};

export const localizeTaskKind = (taskKind: string): string => {
  switch (taskKind) {
    case "prescription":
      return "Recept";
    case "contact":
      return "Kontakt";
    case "referral":
      return "Skapa remiss";
    default:
      return taskKind;
  }
};

export const localizeBookingReason = (bookingReason: BookingReason) => {
  switch (bookingReason) {
    case "menopause":
      return "Klimakteriet";
    case "pms":
      return "PMS";
    case "fertility":
      return "Fertilitet";
    default:
      return "";
  }
};

// TODO: fetch from booking page configuration
export const localizeSlotKind = (slotKind: SlotKind): string => {
  switch (slotKind) {
    case "doctor":
      return "Nybesök - läkare";
    case "doctor-blood-sample":
      return "Nybesök - läkare inklusive blodprov";
    case "doctor-revisit":
      return "Återbesök - läkare";
    case "midwife":
      return "Nybesök - barnmorska";
    case "midwife-revisit":
      return "Återbesök - barnmorska";
    case "general-practitioner":
      return "Nybesök - allmänläkare";
    case "sexologist":
      return "Sexolog";
    case "therapist":
      return "Samtalsterapeut";
    case "test":
      return "Testmöte";
    case "midwife-dynamic-code":
      return "Barnmorka - dynamic code";
    case "midwife-astellas":
      return "Barnmorska - astellas";
    default:
      return slotKind;
  }
};

const typeScoreNames: Record<QuizType, Record<string, string>> = {
  menopause: {
    "1": "Premenopaus",
    "2": "Perimenopaus",
    "3": "Menopaus",
    "4": "Postmenopaus",
    "5": "Prematur ovariell insufficiens",
    "6": "Tidig Menopaus",
    "7": "Avvikande klimakterieförlopp",
    "8": "På hormonbehandling",
  },
  pms: {
    "1": "PMS Milda",
    "2": "PMS Måttlig",
    "3": "PMS/PMSD - svår",
  },
};

export const getQuizTypeName = (quizType: QuizType) => {
  switch (quizType) {
    case "menopause":
      return "Klimakteriet";
    case "pms":
      return "PMS";
  }
};

export const getTypeName = (
  quizType: QuizType,
  typeScores: TypeScores
): string => {
  const typeScore = getTypeScore(typeScores);
  return typeScoreNames[quizType]?.[typeScore] || typeScore.toString();
};

export const isFuture = (booking: Booking): boolean =>
  new Date(booking.startsAt) > new Date();

export const isPast = (booking: Booking): boolean => !isFuture(booking);

export const unique = (value: any, index: number, array: any[]) => {
  return array.indexOf(value) === index;
};

export const isOngoing = (booking: Booking): boolean => {
  const startsAt = new Date(booking.startsAt);
  const now = new Date();
  const paddedDate = new Date();
  paddedDate.setTime(now.getTime() + 60 * 60 * 1000);
  return startsAt > now && startsAt < paddedDate;
};

export const getFutureDate = (days: number) => {
  return addDaysToDate(new Date(), days);
};

export const getWeekNumbersBetween = (d1: Date, d2: Date): Week[] => {
  const weeks: Week[] = [];
  for (let d = d1; d < d2; d = addDaysToDate(d, 7)) {
    const weekNumber = getWeekNumber(d);
    weeks.push({year: d.getFullYear(), number: weekNumber});
  }
  return weeks;
};

export const addDaysToDate = (date: Date, numberOfDays: number): Date => {
  const d = new Date(date);
  d.setDate(date.getDate() + numberOfDays);
  return d;
};

export const getWeekNumber = (date: Date): number => {
  const tdt = new Date(date.valueOf());
  const dayn = (date.getDay() + 6) % 7;
  tdt.setDate(tdt.getDate() - dayn + 3);
  const firstThursday = tdt.valueOf();
  tdt.setMonth(0, 1);
  if (tdt.getDay() !== 4) {
    tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
  }
  return 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000);
};

export const formatNumeric = (x: number) => {
  if (typeof x === "undefined") return "";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const getDatesFromWeekNumber = (
  year: number,
  week: number
): [Date, Date] => {
  const dateCursor = new Date(year, 0, 1, 0, 0, 0, 0);
  let first: Date | null = null;
  let second: Date | null = null;
  const isLastWeek = week === maxWeekNumber(year);
  while (true) {
    const cw = getWeekNumber(dateCursor);
    const peek = new Date(dateCursor);
    peek.setDate(peek.getDate() + 1);
    const pw = getWeekNumber(peek);
    if (cw === week && !first && !(dateCursor.getMonth() === 0 && isLastWeek)) {
      first = new Date(dateCursor);
    }
    if (first != null) {
      if (cw === week && pw !== week) {
        second = new Date(
          dateCursor.getFullYear(),
          dateCursor.getMonth(),
          dateCursor.getDate(),
          23,
          59,
          59,
          999
        );
        break;
      }
    }

    dateCursor.setDate(dateCursor.getDate() + 1);
  }
  return [first!, second!];
};

export const maxWeekNumber = (year: number) => (isLeapYear(year) ? 53 : 52);

export const getYearsInRange = (min: number, max: number): number[] => {
  const years = [];
  let c = min;
  while (c <= max) {
    years.push(c);
    c++;
  }
  return years;
};

export const isLeapYear = (year: number): boolean =>
  (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

export const clearTime = (date: Date): Date => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const today = () => {
  return clearTime(new Date());
};

export const tomorrow = () => {
  var date = clearTime(new Date());
  date.setDate(date.getDate() + 1);
  return date;
};

export interface Location {
  lat: number;
  lng: number;
}

export const getMapDistance = (p1: Location, p2: Location) => {
  const R = 6371;
  const dLat = deg2rad(p2.lat - p1.lat);
  const dLon = deg2rad(p2.lng - p1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(p1.lat)) *
      Math.cos(deg2rad(p2.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};

export const formatDateYYYYMMDD = (date: Date): string => {
  return date.toISOString().split("T")[0];
};

export const meetingInvoiceItemNumbers = [
  "1",
  "2",
  "6",
  "7",
  "19",
  "21",
  "22",
  "24",
  "25",
  "26",
  "27",
];

export const nonMeeingInvoiceItem = (invoiceItem: InvoiceItem) => {
  return !meetingInvoiceItemNumbers.includes(invoiceItem.itemNumber);
};

export const RoleFriendlyName = (role: string): string => {
  if (role === "admin") {
    return "Administratör";
  }
  if (role === "medical") {
    return "Medecinsk personal";
  }
  if (role === "customer") {
    return "Patient";
  }
  return role;
};

export const MedicalRoleFriendlyName = (role: string): string => {
  if (role === "medical-doctor") {
    return "Läkare";
  }
  if (role === "gynecologist") {
    return "Gynekolog";
  }
  if (role === "midwife") {
    return "Barnmorska";
  }
  if (role === "sexologist") {
    return "Sexolog";
  }
  if (role === "therapist") {
    return "Samtalsterapeut";
  }
  if (role === "tester") {
    return "Testare";
  }
  if (role === "patient-support") {
    return "Patientsupport";
  }
  return role;
};

export const MedicalRolesFriendlyName = (medicalRoles: string[]): string => {
  return medicalRoles.map(MedicalRoleFriendlyName).join(", ");
};

const msInNs = 1000000;
const secondInNs = 1000 * msInNs;
const minuteInNs = 60 * secondInNs;
const hourInNs = 60 * minuteInNs;
const dayInNs = 24 * hourInNs;

export const nanoseconds = (ns: number) => {
  return {
    nanoseconds: () => ns,
    milliseconds: () => ns / msInNs,
    seconds: () => {
      return ns / secondInNs;
    },
    minutes: () => {
      return ns / minuteInNs;
    },
    hours: () => {
      return ns / hourInNs;
    },
    days: () => {
      return ns / dayInNs;
    },
  };
};

export const milliseconds = (ms: number): Duration => nanoseconds(ms * msInNs);

export const seconds = (s: number): Duration => nanoseconds(s * secondInNs);
export const minutes = (m: number): Duration => nanoseconds(m * minuteInNs);
export const hours = (h: number): Duration => nanoseconds(h * hourInNs);
export const days = (d: number): Duration => nanoseconds(d * dayInNs);
