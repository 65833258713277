import {
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  Box,
  IconButton,
  Typography,
  Button,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import {useState} from "react";
import {InfoOutlined} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: "auto",
    maxHeight: "80vh",
    minWidth: "80vw",
  },
  formControl: {
    marginTop: "20px",
  },
}));

const tabProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
    sx: {textAlign: "left", alignSelf: "start"},
  };
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{width: "100%"}}
      {...other}>
      {value === index && <Box sx={{p: 3}}>{children}</Box>}
    </div>
  );
};

interface InfoModalProps {
  onClose: () => void;
}
const InfoModal: React.VFC<InfoModalProps> = ({onClose}) => {
  const css = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Dialog
      classes={{paper: css.dialogPaper}}
      fullScreen={true}
      fullWidth
      maxWidth="lg"
      open={true}
      onClose={() => onClose()}>
      <DialogTitle sx={{m: 0, p: 2}} style={{borderBottom: "1px solid #EEE"}}>
        <Typography variant="h4">
          <InfoOutlined /> Information och guider
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            height: "30vw",
          }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            sx={{borderRight: 1, borderColor: "divider"}}>
            <Tab label="Blodprovställen" {...tabProps(0)} />
            <Tab label="Förskrivning av Testosteron" {...tabProps(1)} />
            <Tab label="Licensansökan" {...tabProps(2)} />
            <Tab label="Provtagningspaket" {...tabProps(3)} />
            <Tab label="Strängnäs" {...tabProps(4)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <>
              <h3>Blodprovtagning hos Womni</h3>
              <p>
                Om du vill se närmast blodprovstagningsställe för en patient,
                klicka på fliken "Karta" på patientens profil.
              </p>
              <p>
                Använder du checklistan för att skapa remiss för blodprovtagning
                så visas även närmaste blodprovstagningställe där.
              </p>
              <p>
                Vill du se en lista på våra aktuella blodprovtagningsställen så
                finns dem på denna sidan:{" "}
                <a
                  href="https://womni.se/blodprovtagning-womni"
                  target="_blank"
                  rel="noopener noreferrer">
                  Våra blodprovtaningsställen
                </a>
              </p>
            </>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <>
              <h3>Förskrivning av Testosteron</h3>
              <ol>
                <li>
                  Vid nybesök eller uppföljningsbesök- Var tydlig med patient
                  varför 2 provtagningar behövs, när hon ska göra dem och vad
                  det kommer kosta totalt (1150 kr för 2 blodprovtagningar).
                </li>
                <li>Vecka 0 Provtagning 1 inför behandlingsstart</li>
                <li>Vecka 8 Provtagning 2 kontrollprov</li>
                <li>Vecka 10 Uppföljningsbesök</li>
                <li>
                  Lägg in 2 blodprovsremisser för testosteron i labportalen på
                  en gång och datera dessa för tid 0 och tid 8 veckor.
                </li>
                <li>Fakturera för mötet och 2 blodprovstagningar.</li>
                <li>Sätt upp patient för uppföljningsmöte om 2,5 månader</li>
              </ol>
            </>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <LicenseApplicationInfo />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <>
              <h3>Provtagningspaket</h3>
              <ol>
                <li>
                  <a
                    href="https://svenskprovtagning.se/womni"
                    target="_blank"
                    rel="noopener noreferrer">
                    Se våra provtagningspaket hos svenskprovtagning.se
                  </a>
                </li>
              </ol>
            </>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <StrangnasInfo />
          </TabPanel>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const CopyBox: React.FC = ({children}) => {
  const [isCopied, setIsCopied] = useState(false);
  const copyText = () => {
    window.navigator.clipboard.writeText(children?.toString() || "");
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  return (
    <div
      style={{
        border: "1px solid #CCC",
        fontFamily: "monospace",
        background: "#EFEFEF",
        clear: "both",
        overflow: "auto",
        marginBottom: "10px",
      }}>
      {!isCopied && (
        <Button color="warning" style={{float: "right"}} onClick={copyText}>
          Kopiera text
        </Button>
      )}
      {isCopied && (
        <Button color="success" style={{float: "right"}} onClick={copyText}>
          Text är kopierad
        </Button>
      )}
      <div style={{padding: "10px"}}>{children}</div>
    </div>
  );
};

const StrangnasInfo = () => {
  return (
    <>
      <h3>STRÄNGNÄS</h3>
      <p>
        Besökstyp: <b>strangnas (25 minuter)</b> eller Besökstyp:{" "}
        <b>Återbesök, läkare Strängnäs</b>
      </p>
      <p>
        Strängnäs kommun gör en hälsosatsning med Womni och alla kvinnor som är
        anställda i kommunen får två läkarbesök hos Womni (om patient har behov
        av tre möten på kort tid för att må bra så är det ok också).
      </p>
      <p>
        <b>
          Patient från Strängnäs som träffar läkare för nybesök eller återbesök
        </b>
      </p>
      <p>
        <b>Faktureras</b> 0 kr och blodprover ingår vid behov (kommunen
        betalar).
      </p>
      <h4>Uppföljning</h4>
      <p>
        Patient ska sättas upp på “Återbesök Läkare Strängnäs” för att vi ska
        kunna se hur många möten Strängnäs har bokat. Efter två möten få patient
        själv välja om hon vill fortsätta och ni kan sätta upp henne på vanligt
        återbesök (“Återbesök Läkare”). Dessa val är förifyllda i checklistan så
        ni ska inte behöva fundera över detta.
      </p>
      <h4>Blodprovsremiss</h4>
      <p>
        Skicka vanlig blodprovsremiss. Närmaste provmottagning är i Eskilstuna
        på kartan. Bor patient långt ifrån Strängnäs kan ev annan mottagning
        vara närmare.
      </p>
      <h4>Remiss</h4>
      <p>
        Behöver patient remitteras för fysisk undersökning har tyvärr båda
        öppenvårdsgynekologerna i Strängnäs fullt. Vid
        utredningar/blödningar/malignitet kan vi remittera till Eskilstuna
        sjukhus (Mälarsjukhuset). Behöver patient göra en gynekologisk
        undersökning och kontrollera livmoderslemhinna mer rutinmässigt får ni
        gärna rekommendera att patient remitteras till Stockholm då köerna är
        mycket kortare och större tillgänglighet. Vi arbetar för att hitta någon
        närmare som inte är fullbelagd.
      </p>
      <p>
        Om patient endast vill gå i Strängnäs kan vi i nödfall remittera till{" "}
        <a
          href="https://www.kvinnohalsaneskilstuna.se/post/ny-klinik-i-str%C3%A4ngn%C3%A4s"
          target="_blank"
          rel="noopener noreferrer">
          kvinnohälsan Eskilstuna
        </a>{" "}
        (LÖT) så kanske de kan få en tid, men de är väldigt fullbelagda och
        arbetar inte främst med klimakterievård.
      </p>
    </>
  );
};

const LicenseApplicationInfo = () => {
  return (
    <>
      <h3>LICENSANSÖKAN</h3>

      <p>
        Licens söks via "KLAS". <br />
        <a
          href=" https://www.ehalsomyndigheten.se/tjanster/yrkesverksam/klas/"
          target="_blank">
          https://www.ehalsomyndigheten.se/tjanster/yrkesverksam/klas/
        </a>
      </p>
      <ol>
        <li>Skapa en egen profil </li>

        <li>
          Välj Ny ansökan och fyll i allt inkl preparat och adress ( ansökan kan
          sedan kopieras, då behöver du inte skriva in adress och preparat igen
          utan bara ändra om i den motiverande texten samt ändra personuppgifter
          för patienten ).{" "}
        </li>
      </ol>

      <p>
        <b>Adressuppgifter:</b>
        <ul>
          <li>Din womni mail</li>
          <li>Ditt eget mobil nr</li>
          <li>
            Adress:{" "}
            <CopyBox>
              WOMNI AB C/O Ioffice, Vasagatan 10, 111 20 Stockholm
            </CopyBox>
          </li>
        </ul>
      </p>

      <p>
        <b>Preparat som man behöver söka licens för:</b>
        <ul>
          <li>Utrogestan 100 mg/dagligen</li>
          <li>
            Dydrogesteron (Duphaston) finns bara på 10 mg, men kan delas.{" "}
          </li>
        </ul>
      </p>

      <p>
        <b> Hur motiverar du i ansökan:</b> <br />
        Jobbigt i början, sedan går det bara att copy/paste från dina tidigare
        licens ansökningar, så klart med lite tankar kring vad som gäller just
        denna patient.
      </p>

      <p>
        <b> Ex på motiveringar som kan kopieras:</b>
        <CopyBox>
          Pat mor och mormor fick bröstcancer. Det finns stora observationella
          studier som visar att mikroniserat progesteron/ dydrogesteron i
          kombination med östrogen ger ingen signifikant riskökning för
          bröstcancer upp till fem års användning av MHT.
        </CopyBox>
        <CopyBox>Pat känner sig orolig för Br cancer.</CopyBox>
        <CopyBox>
          Pat kan inte använda MHT oral pga …..( g by pass, U colit, fetma, ….)
          därför kan FEMOSTON inte rekommenderas. Progesteron/dydrogesteron Är
          ej trombogent och har lägre om ens någon ökad risk i kombination med
          östradiol och skiljer sig därmed från de syntetiska gestagenerna (SFOG
          riktlinjer).
        </CopyBox>
        <CopyBox>
          Pat har redan testat NETA, MPA eller/och Femoston/FemostonConti och
          inte mått bra
        </CopyBox>
      </p>

      <p>
        <b>OBS</b> angående Femoston/FemostonConti: ibland önskar LMV en
        komplettering innan godkännande av licensansökan och de rekommenderas då
        att man prövar behandling med Femoston Conti först (då det liknar det
        bioidentiska progesteronet).
      </p>
      <p>
        <b>OBS</b> att recept också behöver utfärdas och att samma preparat står
        på licensansökan som på receptet.
      </p>
    </>
  );
};

export default InfoModal;
