import {
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Button,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
  List,
  Typography,
  Stack,
  ListItem,
  CircularProgress,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import makeStyles from "@mui/styles/makeStyles";
import {useEffect, useState} from "react";
import {
  PostInvoice,
  PostFollowUpQueue,
  PutBookingHasHandledBloodSampleReferral,
  GetProfile,
  GetBookings,
} from "../api";
import {
  Booking,
  Claims,
  CreateFollowUpQueueItemRequest,
  InvoiceItemWithQuantity,
  Profile,
} from "../types";
import {isFuture, nonMeeingInvoiceItem} from "../util";
import InvoicingForm from "./InvoicingForm";
import FollowUpForm, {FollowUpFormEntry} from "./FollowUpForm";
import clsx from "clsx";
import {useClaimsMust} from "../hooks/useClaims";
import useLogger from "../hooks/useLogger";
import ReferralForm from "./ReferralForm";

export interface PostMeetingChecklistModalProps {
  booking: Booking;
  onClose: () => void;
}

type SummaryStep =
  | "needs-referral"
  | "referral-form"
  | "invoicing"
  | "follow-up"
  | "done";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: "auto",
    maxHeight: "80vh",
  },
  dialogTitle: {
    borderBottom: "1px solid #CCC",
    marginBottom: "10px",
  },
  formControl: {
    marginTop: "20px",
  },
  progress: {
    color: "#CCC",
    "& svg": {
      marginRight: "10px",
    },
  },
  progressCompleted: {
    color: "green",
  },
  progressActive: {
    color: "#000",
  },
}));

const getInitialStep = (booking: Booking, claims: Claims): SummaryStep => {
  if (!booking.hasHandledBloodSampleReferral) return "needs-referral";
  if (!booking.isInvoiced) return "invoicing";
  if (!booking.hasHandledFollowUp) return "follow-up";
  return "done";
};

const PostMeetingChecklistModal: React.VFC<PostMeetingChecklistModalProps> = ({
  booking,
  onClose,
}) => {
  const css = useStyles();
  const logger = useLogger();
  const claims = useClaimsMust();
  const [isValid, setIsValid] = useState(false);
  const [patientProfile, setPatientProfile] = useState<Profile | null>(null);
  const [step, setStep] = useState<SummaryStep>(
    getInitialStep(booking, claims)
  );
  const [showNotYourPatientWarning, setShowNotPatientWarning] = useState(
    claims.email !== booking.bookedPerson.email
  );
  const [showIsFutureBookingWarning, setShowIsFutureBookingWarning] = useState(
    isFuture(booking)
  );
  const [needsBloodSample, setNeedsBloodSample] = useState<string>("");
  const [invoiceItems, setInvoiceItems] = useState<
    InvoiceItemWithQuantity[] | null
  >();
  const [followUpFormEntry, setFollowUpFormEntry] =
    useState<FollowUpFormEntry | null>(null);
  const [bookings, setBookings] = useState<Booking[] | null>(null);

  useEffect(() => {
    GetProfile(booking.personalNumber).then(setPatientProfile);
    GetBookings({personalNumber: booking.personalNumber}).then(setBookings);
  }, []);

  const createInvoice = async () => {
    if (!invoiceItems) {
      return;
    }
    if (
      !window.confirm(
        "Är du säker på att du vill skapa faktura med dessa tjänster/produkter?"
      )
    ) {
      setIsValid(true);
      return;
    }
    try {
      await PostInvoice({
        bookingId: booking.id,
        items: invoiceItems,
        personalNumber: booking.personalNumber,
      });
    } catch (error) {
      logger.error(error);
      alert("något gick fel, försök igen");
      return;
    }
    if (!booking.hasHandledFollowUp) {
      setStep("follow-up");
    } else {
      setStep("done");
    }
  };

  const createFollowUp = async () => {
    if (!followUpFormEntry) {
      alert("Missing follow up form entry");
      return;
    }
    const {
      after,
      skipRevisit,
      reason,
      notes,
      bookingPageId,
      bookedPersonProfileId,
    } = followUpFormEntry;

    const followUpQueueItem: CreateFollowUpQueueItemRequest = {
      skipRevisit,
      personalNumber: booking.personalNumber,
      reason,
      after: after || new Date(),
      notes,
      bookingPageId: bookingPageId || "",
      bookedPersonProfileId,
    };
    try {
      await PostFollowUpQueue(followUpQueueItem);
      setStep("done");
    } catch (error) {
      logger.error(error);
      alert("Något gick fel, var god försök igen.");
    }
  };

  const advance = async () => {
    setIsValid(false);
    switch (step) {
      case "needs-referral":
        if (needsBloodSample !== "yes") {
          try {
            await PutBookingHasHandledBloodSampleReferral(booking.id);
          } catch (error) {
            logger.error(error);
          }
        }
        if (needsBloodSample === "yes") {
          setStep("referral-form");
        } else if (!booking.isInvoiced) {
          setStep("invoicing");
        } else {
          setStep("follow-up");
        }
        break;
      case "referral-form":
        if (!booking.isInvoiced) {
          setStep("invoicing");
        } else if (!booking.hasHandledFollowUp) {
          setStep("follow-up");
        } else {
          setStep("done");
        }
        break;
      case "invoicing":
        createInvoice();
        break;
      case "follow-up":
        createFollowUp();
        break;
    }
  };

  const stepCompleted = (q: SummaryStep): boolean => {
    if (step === "done") return true;
    switch (q) {
      case "needs-referral":
      case "referral-form":
        return step !== "needs-referral" && step !== "referral-form";
      case "invoicing":
        return step === "follow-up";
      default:
        return false;
    }
  };

  if (showIsFutureBookingWarning) {
    return (
      <Dialog
        classes={{paper: css.dialogPaper}}
        maxWidth="md"
        open={true}
        onClose={onClose}>
        <DialogContent>
          <Alert color="warning">
            <Typography variant="h5" style={{marginBottom: "20px"}}>
              Detta är ett möte som inte har genomförts ännu. <br />
              Är du säker på att du vill fortsätta till checklistan?
            </Typography>
            <ButtonGroup
              fullWidth
              variant="contained"
              orientation="vertical"
              color="inherit">
              <Button
                onClick={() => setShowIsFutureBookingWarning(false)}
                color="warning">
                Ja
              </Button>
              <Button onClick={onClose}>Nej, stäng fönstret</Button>
            </ButtonGroup>
          </Alert>
        </DialogContent>
      </Dialog>
    );
  }

  if (showNotYourPatientWarning) {
    return (
      <Dialog
        classes={{paper: css.dialogPaper}}
        maxWidth="md"
        open={true}
        onClose={onClose}>
        <DialogContent>
          <Alert color="warning">
            <Typography variant="h5" style={{marginBottom: "20px"}}>
              Detta möte är bokat på {booking.bookedPerson.firstName}{" "}
              {booking.bookedPerson.lastName}. <br />
              Är du säker på att du vill fortsätta till checklistan?
            </Typography>
            <ButtonGroup
              fullWidth
              variant="contained"
              orientation="vertical"
              color="inherit">
              <Button
                onClick={() => setShowNotPatientWarning(false)}
                color="warning">
                Ja
              </Button>
              <Button onClick={onClose}>Nej, stäng fönstret</Button>
            </ButtonGroup>
          </Alert>
        </DialogContent>
      </Dialog>
    );
  }

  if (!patientProfile) {
    return (
      <Dialog
        classes={{paper: css.dialogPaper}}
        maxWidth="md"
        open={true}
        onClose={onClose}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      classes={{paper: css.dialogPaper}}
      fullScreen={true}
      fullWidth
      maxWidth="md"
      open={true}
      onClose={onClose}>
      <DialogTitle className={css.dialogTitle}>
        <List component={Stack} direction="row">
          <ListItem>
            <Typography variant="h5">{"Checklista efter mötet"}</Typography>
          </ListItem>
          <ListItem
            className={clsx(css.progress, {
              [css.progressCompleted]: stepCompleted("needs-referral"),
              [css.progressActive]:
                step === "needs-referral" || step === "referral-form",
            })}>
            {stepCompleted("needs-referral") && <CheckCircleIcon />}
            {!stepCompleted("needs-referral") && <CheckCircleOutlineIcon />}
            Blodprov
          </ListItem>
          <ListItem
            className={clsx(css.progress, {
              [css.progressCompleted]: stepCompleted("invoicing"),
              [css.progressActive]: step === "invoicing",
            })}>
            {stepCompleted("invoicing") && <CheckCircleIcon />}
            {!stepCompleted("invoicing") && <CheckCircleOutlineIcon />}{" "}
            Fakturering
          </ListItem>
          <ListItem
            className={clsx(css.progress, {
              [css.progressCompleted]: stepCompleted("follow-up"),
              [css.progressActive]: step === "follow-up",
            })}>
            {stepCompleted("follow-up") && <CheckCircleIcon />}
            {!stepCompleted("follow-up") && <CheckCircleOutlineIcon />}
            Uppföljning
          </ListItem>
        </List>
      </DialogTitle>
      <DialogContent>
        {step === "needs-referral" && (
          <FormControl fullWidth className={css.formControl}>
            <FormLabel id="service-type-label">
              Behöver {booking.firstName} {booking.lastName} ta blodprov?
            </FormLabel>

            <RadioGroup
              value={needsBloodSample}
              onChange={(evt: any) => {
                setNeedsBloodSample(evt.target.value);
                setIsValid(true);
              }}
              aria-labelledby="service-type-label"
              name="service-type">
              <FormControlLabel value={"no"} control={<Radio />} label="Nej" />
              <FormControlLabel value={"yes"} control={<Radio />} label="Ja" />
              <FormControlLabel
                value={"already-sent"}
                control={<Radio />}
                label="Remiss för blodprov är redan skickad"
              />
            </RadioGroup>
          </FormControl>
        )}
        {step === "referral-form" && (
          <ReferralForm
            patientProfile={patientProfile}
            booking={booking}
            onContinue={advance}
          />
        )}
        {step === "invoicing" && (
          <InvoicingForm
            booking={booking}
            invoiceItemsFilter={nonMeeingInvoiceItem}
            onInvoiceItemsChanged={(invoiceItems) => {
              setInvoiceItems(invoiceItems);
              setIsValid(true);
            }}
          />
        )}
        {step === "follow-up" && bookings && (
          <FollowUpForm
            bookings={bookings}
            onChange={(followUpFormEntry) => {
              setFollowUpFormEntry(followUpFormEntry);
              setIsValid(followUpFormEntry.isValid);
            }}
          />
        )}
        {step === "done" && <div>Färdig, du kan nu stänga fönstret.</div>}
      </DialogContent>
      <DialogActions>
        {step === "done" && (
          <Button color="primary" variant="contained" onClick={onClose}>
            Avsluta
          </Button>
        )}
        {step !== "referral-form" && step !== "done" && (
          <ButtonGroup variant="contained" style={{marginTop: "20px"}}>
            <Button
              color="primary"
              variant="contained"
              onClick={advance}
              disabled={!isValid}>
              Fortsätt
            </Button>
            <Button variant="contained" onClick={onClose} color="inherit">
              Avbryt
            </Button>
          </ButtonGroup>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PostMeetingChecklistModal;
