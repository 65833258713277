import {MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {getYearsInRange} from "../../util";
export interface YearSelectorProps {
  yearMin: number;
  yearMax: number;
  value: number;
  onChange: (date: number) => void;
}

export const YearSelector: React.VFC<YearSelectorProps> = ({
  yearMin,
  yearMax,
  value,
  onChange,
}) => {
  const years = getYearsInRange(yearMin, yearMax);

  const onYearChanged = (evt: SelectChangeEvent<number>) => {
    onChange(Number(evt.target.value));
  };

  return (
    <Select size="small" value={value} onChange={onYearChanged}>
      {years.map((year) => (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      ))}
    </Select>
  );
};
