import makeStyles from "@mui/styles/makeStyles";
import {BookingPageConfiguration, InvoiceItem, SlotKind} from "../types";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import {
  AllBookingReasons,
  AllMedicalRoles,
  MedicalRoleFriendlyName,
  hours,
  localizeBookingReason,
  minutes,
  nanoseconds,
} from "../util";
import {GetBookingPageConfigurations, GetInvoiceItems} from "../api";
import {useEffect, useState} from "react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: "20px",
  },
  blockBox: {
    padding: "20px",
    marginBottom: "20px",
    "& h5": {
      marginBottom: "20px",
    },
  },
}));

interface BookingPageConfiguraionFormProps {
  bookingPageConfiguration: BookingPageConfiguration;
  onChange: (bpc: BookingPageConfiguration) => void;
}

const BookingPageConfigurationForm: React.VFC<
  BookingPageConfiguraionFormProps
> = ({bookingPageConfiguration, onChange}) => {
  const styles = useStyles();

  const [invoiceItems, setInvoiceItems] = useState<InvoiceItem[] | null>(null);
  useEffect(() => {
    GetInvoiceItems().then(setInvoiceItems);
  }, []);

  const [bookingPageConfigurations, setBookingPageConfigurations] = useState<
    BookingPageConfiguration[] | null
  >(null);
  useEffect(() => {
    GetBookingPageConfigurations().then(setBookingPageConfigurations);
  }, []);

  return (
    <>
      <Box component={Paper} className={styles.blockBox}>
        <Typography variant="h5">Allmän info</Typography>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel>Id (används i URL)</InputLabel>
          <Input
            fullWidth
            value={bookingPageConfiguration.urlSlug}
            onChange={(evt) => {
              bookingPageConfiguration.urlSlug = evt.target.value;
              // TODO: This should not be needed after slotkind is removed
              bookingPageConfiguration.kind = evt.target.value as SlotKind;
              onChange({...bookingPageConfiguration});
            }}
          />
          <FormHelperText>
            {process.env.REACT_APP_APP_URL}/calendar/
            {bookingPageConfiguration.urlSlug}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel>Rubrik</InputLabel>
          <Input
            fullWidth
            value={bookingPageConfiguration.title}
            onChange={(evt) => {
              bookingPageConfiguration.title = evt.target.value;
              onChange({...bookingPageConfiguration});
            }}
          />
        </FormControl>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel>Underrubrik</InputLabel>
          <Input
            fullWidth
            value={bookingPageConfiguration.subTitleHTML}
            onChange={(evt) => {
              bookingPageConfiguration.subTitleHTML = evt.target.value;
              onChange({...bookingPageConfiguration});
            }}
          />
        </FormControl>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel>Beskrivande namn</InputLabel>
          <Input
            fullWidth
            value={bookingPageConfiguration.friendlyName}
            onChange={(evt) => {
              bookingPageConfiguration.friendlyName = evt.target.value;
              onChange({...bookingPageConfiguration});
            }}
          />
        </FormControl>
      </Box>
      <Box component={Paper} className={styles.blockBox}>
        <Typography variant="h5">Möteslängd</Typography>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel>Blockstorlek (minuter)</InputLabel>
          <Input
            fullWidth
            type="number"
            value={
              nanoseconds(bookingPageConfiguration.increment).minutes() || ""
            }
            onChange={(evt) => {
              bookingPageConfiguration.increment = minutes(
                Number(evt.target.value)
              ).nanoseconds();
              onChange({...bookingPageConfiguration});
            }}
          />
        </FormControl>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel>Möteslängd (minuter)</InputLabel>
          <Input
            fullWidth
            type="number"
            value={
              nanoseconds(bookingPageConfiguration.duration).minutes() || ""
            }
            onChange={(evt) => {
              bookingPageConfiguration.duration = minutes(
                Number(evt.target.value)
              ).nanoseconds();
              onChange({...bookingPageConfiguration});
            }}
          />
        </FormControl>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel>
            Hur många lång tid i förväg kan mötet bokas (timmar)
          </InputLabel>
          <Input
            fullWidth
            type="number"
            value={
              nanoseconds(bookingPageConfiguration.minNoticeNs).hours() || ""
            }
            onChange={(evt) => {
              bookingPageConfiguration.minNoticeNs = hours(
                Number(evt.target.value)
              ).nanoseconds();
              onChange({...bookingPageConfiguration});
            }}
          />
        </FormControl>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel>
            Hur många lång tid i förväg kan mötet avbokas (timmar)
          </InputLabel>
          <Input
            fullWidth
            type="number"
            value={
              nanoseconds(
                bookingPageConfiguration.minCancellationNoticeNs
              ).hours() || ""
            }
            onChange={(evt) => {
              bookingPageConfiguration.minCancellationNoticeNs = hours(
                Number(evt.target.value)
              ).nanoseconds();
              onChange({...bookingPageConfiguration});
            }}
          />
        </FormControl>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel>Titel i Google Calendar</InputLabel>
          <Input
            fullWidth
            value={bookingPageConfiguration.eventSummaryTitle}
            onChange={(evt) => {
              bookingPageConfiguration.eventSummaryTitle = evt.target.value;
              onChange({...bookingPageConfiguration});
            }}
          />
          <FormHelperText>
            Titel på händelser i google calendar som skall anses som
            tillgängliga.
          </FormHelperText>
        </FormControl>
      </Box>
      <Box component={Paper} className={styles.blockBox}>
        <Typography variant="h5">Pris och fakturering</Typography>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel>Pris (SEK) - visas ut mot patient vid bokning</InputLabel>
          <Input
            fullWidth
            type="number"
            value={bookingPageConfiguration.price || ""}
            onChange={(evt) => {
              bookingPageConfiguration.price = Number(evt.target.value);
              onChange({...bookingPageConfiguration});
            }}
          />
        </FormControl>
        <FormControl fullWidth className={styles.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(evt) => {
                  bookingPageConfiguration.uiSettings.disableInvoicing =
                    evt.target.checked;
                  onChange({...bookingPageConfiguration});
                }}
                checked={bookingPageConfiguration.uiSettings.disableInvoicing}
              />
            }
            label={"Stäng av fakturering"}
          />
        </FormControl>
        {!bookingPageConfiguration.uiSettings.disableInvoicing &&
          invoiceItems && (
            <FormControl fullWidth className={styles.formControl}>
              Vilken billogram-produkt skall faktureras efter mötet?
              <Select
                defaultValue={bookingPageConfiguration.billingProductId}
                onChange={(evt: any) => {
                  bookingPageConfiguration.billingProductId = evt.target.value;
                  onChange({...bookingPageConfiguration});
                }}>
                <MenuItem
                  value={""}
                  selected={bookingPageConfiguration.billingProductId === ""}>
                  Välj billogram ID (lämna tomt om möte ej skall faktureras)
                </MenuItem>
                {invoiceItems.map((invoiceItem) => (
                  <MenuItem
                    key={invoiceItem.itemNumber}
                    value={invoiceItem.itemNumber}
                    selected={
                      bookingPageConfiguration.billingProductId ===
                      invoiceItem.itemNumber
                    }>
                    {invoiceItem.itemNumber} - {invoiceItem.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
      </Box>
      <Box component={Paper} className={styles.blockBox}>
        <Typography variant="h5">Kopplad personal</Typography>
        <FormControl fullWidth className={styles.formControl}>
          Vilka arbetsroller skall ingå?
          <Grid container>
            {AllMedicalRoles.map((medicalRole, index) => (
              <Grid item key={index} xs={6}>
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={bookingPageConfiguration.medicalRoles.includes(
                        medicalRole
                      )}
                      onChange={() => {
                        const index =
                          bookingPageConfiguration.medicalRoles.indexOf(
                            medicalRole
                          );
                        if (index === -1) {
                          bookingPageConfiguration.medicalRoles.push(
                            medicalRole
                          );
                        } else {
                          delete bookingPageConfiguration.medicalRoles[index];
                        }
                        onChange({...bookingPageConfiguration});
                      }}
                    />
                  }
                  label={MedicalRoleFriendlyName(medicalRole)}
                />
              </Grid>
            ))}
          </Grid>
        </FormControl>
      </Box>
      <Box component={Paper} className={styles.blockBox}>
        <Typography variant="h5">Besökstyp</Typography>
        <FormControl fullWidth className={styles.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(evt) => {
                  bookingPageConfiguration.isRevisit = evt.target.checked;
                  onChange({...bookingPageConfiguration});
                }}
                checked={bookingPageConfiguration.isRevisit}
              />
            }
            label={"Bokningar skall räknas som återbesök"}
          />
        </FormControl>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel id="role-label">
            Standardanledning (om ingen annan anges)
          </InputLabel>
          <Select
            defaultValue={bookingPageConfiguration.defaultBookingReason}
            onChange={(evt: any) => {
              bookingPageConfiguration.defaultBookingReason = evt.target.value;
              onChange({...bookingPageConfiguration});
            }}>
            {AllBookingReasons.map((reason) => (
              <MenuItem
                key={reason}
                value={reason}
                selected={
                  bookingPageConfiguration.defaultBookingReason === reason
                }>
                {localizeBookingReason(reason)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box component={Paper} className={styles.blockBox}>
        <Typography variant="h5">Återbesök</Typography>

        <p>
          Efter ett besök kommer vi rekommendera vilken kalender som skall
          användas för att boka återbesök.
        </p>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel id="role-label">
            Vilken kalender skall rekommenderas för återbesök?
          </InputLabel>
          <Select
            defaultValue={
              bookingPageConfiguration.revisitRecommendedBookingPageConfigurationId ===
              null
                ? "none"
                : bookingPageConfiguration.revisitRecommendedBookingPageConfigurationId
            }
            onChange={(evt: any) => {
              if (evt.target.value === "none") {
                bookingPageConfiguration.revisitRecommendedBookingPageConfigurationId =
                  null;
              } else {
                bookingPageConfiguration.revisitRecommendedBookingPageConfigurationId =
                  evt.target.value;
              }

              onChange({...bookingPageConfiguration});
            }}>
            <MenuItem
              key={"none"}
              value={"none"}
              selected={
                bookingPageConfiguration.revisitRecommendedBookingPageConfigurationId ===
                null
              }>
              Ingen vald
            </MenuItem>
            {bookingPageConfigurations &&
              bookingPageConfigurations
                .filter((bpc) => bpc.isRevisit)
                .map((bpc) => (
                  <MenuItem
                    key={bpc.id}
                    value={bpc.id}
                    selected={
                      bookingPageConfiguration.revisitRecommendedBookingPageConfigurationId ===
                      bpc.id
                    }>
                    {bpc.friendlyName}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>

        <FormControl fullWidth className={styles.formControl}>
          <p>
            Om det finns någon information som behöver meddelas till personalen
            vid bokning av återbesök så skriv den här.
          </p>
          <TextareaAutosize
            minRows={5}
            value={bookingPageConfiguration.revisitInformationText}
            onChange={(evt) => {
              bookingPageConfiguration.revisitInformationText =
                evt.target.value;
              onChange({...bookingPageConfiguration});
            }}
            placeholder="Skriv information till personal här"
            style={{width: "100%"}}
          />
        </FormControl>
      </Box>
      <Box component={Paper} className={styles.blockBox}>
        <Typography variant="h5">Mail-mallar</Typography>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel>Mailmall vid ny bokning</InputLabel>
          <Input
            fullWidth
            value={bookingPageConfiguration.emailTemplates.newBooking}
            onChange={(evt) => {
              bookingPageConfiguration.emailTemplates.newBooking =
                evt.target.value;
              onChange({...bookingPageConfiguration});
            }}
          />
        </FormControl>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel>Mailmall vid avbokning</InputLabel>
          <Input
            fullWidth
            value={bookingPageConfiguration.emailTemplates.cancelled}
            onChange={(evt) => {
              bookingPageConfiguration.emailTemplates.cancelled =
                evt.target.value;
              onChange({...bookingPageConfiguration});
            }}
          />
        </FormControl>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel>Mailmall vid feedback</InputLabel>
          <Input
            fullWidth
            value={bookingPageConfiguration.emailTemplates.feedback}
            onChange={(evt) => {
              bookingPageConfiguration.emailTemplates.feedback =
                evt.target.value;
              onChange({...bookingPageConfiguration});
            }}
          />
        </FormControl>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel>Mailmall vid påminnelse</InputLabel>
          <Input
            fullWidth
            value={bookingPageConfiguration.emailTemplates.reminder}
            onChange={(evt) => {
              bookingPageConfiguration.emailTemplates.reminder =
                evt.target.value;
              onChange({...bookingPageConfiguration});
            }}
          />
        </FormControl>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel>Mailmall vid ombokning</InputLabel>
          <Input
            fullWidth
            value={bookingPageConfiguration.emailTemplates.rescheduled}
            onChange={(evt) => {
              bookingPageConfiguration.emailTemplates.rescheduled =
                evt.target.value;
              onChange({...bookingPageConfiguration});
            }}
          />
        </FormControl>
      </Box>
      <Box component={Paper} className={styles.blockBox}>
        <Typography variant="h5">Övrigt</Typography>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel>Villkorstext</InputLabel>
          <Input
            fullWidth
            value={bookingPageConfiguration.uiSettings.termsText}
            onChange={(evt) => {
              bookingPageConfiguration.uiSettings.termsText = evt.target.value;
              onChange({...bookingPageConfiguration});
            }}
          />
        </FormControl>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel>Avbokningstext</InputLabel>
          <Input
            fullWidth
            value={bookingPageConfiguration.uiSettings.cancellationText}
            onChange={(evt) => {
              bookingPageConfiguration.uiSettings.cancellationText =
                evt.target.value;
              onChange({...bookingPageConfiguration});
            }}
          />
        </FormControl>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel>URL till tacksida</InputLabel>
          <Input
            fullWidth
            value={bookingPageConfiguration.uiSettings.confirmationPageURL}
            onChange={(evt) => {
              bookingPageConfiguration.uiSettings.confirmationPageURL =
                evt.target.value;
              onChange({...bookingPageConfiguration});
            }}
          />
        </FormControl>
        <FormControl fullWidth className={styles.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(evt) => {
                  bookingPageConfiguration.uiSettings.hideProfileTitle =
                    evt.target.checked;
                  onChange({...bookingPageConfiguration});
                }}
                checked={bookingPageConfiguration.uiSettings.hideProfileTitle}
              />
            }
            label={
              "Dölj den valda profilen på bokningssidan (om den är angiven)"
            }
          />
        </FormControl>
      </Box>
    </>
  );
};

export default BookingPageConfigurationForm;
