import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DownloadingIcon from "@mui/icons-material/Downloading";
import {useCallback, useEffect, useState} from "react";
import {GetBookingPageConfigurations, GetBookings, GetProfiles} from "../api";
import {L10nDate} from "../components/Date";
import {useClaimsMust} from "../hooks/useClaims";
import {
  Booking,
  BookingPageConfiguration,
  BookingQuery,
  DateRange,
  Profile,
} from "../types";
import Nav from "../components/Nav";
import {Link} from "react-router-dom";
import DateRangeSelector from "../components/dates/DateRangeSelector";

const Bookings = () => {
  const claims = useClaimsMust();
  const [profiles, setProfiles] = useState<Profile[] | null>(null);
  const [bookedPersonId, setBookedPersonId] = useState("all");
  const [range, setRange] = useState<DateRange | null>(null);
  const [bookings, setBookings] = useState<Booking[] | null>(null);
  const [bookingPageId, setBookingPageId] = useState<string>("all");
  const [bookingPageConfigurations, setBookingPageConfigurations] = useState<
    BookingPageConfiguration[] | null
  >(null);
  const [showOnlyMyBookings, setShowOnlyMyBookings] = useState(
    claims.role === "admin" ? false : true
  );

  const loadBookings = useCallback(async () => {
    if (!range) {
      return;
    }
    const {start, end} = range;
    const query: BookingQuery = {
      startsAfter: start.toISOString(),
      startsBefore: end.toISOString(),
    };
    if (showOnlyMyBookings) {
      query.bookedPersonId = claims.id;
    } else if (bookedPersonId !== "all") {
      query.bookedPersonId = bookedPersonId;
    }
    if (bookingPageId !== "all") {
      query.bookingPageId = bookingPageId;
    }
    const bookings = await GetBookings(query);
    setBookings(bookings);
  }, [range, showOnlyMyBookings, bookedPersonId, claims.id, bookingPageId]);

  const loadProfiles = useCallback(async () => {
    const profiles = await GetProfiles({
      roles: ["admin", "medical"],
      limit: 999999, // TODO: This should probably be an autocomplete in the future.
    });
    setProfiles(profiles);
  }, []);

  const loadBookingPageConfigurations = useCallback(async () => {
    const bookingPageConfigurations = await GetBookingPageConfigurations();
    setBookingPageConfigurations(bookingPageConfigurations);
  }, []);

  const csvCleanString = (s: string): string => {
    return `"${s.replace(/"/g, '""')}"`;
  };

  const downloadCsv = () => {
    if (!bookings) return;
    const convertToCSV = () => {
      let str =
        "First name,Last name,Personal number,Start date,End date,Created at," +
        "booked person first name, booked person last name,booked person email,Calendar,Rating\r\n";

      for (let i = 0; i < bookings.length; i++) {
        const booking = bookings[i];
        str +=
          `${csvCleanString(booking.firstName)},${csvCleanString(
            booking.lastName
          )},${booking.personalNumber},${booking.startsAt},${booking.endsAt},${
            booking.createdAt
          },` +
          `${csvCleanString(booking.bookedPerson.firstName)},${csvCleanString(
            booking.bookedPerson.lastName
          )},${booking.bookedPerson.email},` +
          `${booking.bookingPageConfiguration.urlSlug},${
            booking.rating || "N/A"
          }` +
          `\r\n`;
      }
      return str;
    };
    const csvData = new Blob([convertToCSV()], {type: "text/csv"});
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = `bookings.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    loadBookingPageConfigurations();
  }, [loadBookingPageConfigurations]);

  useEffect(() => {
    loadBookings();
  }, [loadBookings]);

  useEffect(() => {
    if (claims.role === "admin") {
      loadProfiles();
    }
  }, [loadProfiles, claims.role]);

  return (
    <Nav
      highlightedSection="bookings"
      content={
        <>
          <div style={{marginBottom: "10px"}}>
            <Grid container>
              <Grid item xs={6}>
                {claims.role === "admin" && (
                  <>
                    <FormControl style={{marginLeft: "20px"}}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={showOnlyMyBookings}
                            onChange={(evt) =>
                              setShowOnlyMyBookings(evt.target.checked)
                            }
                          />
                        }
                        label="Mina möten"
                      />
                    </FormControl>
                    {!showOnlyMyBookings && (
                      <FormControl style={{marginLeft: "20px"}}>
                        <Select
                          value={bookedPersonId}
                          fullWidth
                          size="small"
                          variant="outlined"
                          labelId="booked-person-label"
                          onChange={(evt: any) =>
                            setBookedPersonId(evt.target.value)
                          }>
                          <MenuItem value={"all"}>Allas möten</MenuItem>
                          {profiles &&
                            profiles.map((profile) => (
                              <MenuItem key={profile.id} value={profile.id}>
                                {profile.givenName} {profile.surname},{" "}
                                {profile.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  </>
                )}
                <FormControl style={{marginLeft: "20px"}}>
                  <Select
                    value={bookingPageId}
                    fullWidth
                    size="small"
                    variant="outlined"
                    labelId="booked-person-label"
                    onChange={(evt: any) => setBookingPageId(evt.target.value)}>
                    <MenuItem value={"all"}>Alla mötestyper</MenuItem>
                    {bookingPageConfigurations &&
                      bookingPageConfigurations.map(
                        (bookingPageConfiguration) => (
                          <MenuItem
                            key={bookingPageConfiguration.id}
                            value={bookingPageConfiguration.id}>
                            {bookingPageConfiguration.friendlyName}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>

                {claims.role === "admin" && (
                  <FormControl style={{marginLeft: "20px"}}>
                    <Button
                      variant="outlined"
                      onClick={downloadCsv}
                      startIcon={<DownloadingIcon />}>
                      CSV
                    </Button>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-end">
                  <DateRangeSelector defaultMode="week" onChange={setRange} />
                </Box>
              </Grid>
            </Grid>
          </div>
          {bookings && <BookingTable bookings={bookings} />}
          {!bookings && <CircularProgress />}
        </>
      }
    />
  );
};

const BookingTable: React.VFC<{bookings: Booking[]}> = ({bookings}) => {
  const statusLabel = (booking: Booking): string => {
    if (booking.cancelled) return "Avbokat";
    if (!booking.isInvoiced) return "Ofakturerad";
    else return "Fakturerad";
  };
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Patient</TableCell>
            <TableCell>Telefon</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Bokad person</TableCell>
            <TableCell>Besökstyp</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bookings.map((booking) => (
            <TableRow key={booking.id}>
              <TableCell>
                <L10nDate
                  dateTime={booking.startsAt}
                  options={{dateStyle: "full", timeStyle: "short"}}
                />
              </TableCell>
              <TableCell>
                <Link to={`/profiles/${booking.personalNumber}`}>
                  {booking.firstName} {booking.lastName}
                </Link>
              </TableCell>
              <TableCell>
                {booking.telephoneNumber} <br />
              </TableCell>
              <TableCell>
                {booking.email} <br />
              </TableCell>
              <TableCell>
                {booking.bookedPerson.firstName} {booking.bookedPerson.lastName}
                , {booking.bookedPerson.role}
              </TableCell>
              <TableCell>
                {booking.bookingPageConfiguration.friendlyName}
              </TableCell>
              <TableCell>{statusLabel(booking)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Bookings;
