import {
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {useState} from "react";
import {PostFollowUpQueue} from "../api";
import {
  Booking,
  CreateFollowUpQueueItemRequest,
  FollowUpQueueItem,
} from "../types";
import FollowUpForm, {FollowUpFormEntry} from "./FollowUpForm";

export interface FollowUpModalProps {
  personalNumber: string;
  prefillWith?: FollowUpQueueItem;
  bookings: Booking[];
  onClose: (modified: boolean | undefined) => void;
}

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: "auto",
    maxHeight: "80vh",
  },
}));

const FollowUpModal: React.VFC<FollowUpModalProps> = ({
  personalNumber,
  onClose,
  prefillWith,
  bookings,
}) => {
  const css = useStyles();
  const [followUpFormEntry, setFollowUpFormEntry] =
    useState<FollowUpFormEntry | null>();

  const submit = async () => {
    if (!followUpFormEntry) {
      return;
    }
    const {
      after,
      skipRevisit,
      reason,
      notes,
      bookingPageId,
      bookedPersonProfileId,
    } = followUpFormEntry;
    // TODO: Can we fix this?
    if (after === null || bookingPageId === null) {
      return;
    }
    const req: CreateFollowUpQueueItemRequest = {
      skipRevisit,
      reason,
      personalNumber,
      after,
      notes,
      bookingPageId,
      bookedPersonProfileId,
    };
    try {
      await PostFollowUpQueue(req);
      onClose(true);
    } catch (error) {
      alert("Något gick fel, var god försök igen.");
    }
  };

  return (
    <Dialog
      classes={{paper: css.dialogPaper}}
      fullScreen={false}
      fullWidth
      maxWidth="md"
      open={true}
      onClose={() => onClose(false)}>
      <DialogTitle>{"Hantera uppföljning"}</DialogTitle>
      <DialogContent>
        <FollowUpForm
          bookings={bookings}
          onChange={setFollowUpFormEntry}
          prefillWith={prefillWith}
        />
      </DialogContent>
      <DialogActions>
        <ButtonGroup variant="contained" style={{marginTop: "20px"}}>
          <Button
            color="primary"
            variant="contained"
            onClick={submit}
            disabled={
              !followUpFormEntry || followUpFormEntry.isValid === false
            }>
            Spara
          </Button>
          <Button
            variant="contained"
            onClick={() => onClose(false)}
            color="inherit">
            Avbryt
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

export default FollowUpModal;
