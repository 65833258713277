import {
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableContainer,
  CircularProgress,
} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {GetBookingReferrals, GetInvoiceItems} from "../api";
import {
  Booking,
  InvoiceItem,
  InvoiceItemWithQuantity,
  SlotKind,
} from "../types";
import {formatNumeric} from "../util";

export interface InvoicingFormProps {
  onInvoiceItemsChanged: (invoiceItems: InvoiceItemWithQuantity[]) => void;
  prefillWith?: Record<string, number>;
  invoiceItemsFilter?: (invoiceItem: InvoiceItem) => boolean;
  booking?: Booking;
}

const TrueFilter = () => true;

interface InvoiceItemWithQuantityAndVisibility extends InvoiceItemWithQuantity {
  visible: boolean;
}

const slotKindItemNumberMap: Record<SlotKind, string> = {
  doctor: "1",
  "doctor-revisit": "26",
  "doctor-blood-sample": "1",
  midwife: "2",
  "midwife-revisit": "27",
  sexologist: "22",
  therapist: "21",
  "general-practitioner": "1",
  "health-coach": "25",
  test: "1",
  "midwife-dynamic-code": "28",
  "midwife-astellas": "",
  "midwife-werlabs": "",
};

const InvoicingForm: React.VFC<InvoicingFormProps> = ({
  onInvoiceItemsChanged,
  invoiceItemsFilter,
  prefillWith = {},
  booking,
}) => {
  const [invoiceSum, setInvoiceSum] = useState<number | null>(null);
  const [invoiceItems, setInvoiceItems] = useState<
    InvoiceItemWithQuantityAndVisibility[] | null
  >(null);

  const isItemVisible = invoiceItemsFilter || TrueFilter;
  const loadInvoiceItems = useCallback(
    async () => {
      let bookingItemNumber: string | null = null;
      if (booking) {
        const referrals = await GetBookingReferrals(booking.id);
        prefillWith["18"] = referrals.length;

        bookingItemNumber = slotKindItemNumberMap[booking.slotKind];
        // TODO: Temporary fix to handle old revisits
        // Remove this in november 2023
        const created = new Date(booking.createdAt);
        if (created < new Date(2023, 8, 4, 12, 0, 0, 0)) {
          if (booking.slotKind === "doctor-revisit") {
            bookingItemNumber = "6";
          } else if (booking.slotKind === "midwife-revisit") {
            bookingItemNumber = "7";
          }
        }
        prefillWith[bookingItemNumber] = 1;
      }
      const invoiceItems = await GetInvoiceItems();
      setInvoiceItems(
        invoiceItems
          .map((i): InvoiceItemWithQuantityAndVisibility => {
            return {
              ...i,
              visible: isItemVisible(i) || bookingItemNumber === i.itemNumber,
            };
          })
          .map((i) => {
            i.quantity = prefillWith[i.itemNumber] || 0;
            return i;
          })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    loadInvoiceItems();
  }, [loadInvoiceItems]);

  useEffect(() => {
    if (!invoiceItems) return;
    setInvoiceSum(
      invoiceItems
        .map((ii) => ii.quantity * ii.price)
        .reduce((acc, a) => acc + a)
    );
    onInvoiceItemsChanged(invoiceItems);
  }, [invoiceItems, onInvoiceItemsChanged]);

  const incrementInvoiceItem = (invoiceItemNumber: string, count: number) => {
    if (invoiceItems === null) return;
    const index = invoiceItems.findIndex(
      (p) => p.itemNumber === invoiceItemNumber
    );
    const invoiceItem = invoiceItems[index]!;
    invoiceItem.quantity += count;
    if (invoiceItem.quantity < 0) invoiceItem.quantity = 0;
    invoiceItems[index] = invoiceItem;
    setInvoiceItems([...invoiceItems]);
  };

  if (!invoiceItems) {
    return <CircularProgress />;
  }
  return (
    <TableContainer>
      <Table
        size="small"
        style={{
          borderTop: "1px solid #EEE",
        }}>
        <colgroup>
          <col style={{width: "15%"}} />
          <col style={{width: "55%"}} />
          <col style={{width: "30%"}} />
        </colgroup>
        <TableBody>
          {invoiceItems
            .filter((i) => i.visible || i.quantity > 0)
            .map((invoiceItem, index) => (
              <TableRow key={index}>
                <TableCell>
                  <ButtonGroup>
                    <Button
                      color={invoiceItem.quantity > 0 ? "primary" : "inherit"}
                      variant="contained">
                      {invoiceItem.quantity} st
                    </Button>
                    <Button
                      onClick={() =>
                        incrementInvoiceItem(invoiceItem.itemNumber, 1)
                      }>
                      +
                    </Button>
                    <Button
                      onClick={() =>
                        incrementInvoiceItem(invoiceItem.itemNumber, -1)
                      }>
                      -
                    </Button>
                  </ButtonGroup>
                </TableCell>
                <TableCell>{invoiceItem.title}</TableCell>
                <TableCell align="right">
                  {formatNumeric(invoiceItem.price)} SEK
                </TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">
              <b>Summa</b>
            </TableCell>
            <TableCell align="right">
              <b>{invoiceSum && formatNumeric(invoiceSum)}</b> SEK
            </TableCell>
          </TableRow>
        </TableBody>
        <TableFooter></TableFooter>
      </Table>
    </TableContainer>
  );
};

export default InvoicingForm;
