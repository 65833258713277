import {
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, {useEffect, useState} from "react";
import {GetInvoiceItems, PatchRebate, PostRebates} from "../api";
import {InvoiceItem, Rebate} from "../types";
import {addDaysToDate, formatDateYYYYMMDD} from "../util";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: "auto",
    maxHeight: "80vh",
  },
  formControl: {
    marginBottom: "20px",
  },
}));

interface CreateRebateFormProps {
  rebateToEdit: Rebate | null;
  onSubmitted: () => void;
}

export const CreateOrEditRebateForm: React.VFC<CreateRebateFormProps> = ({
  onSubmitted,
  rebateToEdit,
}) => {
  const css = useStyles();
  const [value, setValue] = useState<Number>(rebateToEdit?.value || 0);
  const [isPercentage, setIsPercentage] = useState<boolean>(
    rebateToEdit?.isPercentage || false
  );
  const [allowedProductIds, setAllowedProductIds] = useState<string[]>(
    rebateToEdit?.allowedProductIds || []
  );
  const [startsAt, setStartsAt] = useState<string>(
    formatDateYYYYMMDD(
      rebateToEdit ? new Date(rebateToEdit.startsAt) : new Date()
    )
  );
  const [endsAt, setEndsAt] = useState<string>(
    formatDateYYYYMMDD(
      rebateToEdit
        ? new Date(rebateToEdit.endsAt)
        : addDaysToDate(new Date(), 30)
    )
  );
  const [code, setCode] = useState<string>(rebateToEdit?.code || "");

  const [invoiceItems, setInvoiceItems] = useState<InvoiceItem[]>([]);

  useEffect(() => {
    GetInvoiceItems().then(setInvoiceItems);
  }, []);

  const isProductIdAllowed = (itemNumber: string): boolean => {
    return allowedProductIds.includes(itemNumber);
  };

  const getInvoiceItemTitle = (productId: string): string => {
    return (
      invoiceItems.find((p) => p.itemNumber === productId)?.title || productId
    );
  };

  const handleAllowedProductIdsChanged = (
    evt: any,
    invoiceItem: InvoiceItem
  ) => {
    if (evt.target.checked) {
      setAllowedProductIds([...allowedProductIds, invoiceItem.itemNumber]);
    } else {
      allowedProductIds.splice(
        allowedProductIds.indexOf(invoiceItem.itemNumber),
        1
      );
      setAllowedProductIds([...allowedProductIds]);
    }
  };

  const submitRebate = async () => {
    const endsAtDate = new Date(endsAt);
    endsAtDate.setHours(23, 59, 59);
    const req = {
      allowedProductIds,
      code,
      endsAt: endsAtDate.toISOString(),
      startsAt: new Date(startsAt).toISOString(),
      isPercentage,
      value: Number(value),
    };
    if (code.length < 3) {
      window.alert("Rabattkoden måste vara minst tre tecken lång");
      return;
    }
    if (isPercentage && req.value > 100) {
      window.alert("Du kan inte ge mer än 100% i rabatt");
      return;
    }
    if (allowedProductIds.length === 0) {
      window.alert("Ange vilka produkter som rabatten skall vara giltig för");
      return;
    }
    if (rebateToEdit) {
      await PatchRebate(rebateToEdit.id, req);
    } else {
      await PostRebates(req);
    }

    onSubmitted();
  };

  const maskAndSetCode = (
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCode(
      evt.target.value
        .toLowerCase()
        .replace(" ", "-")
        .replace(/[^a-z0-9-]+/g, "")
    );
  };

  return (
    <div style={{minWidth: "600px"}}>
      <FormControl fullWidth className={css.formControl}>
        <InputLabel htmlFor="code">Rabattkod</InputLabel>
        <Input
          disabled={rebateToEdit !== null}
          placeholder="T ex womni-2024"
          fullWidth
          size="medium"
          id="code"
          type="text"
          value={code}
          onChange={maskAndSetCode}
        />
      </FormControl>
      <h3 style={{marginTop: "20px", marginBottom: "20px"}}>
        Rabattkodens värde
      </h3>
      <FormControl fullWidth className={css.formControl}>
        <InputLabel htmlFor="value">Värde på rabatten</InputLabel>
        <Input
          placeholder="Skriv in hur mycket värde som rabatten skall ge"
          endAdornment={
            <InputAdornment position="end">
              {isPercentage ? "%" : "kr"}
            </InputAdornment>
          }
          fullWidth
          id="value"
          type="number"
          value={value === 0 ? "" : value}
          onChange={(evt) => setValue(Number.parseInt(evt.target.value))}
        />
      </FormControl>
      <FormControl>
        <p>Är värdet i procent eller i kronor?</p>
        <FormControl style={{marginLeft: "20px"}}>
          <RadioGroup
            value={isPercentage ? "percentage" : "currency"}
            onChange={(evt: any) =>
              setIsPercentage(evt.target.value === "percentage")
            }
            aria-labelledby="service-type-label"
            name="service-type">
            <FormControlLabel
              value="percentage"
              control={<Radio />}
              label="Procent"
            />
            <FormControlLabel
              value="currency"
              control={<Radio />}
              label="Kronor"
            />
          </RadioGroup>
        </FormControl>
      </FormControl>
      <Divider />
      <h3 style={{marginTop: "20px", marginBottom: "20px"}}>
        Rabattkodens giltighet
      </h3>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <FormControl fullWidth className={css.formControl}>
            <InputLabel htmlFor="startDate">
              Rabatten är giltig f om.
            </InputLabel>
            <Input
              fullWidth
              id="startDate"
              type="date"
              value={startsAt}
              onChange={(evt) => setStartsAt(evt.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item sm={6}>
          <FormControl fullWidth className={css.formControl}>
            <InputLabel htmlFor="endDate">Rabatten är giltig t om.</InputLabel>
            <Input
              fullWidth
              id="endDate"
              type="date"
              value={endsAt}
              onChange={(evt) => setEndsAt(evt.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
      <h3 style={{marginTop: "20px", marginBottom: "20px"}}>
        Rabatten kan användas på följande produkter/tjänster:
      </h3>
      <FormControl fullWidth className={css.formControl}>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={allowedProductIds}
          onChange={() => {}}
          input={<OutlinedInput label="Tag" />}
          renderValue={(allowedProductIds) =>
            allowedProductIds.map(getInvoiceItemTitle).join(", ")
          }
          MenuProps={MenuProps}>
          {invoiceItems.map((invoiceItem) => (
            <MenuItem
              key={invoiceItem.itemNumber}
              value={invoiceItem.itemNumber}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(evt) =>
                      handleAllowedProductIdsChanged(evt, invoiceItem)
                    }
                    checked={isProductIdAllowed(invoiceItem.itemNumber)}
                  />
                }
                label={invoiceItem.title}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ButtonGroup
        variant="contained"
        style={{marginTop: "20px"}}></ButtonGroup>
      <Grid container justifyContent="flex-end">
        <Button color="secondary" onClick={onSubmitted}>
          Avbryt
        </Button>
        <Button variant="contained" color="primary" onClick={submitRebate}>
          {rebateToEdit ? "Spara" : "Skapa"} rabattkod
        </Button>
      </Grid>
    </div>
  );
};
