import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Input,
  FormControlLabel,
  Checkbox,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  InputAdornment,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, {useEffect, useState} from "react";
import {
  GetMedicalRoles,
  GetRoles,
  PatchProfile,
  PostProfileRefreshAddress,
} from "../api";
import {BookingReason, ExternalID, Profile} from "../types";
import {
  MedicalRoleFriendlyName,
  RoleFriendlyName,
  localizeBookingReason,
} from "../util";
import {Key, RefreshOutlined} from "@mui/icons-material";
import useLogger from "../hooks/useLogger";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: "auto",
    maxHeight: "80vh",
  },
  formControl: {
    marginBottom: "20px",
  },
}));

export const editableExternalIds: ExternalID[] = ["labportalen"];

interface EditProfileFormProps {
  onSubmitted: () => void;
  profile: Profile;
}

export const EditProfileForm: React.VFC<EditProfileFormProps> = ({
  onSubmitted,
  profile,
}) => {
  const css = useStyles();
  const logger = useLogger();
  const [formValid, setFormValid] = useState<boolean>(true);
  const [email, setEmail] = useState<string>(profile.email);
  const [givenName, setGivenName] = useState<string>(profile.givenName);
  const [surname, setSurname] = useState<string>(profile.surname);
  const [externalIds, setExternalIds] = useState<Record<ExternalID, string>>(
    profile.externalIds
  );
  const [telephoneNumber, setTelephoneNumber] = useState<string>(
    profile.telephoneNumber
  );
  const [address, setAddress] = useState<string | null>(
    profile.primaryAddress?.fullAddressString || null
  );
  const [role, setRole] = useState<string>(profile.role);
  const [roles, setRoles] = useState<string[]>([]);
  const [medicalRoles, setMedicalRoles] = useState<string[]>(
    profile.medicalRoles
  );
  const [bookingReasons, setBookingReasons] = useState<BookingReason[]>(
    profile.bookingReasons
  );
  const [availableMedicalRoles, setAvailableMedicalRoles] = useState<string[]>(
    []
  );
  const availableBookingReasons: BookingReason[] = [
    "menopause",
    "pms",
    "fertility",
  ];
  useEffect(() => {
    GetRoles().then(setRoles);
    GetMedicalRoles().then(setAvailableMedicalRoles);
  }, []);

  useEffect(() => {
    setFormValid(email !== "" && role !== "");
  }, [email, role]);

  const toggleMedicalRole = (medicalRole: string) => {
    const index = medicalRoles.indexOf(medicalRole);
    if (index === -1) {
      medicalRoles.push(medicalRole);
    } else {
      medicalRoles.splice(index, 1);
    }
    setMedicalRoles([...medicalRoles]);
  };

  const toggleBookingReason = (reason: BookingReason) => {
    const index = bookingReasons.indexOf(reason);
    if (index === -1) {
      bookingReasons.push(reason);
    } else {
      bookingReasons.splice(index, 1);
    }
    setBookingReasons([...bookingReasons]);
  };

  const changeExternalId = (externalId: ExternalID, value: string) => {
    externalIds[externalId] = value;
    setExternalIds({...externalIds});
  };

  const updateProfileAddress = async () => {
    try {
      const updatedProfile = await PostProfileRefreshAddress(profile.id);
      setAddress(updatedProfile.primaryAddress?.fullAddressString || null);
    } catch (error) {
      logger.error(error);
      window.alert("Kunde inte uppdatera addressen");
    }
  };

  const handleSubmit = async () => {
    if (!formValid) {
      return;
    }

    try {
      await PatchProfile({
        id: profile.id,
        email,
        telephoneNumber,
        role,
        givenName,
        surname,
        medicalRoles,
        externalIds,
        bookingReasons,
      });
      onSubmitted();
    } catch (error) {
      alert("Något gick fel, var god försök igen.");
      console.error(error);
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <FormControl fullWidth className={css.formControl}>
            <InputLabel htmlFor="givenName">Förnamn</InputLabel>
            <Input
              fullWidth
              id="givenName"
              type="text"
              value={givenName}
              onChange={(evt) => setGivenName(evt.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth className={css.formControl}>
            <InputLabel htmlFor="surname">Efternamn</InputLabel>
            <Input
              fullWidth
              id="surname"
              type="text"
              value={surname}
              onChange={(evt) => setSurname(evt.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
      <FormControl fullWidth className={css.formControl}>
        <InputLabel htmlFor="email">E-postaddress</InputLabel>
        <Input
          fullWidth
          id="email"
          type="text"
          value={email}
          onChange={(evt) => setEmail(evt.target.value)}
        />
      </FormControl>
      <FormControl fullWidth className={css.formControl}>
        <InputLabel htmlFor="telephoneNumber">Telefonnummer</InputLabel>
        <Input
          fullWidth
          id="telephoneNumber"
          type="text"
          value={telephoneNumber}
          onChange={(evt) => setTelephoneNumber(evt.target.value)}
        />
      </FormControl>
      <FormControl
        fullWidth
        className={css.formControl}
        style={{marginBottom: "40px"}}>
        <InputLabel htmlFor="address">Address</InputLabel>
        <Input fullWidth id="address" disabled type="text" value={address} />
        <Grid container justifyContent="flex-end">
          <Button
            startIcon={<RefreshOutlined />}
            onClick={updateProfileAddress}
            style={{marginTop: "-50px"}}>
            Uppdatera
          </Button>
        </Grid>
      </FormControl>
      <FormControl fullWidth className={css.formControl}>
        Arbetsroller
        <Grid container>
          {availableMedicalRoles.map((medicalRole, index) => (
            <Grid item key={index} xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={medicalRoles.includes(medicalRole)}
                    onChange={() => toggleMedicalRole(medicalRole)}
                  />
                }
                label={MedicalRoleFriendlyName(medicalRole)}
              />
            </Grid>
          ))}
        </Grid>
      </FormControl>
      <FormControl fullWidth className={css.formControl}>
        Kontaktorsaker
        <Grid container>
          {availableBookingReasons.map((reason, index) => (
            <Grid item key={index} xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={bookingReasons.includes(reason)}
                    onChange={() => toggleBookingReason(reason)}
                  />
                }
                label={localizeBookingReason(reason)}
              />
            </Grid>
          ))}
        </Grid>
      </FormControl>
      <FormControl
        fullWidth
        className={css.formControl}
        style={{marginBottom: "40px"}}>
        Systemroll
        <Select
          labelId="role-label"
          value={role}
          label="Roll"
          onChange={(evt: any) => setRole(evt.target.value)}>
          {roles.map((role, index) => (
            <MenuItem key={index} value={role}>
              {RoleFriendlyName(role)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth className={css.formControl}>
        Externa IDn
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>System</TableCell>
              <TableCell>Värde</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {editableExternalIds.map((externalId) => (
              <TableRow key={externalId}>
                <TableCell>{externalId}</TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    size="small"
                    onChange={(evt) =>
                      changeExternalId(externalId, evt.target.value)
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Key />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    id={`external_id_${externalId}`}
                    type="text"
                    value={externalIds[externalId] || ""}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </FormControl>
      <Grid container justifyContent="flex-end" style={{marginTop: "20px"}}>
        <Button
          variant="contained"
          color="primary"
          disabled={!formValid}
          onClick={handleSubmit}>
          Spara ändringar
        </Button>
      </Grid>
    </>
  );
};
