import React, {useEffect, useState} from "react";
import {PostVoiceGrant} from "../api";

import {CallEnd as CallEndIcon} from "@mui/icons-material";
import {Device, Call} from "@twilio/voice-sdk";
import {BoxModal} from "./BoxModal";
import useLogger from "../hooks/useLogger";
import {
  Alert,
  AlertTitle,
  Button,
  ButtonGroup,
  Fab,
  FormControl,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  NativeSelect,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import MicOffIcon from "@mui/icons-material/MicOff";
import {ensureCountryCode} from "../util";

type CallState =
  | CallStateInit
  | CallStateConnecting
  | CallStateError
  | CallStateRinging
  | CallStateAccepted
  | CallStateDisconnected;

interface CallStateInit {
  type: "init";
}

interface CallStateDisconnected {
  type: "disconnected";
}

interface CallStateConnecting {
  type: "connecting";
}

interface CallStateRinging {
  type: "ringing";
}

interface CallStateAccepted {
  type: "accepted";
}

interface CallStateError {
  type: "error";
  error: any;
}

interface Props {
  telephoneNumber: string | null;
  onClose: () => void;
}

const CallModal: React.VFC<Props> = (options) => {
  const logger = useLogger();
  const [callState, setCallState] = useState<CallState>({type: "init"});
  const [call, setCall] = useState<Call | null>(null);
  const [device, setDevice] = useState<Device | null>(null);
  const [callStarted, setCallStarted] = useState(false);
  const [telephoneNumberInput, setTelephoneNumberInput] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState<string>(
    options.telephoneNumber ? ensureCountryCode(options.telephoneNumber) : ""
  );

  const [availableInputDevices, setAvailableInputDevices] = useState<
    MediaDeviceInfo[]
  >([]);

  const [selectedDeviceId, setSelectedDeviceId] = useState<string>("default");

  const initializedDevice = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    stream.getTracks().forEach((track) => track.stop());
    const {token} = await PostVoiceGrant();
    const device = new Device(token, {});
    device.addListener("error", (error) => {
      logger.error("call: device error event", error);
      setCallState({type: "error", error});
    });
    device.addListener("registered", () => {});
    device.audio?.on("deviceChange", () => {
      setAvailableInputDevices(
        Array.from(device.audio?.availableInputDevices.values() || [])
      );
      setSelectedDeviceId(device?.audio?.inputDevice?.deviceId || "default");
      setDevice(device);
    });
    device.register();
  };

  const changeInputDevice = async (deviceId: string) => {
    await device!.audio?.setInputDevice(deviceId);
    setSelectedDeviceId(device!.audio?.inputDevice?.deviceId || "");
  };

  const startCall = async () => {
    if (!device) {
      return;
    }
    const call = await device.connect({params: {To: telephoneNumber}});
    call.addListener("error", (error) => {
      logger.error("call: call error event", error);
      setCallState({type: "error", error});
    });
    call.addListener("accept", () => {
      setCallState({type: "accepted"});
    });
    call.addListener("disconnect", () => {
      setCallState({type: "disconnected"});
    });
    setCall(call);
    setCallStarted(true);
  };

  const closeCall = async () => {
    if (call) {
      call.disconnect();
    }
    if (device) {
      device.disconnectAll();
      device.destroy();
    }
    options.onClose();
  };

  const isSelectedInputDevice = (d: MediaDeviceInfo): boolean => {
    return selectedDeviceId === d.deviceId;
  };

  useEffect(() => {
    initializedDevice();
    return () => {
      if (call) {
        call.disconnect();
      }
      if (device) {
        device.disconnectAll();
        device.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BoxModal width={800} open={true} onClose={() => {}}>
      {telephoneNumber === "" && (
        <>
          <div>
            <h3>Skriv telefonnummer att ringa</h3>
            <br />
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              value={telephoneNumberInput}
              onChange={(evt) => setTelephoneNumberInput(evt.target.value)}
              placeholder="T.ex +46 70 123 4567"
            />
            <br /> <br />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                setTelephoneNumber(ensureCountryCode(telephoneNumberInput));
              }}>
              Fortsätt
            </Button>
          </div>
        </>
      )}
      {telephoneNumber !== "" && callStarted && device && (
        <>
          <Typography variant="h5">Ringer {telephoneNumber}</Typography>
          {callState.type === "init" && <p>Initierar</p>}
          {callState.type === "error" && <p>Ett oväntat fel inträffade.</p>}
          {callState.type === "connecting" && <p>Ansluter.</p>}
          {callState.type === "disconnected" && <p>Samtalet avslutades.</p>}
          {callState.type === "ringing" && <p>Ringer.</p>}
          {callState.type === "accepted" && <p>Ansluten.</p>}
          <Fab
            color="error"
            aria-label="add"
            onClick={closeCall}
            style={{float: "right"}}>
            <CallEndIcon />
          </Fab>

          <FormControl
            fullWidth
            style={{marginTop: "40px", marginBottom: "20px"}}>
            <Typography variant="body2">Ändra mikrofon</Typography>
            <NativeSelect
              onChange={(evt) => changeInputDevice(evt.target.value)}
              value={device.audio?.inputDevice?.deviceId}>
              {availableInputDevices.map((device) => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </>
      )}
      {telephoneNumber !== "" && !callStarted && (
        <>
          {!device && <>Förbereder</>}
          {device && (
            <>
              <Alert color="info" style={{marginBottom: "20px"}}>
                <AlertTitle>Starta samtal</AlertTitle>
                <Typography variant="h5">{telephoneNumber}</Typography>
              </Alert>
              <FormControl fullWidth style={{marginBottom: "20px"}}>
                <Typography variant="body2">
                  Välj vilken mikrofon du vill använda
                </Typography>
                <List>
                  {availableInputDevices.map((d) => (
                    <ListItemButton
                      key={d.deviceId}
                      onClick={() => changeInputDevice(d.deviceId)}
                      selected={isSelectedInputDevice(d)}>
                      <ListItemIcon>
                        {isSelectedInputDevice(d) && <KeyboardVoiceIcon />}
                        {!isSelectedInputDevice(d) && <MicOffIcon />}
                      </ListItemIcon>
                      <ListItemText primary={d.label} />
                    </ListItemButton>
                  ))}
                </List>
              </FormControl>

              <ButtonGroup fullWidth orientation="horizontal">
                <Button
                  color="primary"
                  variant="contained"
                  disabled={selectedDeviceId === ""}
                  onClick={startCall}>
                  Starta samtal
                </Button>
                <Button
                  color="inherit"
                  variant="contained"
                  onClick={options.onClose}>
                  Avbryt
                </Button>
              </ButtonGroup>
            </>
          )}
        </>
      )}
    </BoxModal>
  );
};

export default CallModal;
