import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import {Button, Grid, ButtonGroup, Dialog, DialogContent} from "@mui/material";
import {
  Call as CallIcon,
  NoteAdd as NoteAddIcon,
  Receipt as ReceiptIcon,
  AccessTime as AccessTimeIcon,
} from "@mui/icons-material";
import React, {useState} from "react";
import {PostProfilesBan} from "../api";
import {Booking, Profile, FollowUpQueueItem} from "../types";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import IdentificationModal from "../components/IdentificationModal";
import FollowUpModal from "../components/FollowUpModal";
import {BankIDSmall} from "../Svg";
import BookingLinksModal from "../components/BookingLinksModal";
import InvoiceProfileModal from "../components/InvoiceProfileModal";
import {useClaimsMust} from "../hooks/useClaims";
import ReferralForm from "./ReferralForm";

interface ProfileActionButtonsProps {
  profile: Profile;
  followUpQueueItems: FollowUpQueueItem[];
  bookings: Booking[];
  onFollowUpChanged: () => void;
  onInvoicesChanged: () => void;
  onProfileChanged: () => void;
}

const ProfileActionButtons: React.VFC<ProfileActionButtonsProps> = ({
  profile,
  followUpQueueItems,
  onFollowUpChanged,
  onInvoicesChanged,
  onProfileChanged,
  bookings,
}) => {
  const claims = useClaimsMust();
  const [identificationModalOpen, setIdentificationModalOpen] = useState(false);
  const [followUpModalOpen, setFollowUpModalOpen] = useState(false);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [bookingLinksModalOpen, setBookingLinksModalOpen] = useState(false);
  const [referralModalOpen, setReferralModalOpen] = useState(false);

  const banUser = async () => {
    if (
      !window.confirm(
        "Är du säker på att du vill blockera denna användare? Detta innebär att inga fler bokningar kommer tillåtas samt att alla planerade återbesök ställs in."
      )
    ) {
      return;
    }
    await PostProfilesBan({id: profile.id, isBannedFromBooking: true});
    onProfileChanged();
  };

  const unBanUser = async () => {
    if (
      !window.confirm("Är du säker på att du vill av-blockera denna användare?")
    ) {
      return;
    }
    await PostProfilesBan({id: profile.id, isBannedFromBooking: false});
    onProfileChanged();
  };

  return (
    <>
      {invoiceModalOpen && (
        <InvoiceProfileModal
          profile={profile}
          onClose={() => {
            setInvoiceModalOpen(false);
            onInvoicesChanged();
          }}
        />
      )}
      {bookingLinksModalOpen && (
        <BookingLinksModal
          profile={profile}
          bookings={bookings}
          onClose={() => setBookingLinksModalOpen(false)}
        />
      )}
      {followUpModalOpen && (
        <FollowUpModal
          bookings={bookings}
          personalNumber={profile.personalNumber}
          onClose={() => {
            setFollowUpModalOpen(false);
            onFollowUpChanged();
          }}
        />
      )}
      {identificationModalOpen && (
        <IdentificationModal
          personalNumber={profile.personalNumber}
          onClose={() => setIdentificationModalOpen(false)}
        />
      )}
      {referralModalOpen && (
        <Dialog
          fullScreen={false}
          fullWidth
          maxWidth="md"
          open={true}
          onClose={() => {
            setReferralModalOpen(false);
          }}>
          <DialogContent>
            <ReferralForm
              patientProfile={profile}
              onContinue={() => {
                setReferralModalOpen(false);
              }}
            />
          </DialogContent>
        </Dialog>
      )}

      <Grid container spacing={2} style={{marginBottom: "15px"}}>
        <Grid item xs={6}>
          <Button
            onClick={() => setIdentificationModalOpen(true)}
            color="primary"
            variant="contained"
            fullWidth
            startIcon={
              <BankIDSmall
                style={{width: "20px", height: "20px", fill: "#FFF"}}
              />
            }>
            Starta bankID-legitimering
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            href={
              "/call?telephoneNumber=" +
              encodeURIComponent(profile.telephoneNumber)
            }
            target="_blank"
            color="primary"
            variant="contained"
            fullWidth
            startIcon={
              <CallIcon style={{width: "20px", height: "20px", fill: "#FFF"}} />
            }>
            Ring {profile.givenName}
          </Button>
        </Grid>
      </Grid>

      <ButtonGroup
        variant="contained"
        orientation="vertical"
        color="inherit"
        fullWidth>
        <Button
          color="inherit"
          href={`/profiles/${profile.personalNumber}/add-journal-record`}
          target="_blank"
          startIcon={
            <NoteAddIcon
              style={{width: "20px", height: "20px", fill: "#000"}}
            />
          }
          style={{
            borderBottom: "1px solid rgb(204, 204, 204)",
            borderBottomLeftRadius: "0",
            borderBottomRightRadius: "0",
          }}>
          Ny journalanteckning
        </Button>
        <Button
          onClick={() => setInvoiceModalOpen(true)}
          color="inherit"
          startIcon={
            <ReceiptIcon
              style={{width: "20px", height: "20px", fill: "#000"}}
            />
          }>
          Skapa faktura för extratjänst
        </Button>
        <Button
          onClick={() => setBookingLinksModalOpen(true)}
          color="inherit"
          startIcon={
            <AccessTimeIcon
              style={{width: "20px", height: "20px", fill: "#000"}}
            />
          }>
          Boka besök
        </Button>

        <Button
          onClick={() => setFollowUpModalOpen(true)}
          disabled={followUpQueueItems.length > 0}
          startIcon={
            <HistoryToggleOffIcon
              style={{width: "20px", height: "20px", fill: "#000"}}
            />
          }
          color="inherit">
          Schemalägg senare uppföljning{" "}
          {followUpQueueItems.length > 0 && "(redan bokat)"}
        </Button>

        <Button
          onClick={() => setReferralModalOpen(true)}
          startIcon={
            <ForwardToInboxIcon
              style={{width: "20px", height: "20px", fill: "#000"}}
            />
          }
          color="inherit">
          Skapa remiss
        </Button>
        {claims.role === "admin" && !profile.isBannedFromBooking && (
          <Button color="error" onClick={banUser}>
            Blockera användare
          </Button>
        )}
        {claims.role === "admin" && profile.isBannedFromBooking && (
          <Button color="success" onClick={unBanUser}>
            Av-blockera användare
          </Button>
        )}
      </ButtonGroup>
    </>
  );
};

export default ProfileActionButtons;
