import React from "react";
import {Note} from "../types";
import NotesList from "./NotesList";
import NoteForm from "./NoteForm";

interface NotesProps {
  personalNumber: string;
  taskId?: string;
  notes: Note[];
  loadNotes: () => void;
}

const Notes: React.VFC<NotesProps> = ({
  personalNumber,
  notes,
  taskId,
  loadNotes,
}) => {
  return (
    <>
      <NoteForm
        personalNumber={personalNumber}
        taskId={taskId}
        onNotePosted={loadNotes}
      />
      <div style={{marginTop: "20px"}}></div>
      <NotesList notes={notes} loadNotes={loadNotes} />
    </>
  );
};

export default Notes;
