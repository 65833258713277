import {createContext} from "react";
import {Task} from "../types";

export interface TaskContextData {
  tasks: Task[] | null;
  setTasks: (tasks: Task[]) => void;
}

export const TasksContext = createContext<TaskContextData>({
  tasks: null,
  setTasks: () => {},
});
