import {Button, Grid, Box} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {DeleteMessage} from "../api";
import {Message} from "../types";
import clsx from "clsx";
import {MedicalRolesFriendlyName} from "../util";

const useStyles = makeStyles((theme) => ({
  root: {},
  messageContainer: {
    border: "1px solid #CCC",
    marginTop: "10px",
    borderRadius: "10px",
  },
  deletedMessage: {
    backgroundColor: "#EEE",
  },
  messageHeader: {
    padding: "10px",
    borderBottom: "1px solid #CCC",
  },
  messageBody: {
    padding: "20px",
    borderBottom: "1px solid #CCC",
    whiteSpace: "pre-wrap",
  },
  messageTimestamp: {
    display: "block",
  },
  viewed: {
    padding: "10px",
    fontSize: "10px",
    color: "green",
  },
  notViewed: {
    padding: "10px",
    fontSize: "10px",
    color: "red",
  },
}));

interface MessageListProps {
  messages: Message[];
  onMessageDeleted: () => void;
}

export const MessageList: React.VFC<MessageListProps> = ({
  messages,
  onMessageDeleted,
}) => {
  const classes = useStyles();

  const deleteMessage = async (id: string) => {
    if (
      !window.confirm("Är du säker på att du vill ta bort detta meddelande?")
    ) {
      return;
    }
    try {
      await DeleteMessage(id);
      onMessageDeleted();
    } catch (error) {
      alert("Något gick fel, var god försök igen");
    }
  };

  return (
    <>
      {messages.map((message, i) => (
        <div
          className={clsx(classes.messageContainer, {
            [classes.deletedMessage]: message.deleted,
          })}
          key={i}>
          <div className={classes.messageHeader}>
            <time
              dateTime={message.created}
              className={classes.messageTimestamp}>
              {new Date(message.created).toLocaleString()}
            </time>
            <b>
              {message.sender.firstName} {message.sender.lastName},{" "}
              {MedicalRolesFriendlyName(message.sender.medicalRoles)}
            </b>
          </div>
          <div className={classes.messageBody}>{message.body}</div>
          <Grid container>
            <Grid item sm={6}>
              {message.deleted && (
                <div className={classes.notViewed}>
                  Meddelandet är borttaget
                </div>
              )}
              {message.viewedByReceiver && (
                <div className={classes.viewed}>
                  Mottagaren har läst meddelandet
                </div>
              )}
              {!message.viewedByReceiver && (
                <div className={classes.notViewed}>
                  Mottagaren har ej läst meddelandet
                </div>
              )}
            </Grid>
            <Grid item sm={6}>
              <Box display="flex" justifyContent="right">
                {!message.deleted && (
                  <Button
                    color="warning"
                    onClick={() => deleteMessage(message.id)}>
                    Ta bort meddelande
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </div>
      ))}
    </>
  );
};

export default MessageList;
