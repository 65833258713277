import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Input,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, {useEffect, useState} from "react";
import {GetMedicalRoles, GetRoles, PostProfiles} from "../api";
import {
  MedicalRoleFriendlyName,
  RoleFriendlyName,
  localizeBookingReason,
} from "../util";
import {BookingReason} from "../types";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: "auto",
    maxHeight: "80vh",
  },
  formControl: {
    marginBottom: "20px",
  },
}));

interface CreateProfileFormProps {
  onSubmitted: () => void;
}

export const CreateProfileForm: React.VFC<CreateProfileFormProps> = ({
  onSubmitted,
}) => {
  const css = useStyles();
  const availableBookingReasons: BookingReason[] = ["menopause", "pms"];
  const [bookingReasons, setBookingReasons] = useState<BookingReason[]>([]);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [telephoneNumber, setTelephoneNumber] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [medicalRoles, setMedicalRoles] = useState<string[]>([]);
  const [personalNumber, setPersonalNumber] = useState<string>("");
  const [roles, setRoles] = useState<string[]>([]);
  const [availableMedicalRoles, setAvailableMedicalRoles] = useState<string[]>(
    []
  );

  useEffect(() => {
    GetRoles().then(setRoles);
    GetMedicalRoles().then(setAvailableMedicalRoles);
  }, []);

  useEffect(() => {
    setFormValid(
      email !== "" &&
        telephoneNumber !== "" &&
        role !== "" &&
        personalNumber !== ""
    );
  }, [email, telephoneNumber, role, personalNumber]);

  const handleSubmit = async () => {
    if (!formValid) {
      return;
    }

    try {
      await PostProfiles({
        email,
        telephoneNumber,
        role,
        personalNumber,
        medicalRoles,
        bookingReasons,
      });
      alert("användaren är nu sparad");
      onSubmitted();
    } catch (error) {
      alert("Något gick fel, var god försök igen.");
      console.error(error);
    }
  };

  const toggleBookingReason = (reason: BookingReason) => {
    const index = bookingReasons.indexOf(reason);
    if (index === -1) {
      bookingReasons.push(reason);
    } else {
      bookingReasons.splice(index, 1);
    }
    setBookingReasons([...bookingReasons]);
  };

  const toggleMedicalRole = (medicalRole: string) => {
    const index = medicalRoles.indexOf(medicalRole);
    if (index === -1) {
      medicalRoles.push(medicalRole);
    } else {
      medicalRoles.splice(index, 1);
    }
    setMedicalRoles([...medicalRoles]);
  };

  return (
    <>
      <FormControl fullWidth className={css.formControl}>
        <InputLabel htmlFor="personalNumber">Personnummer</InputLabel>
        <Input
          fullWidth
          id="personalNumber"
          type="text"
          value={personalNumber}
          onChange={(evt) => setPersonalNumber(evt.target.value)}
        />
      </FormControl>
      <FormControl fullWidth className={css.formControl}>
        <InputLabel htmlFor="email">E-postaddress</InputLabel>
        <Input
          fullWidth
          id="email"
          type="text"
          value={email}
          onChange={(evt) => setEmail(evt.target.value)}
        />
      </FormControl>
      <FormControl fullWidth className={css.formControl}>
        <InputLabel htmlFor="telephoneNumber">Telefonnummer</InputLabel>
        <Input
          fullWidth
          id="telephoneNumber"
          type="text"
          value={telephoneNumber}
          onChange={(evt) => setTelephoneNumber(evt.target.value)}
        />
      </FormControl>
      <FormControl fullWidth className={css.formControl}>
        Arbetsroller
        <Grid container>
          {availableMedicalRoles.map((medicalRole, index) => (
            <Grid item key={index} xs={6}>
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={medicalRoles.includes(medicalRole)}
                    onChange={() => toggleMedicalRole(medicalRole)}
                  />
                }
                label={MedicalRoleFriendlyName(medicalRole)}
              />
            </Grid>
          ))}
        </Grid>
      </FormControl>
      <FormControl fullWidth className={css.formControl}>
        Kontaktorsaker
        <Grid container>
          {availableBookingReasons.map((reason, index) => (
            <Grid item key={index} xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={bookingReasons.includes(reason)}
                    onChange={() => toggleBookingReason(reason)}
                  />
                }
                label={localizeBookingReason(reason)}
              />
            </Grid>
          ))}
        </Grid>
      </FormControl>
      <FormControl fullWidth className={css.formControl}>
        Systemroll
        <Select
          labelId="role-label"
          value={role}
          label="Roll"
          onChange={(evt: any) => setRole(evt.target.value)}>
          {roles.map((role, index) => (
            <MenuItem key={index} value={role}>
              {RoleFriendlyName(role)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid container justifyContent="flex-end" style={{marginTop: "20px"}}>
        <Button
          variant="contained"
          color="primary"
          disabled={!formValid}
          onClick={handleSubmit}>
          Skapa ny användare
        </Button>
      </Grid>
    </>
  );
};
