import {PointOfInterest, Profile} from "../types";
import Map from "./Map";

import {InfoWindow, Marker} from "@react-google-maps/api";

import biotech from "../images/biotech.svg";
import home from "../images/home.svg";
import marker from "../images/marker.svg";
import {useEffect, useState} from "react";
import {NewLineText} from "./NewLineText";
import {GetPointsOfInterest, GetProfileLocation} from "../api";
import useLogger from "../hooks/useLogger";
import {CircularProgress} from "@mui/material";

interface ProfileMapProps {
  profile: Profile;
}

const ProfileMap: React.VFC<ProfileMapProps> = ({profile}) => {
  const logger = useLogger();
  const [homeLocation, setHomeLocation] = useState<google.maps.LatLng | null>(
    null
  );
  const address = profile.primaryAddress;
  const addressString = address
    ? `${address.fullAddressString}\n${address.postalCode} ${address.city} ${address.country}`
    : "";
  const [pois, setPois] = useState<PointOfInterest[] | null>(null);

  useEffect(() => {
    GetPointsOfInterest().then(setPois).catch(logger.error);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!pois) {
    return <CircularProgress />;
  }

  const onMapLoaded = async (map: google.maps.Map) => {
    const bounds = new window.google.maps.LatLngBounds();
    if (address) {
      try {
        const {lat, lng} = await GetProfileLocation(profile.personalNumber);
        setHomeLocation(new google.maps.LatLng({lat, lng}));
        bounds.extend(new window.google.maps.LatLng(lat, lng));
      } catch (error) {
        logger.error(`unable to get profile location ${error}`);
      }
    }
    pois.forEach((poi) => {
      bounds.extend(
        new window.google.maps.LatLng(poi.location.lat, poi.location.lng)
      );
    });
    map.fitBounds(bounds);
    map.setCenter(bounds.getCenter());
  };

  const onMapUnmounted = () => {};

  return (
    <Map
      width="100%"
      height="600px"
      onLoaded={onMapLoaded}
      onUnmounted={onMapUnmounted}
      center={{lat: 62.512318, lng: 15.20654}}
      zoom={4}>
      <ProfileMapMarkerCluster
        homeLocation={homeLocation}
        profile={profile}
        addressString={addressString}
        pois={pois}
      />
    </Map>
  );
};

const ProfileMapMarkerCluster: React.VFC<{
  homeLocation: google.maps.LatLng | null;
  profile: Profile;
  addressString: string;
  pois: PointOfInterest[];
}> = ({homeLocation, profile, addressString, pois}) => {
  const [activeLocation, setActiveLocation] = useState<number | null>(null);
  const icon = (type: string) => {
    switch (type) {
      case "test-location":
        return biotech;
      default:
        return marker;
    }
  };
  const markers = pois.map((poi, i) => (
    <Marker
      key={i}
      position={poi.location}
      onClick={() => setActiveLocation(i)}
      icon={{
        url: icon(poi.type),
      }}>
      {activeLocation === i && (
        <InfoWindow onCloseClick={() => setActiveLocation(null)}>
          <div>
            <b>{poi.title}</b>
            <br />
            <NewLineText text={poi.address} />
          </div>
        </InfoWindow>
      )}
    </Marker>
  ));

  if (homeLocation) {
    markers.push(
      <Marker
        key={-1}
        position={homeLocation}
        icon={{url: home}}
        onClick={() => setActiveLocation(-1)}>
        {activeLocation === -1 && (
          <InfoWindow onCloseClick={() => setActiveLocation(null)}>
            <div>
              <b>
                {profile.givenName} {profile.surname}
              </b>
              <br />
              <NewLineText text={addressString} />
            </div>
          </InfoWindow>
        )}
      </Marker>
    );
  }

  return <>{markers}</>;
};

export default ProfileMap;
