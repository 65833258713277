import {
  Alert,
  AlertTitle,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {
  PostAuthBankIDPhone,
  PostAuthBankIDCancel,
  PostAuthBankIDCollect,
} from "../api";
import {CallInitiator} from "../types";

type AuthenticationStatus =
  | "not-started"
  | "pending"
  | "sending"
  | "done"
  | "error"
  | "cancelled";

interface Props {
  personalNumber: string;
  onClose: () => void;
}

const IdentificationModal: React.VFC<Props> = ({personalNumber, onClose}) => {
  const [collectTimer, setCollectTimer] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [callInitiator, setCallInitiator] = useState<CallInitiator>("RP");
  const [identificationStatus, setIdentificationStatus] =
    useState<AuthenticationStatus>("not-started");
  const [orderRef, setOrderRef] = useState<string | null>(null);

  const startIdentification = useCallback(async () => {
    setIdentificationStatus("sending");
    const response = await PostAuthBankIDPhone({
      personalNumber,
      callInitiator: "RP",
    });
    setOrderRef(response.orderRef);
    setCollectTimer(new Date().getTime());
  }, [personalNumber]);

  const collect = useCallback(async () => {
    if (!orderRef) {
      return;
    }
    const {error, status} = await PostAuthBankIDCollect(orderRef);
    if (error) {
      setIdentificationStatus("error");
      setErrorMessage(error);
      return;
    }
    setIdentificationStatus((status as AuthenticationStatus) || null);
    if (status === "pending") {
      setTimeout(() => setCollectTimer(new Date().getTime()), 1000);
    }
  }, [orderRef]);

  const cancel = () => {
    if (orderRef) {
      PostAuthBankIDCancel(orderRef);
    }
  };

  useEffect(() => {
    if (collectTimer === null) {
      return;
    }
    collect();
  }, [orderRef, collectTimer, collect]);

  return (
    <Dialog
      fullScreen={false}
      fullWidth
      maxWidth="md"
      open={true}
      onClose={onClose}>
      {identificationStatus === "not-started" && (
        <>
          <DialogContent>
            <Typography variant="h5">
              Starta BankID-legitimering av telefonsamtal
            </Typography>
            <FormControl>
              <FormLabel>Vem initierade samtalet?</FormLabel>
              <RadioGroup
                defaultValue={callInitiator}
                onChange={(evt) =>
                  setCallInitiator(evt.target.value as CallInitiator)
                }>
                <FormControlLabel
                  value="RP"
                  control={<Radio />}
                  label="Jag initierade samtalet"
                />
                <FormControlLabel
                  value="user"
                  control={<Radio />}
                  label="Patienten initierade samtalet"
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <ButtonGroup fullWidth>
              <Button
                variant="contained"
                color="primary"
                onClick={startIdentification}>
                Starta legitimering
              </Button>
              <Button variant="contained" color="inherit" onClick={onClose}>
                Avbryt
              </Button>
            </ButtonGroup>
          </DialogActions>
        </>
      )}
      {identificationStatus === "error" && (
        <>
          <DialogTitle>
            <Typography variant="h5">Ett fel uppstod</Typography>
          </DialogTitle>
          <DialogContent>
            <Alert color="error">
              <AlertTitle>{errorMessage}</AlertTitle>
              <p>
                Kunden kan ha en pågående legitimering eller ett annat tekniskt
                fel kan ha inträffat. Var god försök igen.
              </p>
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={onClose}>
              Stäng fönstret
            </Button>
          </DialogActions>
        </>
      )}
      {identificationStatus === "done" && (
        <>
          <DialogTitle>
            <Typography variant="h5">Patienten är nu legitimerad</Typography>
          </DialogTitle>
          <DialogContent>
            <Alert color="success">
              <AlertTitle>Legitimeringen lyckades</AlertTitle>
              Patienten är nu legitimerad.
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={onClose}>
              Stäng fönstret
            </Button>
          </DialogActions>
        </>
      )}
      {identificationStatus === "sending" ||
        (identificationStatus === "pending" && (
          <>
            <DialogTitle>
              <Typography variant="h5">
                Inväntar svar från Patienten: {identificationStatus}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Stack alignItems="center">
                <CircularProgress style={{margin: "20px"}} />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={cancel}>
                Avbryt legitimering
              </Button>
            </DialogActions>
          </>
        ))}
      {identificationStatus === "cancelled" && (
        <>
          <DialogTitle>
            <Typography variant="h3">Legitimeringen avbröts</Typography>
          </DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={onClose}>
              Stäng fönstret
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default IdentificationModal;
