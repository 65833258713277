import {
  CircularProgress,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Grid,
  Typography,
  LinearProgress,
  TableFooter,
  Box,
} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {GetProfiles, GetSlots} from "../../api";
import {Divider} from "../../components/Divider";
import {DateRange, Profile} from "../../types";
import useLogger from "../../hooks/useLogger";
import Nav from "../../components/Nav";
import DateRangeSelector from "../../components/dates/DateRangeSelector";

interface BookingsSummary {
  total: number;
  unbooked: number;
  booked: number;
  bookingRatePercent: number;
}

const hasBookableRoles = (profile: Profile): boolean => {
  return (
    profile.medicalRoles.includes("medical-doctor") ||
    profile.medicalRoles.includes("midwife") ||
    profile.medicalRoles.includes("sexologist") ||
    profile.medicalRoles.includes("therapist") ||
    profile.medicalRoles.includes("gynecologist")
  );
};

const BookingRateReport = () => {
  const [profiles, setProfiles] = useState<Profile[] | null>(null);
  const [summaries, setSummaries] = useState<Record<string, BookingsSummary>>(
    {}
  );
  const logger = useLogger();
  const [totals, setTotals] = useState<BookingsSummary | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [range, setRange] = useState<DateRange | null>(null);
  const loadSlots = useCallback(async () => {
    if (!range) {
      return;
    }
    if (!profiles) {
      return;
    }
    setSummaries({});
    setTotals(null);

    setIsLoading(true);
    const {start, end} = range;
    let totals: BookingsSummary = {
      total: 0,
      unbooked: 0,
      booked: 0,
      bookingRatePercent: 0,
    };
    const s: Record<string, BookingsSummary> = {};
    for (let i = 0; i < profiles.length; i++) {
      const profile = profiles[i];
      try {
        const slots = await GetSlots({
          personalNumber: profile.personalNumber,
          kind: "doctor",
          showOnlyAvailable: false,
          from: start.toISOString(),
          to: end.toISOString(),
        });

        const total = slots.length;
        const booked = slots.filter((s) => s.hasOverlappingBooking).length;
        const unbooked = total - booked;
        totals.total += total;
        totals.booked += booked;
        totals.unbooked += unbooked;
        const bookingRatePercent =
          total === 0 ? 0 : Math.round((booked / total) * 100);

        s[profile.id] = {
          total,
          booked,
          unbooked,
          bookingRatePercent,
        };
      } catch (error) {
        logger.error(error);
      }
    }
    totals.bookingRatePercent =
      totals.total === 0 ? 0 : Math.round((totals.booked / totals.total) * 100);
    setTotals(totals);
    setIsLoading(false);
    setSummaries(s);
  }, [profiles, range, logger]);

  useEffect(() => {
    GetProfiles({roles: ["admin", "medical"], skip: 0, limit: 100}).then(
      (profiles) =>
        setProfiles(profiles.filter((profile) => hasBookableRoles(profile)))
    );
  }, []);

  useEffect(() => {
    loadSlots();
  }, [loadSlots]);

  if (!profiles) {
    return <Nav highlightedSection="reports" content={<CircularProgress />} />;
  }

  return (
    <Nav
      highlightedSection="reports"
      content={
        <>
          <Grid container>
            <Grid item sm={6}>
              <Typography variant="h4">Beläggningsgrad</Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <DateRangeSelector defaultMode="week" onChange={setRange} />
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Person</TableCell>
                  <TableCell>Totalt antal tider</TableCell>
                  <TableCell>Tillgängliga tider</TableCell>
                  <TableCell>Bokade tider</TableCell>
                  <TableCell>Bokningsgrad</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {profiles.map((profile) => (
                  <TableRow key={profile.id}>
                    <TableCell>
                      {profile.givenName} {profile.surname}
                    </TableCell>
                    {isLoading && (
                      <TableCell colSpan={4}>
                        <LinearProgress />
                      </TableCell>
                    )}
                    {!summaries[profile.id] && !isLoading && (
                      <TableCell colSpan={4}>Saknar uppgifter</TableCell>
                    )}
                    {summaries[profile.id] && (
                      <>
                        <TableCell>{summaries[profile.id]?.total}</TableCell>
                        <TableCell>{summaries[profile.id]?.unbooked}</TableCell>
                        <TableCell>{summaries[profile.id]?.booked}</TableCell>
                        <TableCell>
                          {summaries[profile.id]?.bookingRatePercent}%
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
              {totals && (
                <TableFooter>
                  <TableRow>
                    <TableCell>Summa: </TableCell>
                    <TableCell>{totals.total}</TableCell>
                    <TableCell>{totals.unbooked}</TableCell>
                    <TableCell>{totals.booked}</TableCell>
                    <TableCell>{totals.bookingRatePercent}%</TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </>
      }
    />
  );
};

export default BookingRateReport;
