import {useEffect, useState} from "react";
import {DateRange} from "../../types";
import {FormControl, MenuItem, Select} from "@mui/material";
import WeekSelector from "./WeekSelector";
import {MonthSelector} from "./MonthSelector";

type DateRangeSelectorMode = "week" | "month";

interface DateRangeSelectorProps {
  defaultMode: DateRangeSelectorMode;
  dateMin?: Date;
  dateMax?: Date;
  onChange: (range: DateRange) => void;
}

const DateRangeSelector: React.VFC<DateRangeSelectorProps> = ({
  defaultMode,
  onChange,
}) => {
  const now = new Date();
  const yearMin = 2021;
  const yearMax = now.getFullYear() + 1;
  const [mode, setMode] = useState<DateRangeSelectorMode>(defaultMode);
  const [monthSelectorValue, setMonthSelectorValue] = useState<Date>(
    new Date(now.getFullYear(), now.getMonth())
  );
  useEffect(() => {
    if (mode !== "month") {
      return;
    }
    const end = new Date(monthSelectorValue);
    end.setMonth(end.getMonth() + 1);
    onChange({start: monthSelectorValue, end});
  }, [mode, monthSelectorValue]);

  return (
    <>
      <FormControl variant="outlined">
        <Select
          size="small"
          value={mode}
          onChange={(evt) =>
            setMode(evt.target.value as DateRangeSelectorMode)
          }>
          <MenuItem value={"week"}>Veckovis</MenuItem>
          <MenuItem value={"month"}>Månadsvis</MenuItem>
        </Select>
      </FormControl>
      {mode === "week" && <WeekSelector onChange={onChange} />}
      {mode === "month" && (
        <MonthSelector
          value={monthSelectorValue}
          yearMin={yearMin}
          yearMax={yearMax}
          onChange={setMonthSelectorValue}
        />
      )}
    </>
  );
};

export default DateRangeSelector;
