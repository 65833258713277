import {
  Alert,
  Badge,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {GetBookings, GetFollowUpQueueStats} from "../api";
import BookingList from "../components/BookingList";
import GoogleCalendarConnect from "../components/GoogleCalendarConnect";
import {Booking, BookingQuery, FollowUpQueueWeeklyCount, Week} from "../types";
import {getFutureDate, getWeekNumbersBetween, today, tomorrow} from "../util";
import {useClaimsMust} from "../hooks/useClaims";
import Nav from "../components/Nav";
import TabPanel from "../components/TabPanel";

const Home = () => {
  return (
    <Nav
      highlightedSection="home"
      content={
        <>
          <GoogleCalendarConnect />
          <BookingTabs />
        </>
      }
    />
  );
};

const BookingTabs: React.VFC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [bookingsToday, setBookingsToday] = useState<Booking[] | null>(null);
  const [showCancelled, setShowCancelled] = useState<boolean>(false);
  const claims = useClaimsMust();
  const [bookingsForCheckist, setBookingsForFollowUp] = useState<
    Booking[] | null
  >(null);

  const [followUpQueueStats, setFollowUpQueueStats] = useState<
    FollowUpQueueWeeklyCount[] | null
  >(null);
  const rangeFrom = new Date();
  // Note that this range is equivalent to how far
  // in the future we do automatic bookings.
  const [rangeTo] = useState<Date>(getFutureDate(30 * 3));
  const [weeks] = useState<Week[]>(getWeekNumbersBetween(rangeFrom, rangeTo));

  const loadTodaysBookings = useCallback(async () => {
    let filter: BookingQuery = {
      startsAfter: today().toISOString(),
      startsBefore: tomorrow().toISOString(),
      bookedPersonId: claims.id,
    };
    if (showCancelled === false) {
      filter["cancelled"] = false;
    }
    const bookingsToday = await GetBookings(filter);
    setBookingsToday(bookingsToday);
  }, [showCancelled, claims.id]);

  const loadBookingsForChecklist = useCallback(async () => {
    const invoicing = await GetBookings({
      bookedPersonId: claims.id,
      startsBefore: new Date().toISOString(),
      isInvoiced: false,
      cancelled: false,
    });

    const followUp = await GetBookings({
      bookedPersonId: claims.id,
      startsBefore: new Date().toISOString(),
      hasHandledFollowUp: false,
      cancelled: false,
    });

    setBookingsForFollowUp(
      [...followUp, ...invoicing].filter(
        (v, i, self) => self.findIndex((v2) => v2.id === v.id) === i
      )
    );
  }, [claims.id]);

  useEffect(() => {
    const query: any = {
      to: rangeTo,
      bookedPersonProfileId: claims.id,
    };
    GetFollowUpQueueStats(query).then(setFollowUpQueueStats);
  }, [rangeTo, claims.id]);

  useEffect(() => {
    loadTodaysBookings();
  }, [loadTodaysBookings]);

  useEffect(() => {
    loadBookingsForChecklist();
  }, [loadBookingsForChecklist]);

  if (!bookingsToday) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box sx={{borderBottom: 1, borderColor: "divider", marginBottom: "10px"}}>
        <Grid container>
          <Grid item sm={9}>
            <Tabs
              onChange={(_, newValue) => setSelectedTab(newValue)}
              indicatorColor="primary"
              textColor="primary"
              value={selectedTab}>
              <Tab label="Möten idag" />
              <Tab
                label={
                  <Badge
                    badgeContent={
                      bookingsForCheckist && bookingsForCheckist.length
                    }
                    color="warning">
                    <div style={{marginRight: "12px", fontSize: "0.875rem"}}>
                      Checklista efter mötet
                    </div>
                  </Badge>
                }
              />
              <Tab
                label={
                  <Badge
                    badgeContent={
                      followUpQueueStats &&
                      followUpQueueStats
                        .map((p) => p.followUps)
                        .reduce((p, c) => c + p, 0)
                    }
                    color="info">
                    <div style={{marginRight: "12px", fontSize: "0.875rem"}}>
                      Väntelista för uppföljning
                    </div>
                  </Badge>
                }
              />
            </Tabs>
          </Grid>
          <Grid item sm={3} justifyContent="flex-end">
            <TabPanel value={selectedTab} index={0}>
              <FormControlLabel
                style={{float: "right"}}
                control={
                  <Checkbox
                    checked={showCancelled}
                    onChange={(evt) => setShowCancelled(evt.target.checked)}
                  />
                }
                label="Visa inställda möten"
              />
            </TabPanel>
          </Grid>
        </Grid>
      </Box>
      <TabPanel value={selectedTab} index={0}>
        <BookingList
          emptyTitle="Du har inga möten idag"
          showLinkToProfile={true}
          onReloadNeeded={loadTodaysBookings}
          bookings={bookingsToday}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        {bookingsForCheckist && (
          <BookingList
            emptyTitle="Du har inga checklistor att fylla i"
            showLinkToProfile={true}
            showFollowUpSummary={true}
            onReloadNeeded={loadBookingsForChecklist}
            bookings={bookingsForCheckist}
          />
        )}
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        {!followUpQueueStats && <CircularProgress />}
        {followUpQueueStats && (
          <FollowUpPlanner
            followUpQueueStats={followUpQueueStats}
            weeks={weeks}
          />
        )}
      </TabPanel>
    </>
  );
};

interface FollowUpPlannerProps {
  followUpQueueStats: FollowUpQueueWeeklyCount[];
  weeks: Week[];
}

const FollowUpPlanner: React.VFC<FollowUpPlannerProps> = ({
  followUpQueueStats,
  weeks,
}) => {
  const weeksWithFollowUps = weeks.filter((week) =>
    followUpQueueStats.find(
      (fuqs) => fuqs.week === week.number && fuqs.followUps > 0
    )
  );
  if (weeksWithFollowUps.length === 0) {
    return <Alert color="info">Alla dina uppföljningar är inbokade.</Alert>;
  }
  return (
    <>
      <Alert color="info">
        Tabellen nedan visar antalet personer som står i kö för att få ett
        återbesök med dig, men återbesöket kan inte planeras då det inte finns
        tillängliga tider.
        <p>
          Vänligen säkerställ att du har tillräckligt med lediga tider under
          perioderna så att besöken kan planeras.
        </p>
      </Alert>
      <TableContainer style={{marginTop: "10px"}} component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Vecka</TableCell>
              <TableCell>År</TableCell>
              <TableCell>Antal på väntelistan</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {weeksWithFollowUps.map((week, i) => (
              <TableRow key={i}>
                <TableCell>{week.number}</TableCell>
                <TableCell>{week.year}</TableCell>
                <TableCell>
                  {
                    followUpQueueStats.find((fuqs) => fuqs.week === week.number)
                      ?.followUps
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Home;
