import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import {useEffect, useState} from "react";
import {Divider} from "../../components/Divider";
import {GetStatsBookings} from "../../api";
import {MedicalRolesFriendlyName, localizeSlotKind} from "../../util";
import {
  BookingCancellationStatusAggregate,
  BookingsPerSlotKind,
  BookingsStats,
  DateRange,
  SlotKind,
} from "../../types";
import Nav from "../../components/Nav";
import DateRangeSelector from "../../components/dates/DateRangeSelector";

const useStyles = makeStyles((theme) => ({
  summaryRow: {
    "& td": {
      backgroundColor: "#e3f1ff",
      fontWeight: "bold",
    },
  },
  tableHeader: {
    "& th": {
      backgroundColor: "#EFEFEF",
      color: "#000",
      fontWeight: "bold",
    },
  },
  tableFooter: {
    "& td": {
      backgroundColor: "#EFEFEF",
      color: "#000",
      fontWeight: "bold",
    },
  },
}));

const sumBookingsPerSlotKind = (
  bps: BookingsPerSlotKind,
  key: keyof BookingCancellationStatusAggregate
): number => {
  return Object.entries(bps).reduce((p, [_, v]) => p + v[key], 0);
};

const percent = (
  bps: BookingsPerSlotKind,
  keyA: keyof BookingCancellationStatusAggregate,
  keyT: keyof BookingCancellationStatusAggregate
): string => {
  return (
    (sumBookingsPerSlotKind(bps, keyA) / sumBookingsPerSlotKind(bps, keyT)) *
    100
  ).toFixed(2);
};

const BookingsReport = () => {
  const styles = useStyles();
  const [range, setRange] = useState<DateRange>();
  const [bookingsReport, setBookingsReport] = useState<BookingsStats | null>(
    null
  );

  useEffect(() => {
    if (!range) {
      return;
    }
    setBookingsReport(null);
    const {start, end} = range;
    GetStatsBookings({
      from: start.toISOString(),
      to: end.toISOString(),
    }).then(setBookingsReport);
  }, [range]);

  return (
    <Nav
      highlightedSection={"reports"}
      content={
        <>
          <Grid container>
            <Grid item sm={6}>
              <Typography variant="h4">Bokade möten</Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <DateRangeSelector defaultMode="month" onChange={setRange} />
              </Box>
            </Grid>
          </Grid>
          <Divider />
          {!bookingsReport && <CircularProgress />}
          {bookingsReport && (
            <>
              <Typography style={{margin: "20px 0px"}} variant="h5">
                Möten som genomförts inom perioden
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small" style={{whiteSpace: "nowrap"}}>
                  <TableHead className={styles.tableHeader}>
                    <TableRow>
                      <TableCell>Mötestyp</TableCell>
                      <TableCell>Bokade</TableCell>
                      <TableCell>Avbokade</TableCell>
                      <TableCell>Avbokade %</TableCell>
                      <TableCell>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(
                      bookingsReport.bookingsPerSlotKindInPeriod
                    ).map(([slotKind, values]) => (
                      <TableRow>
                        <TableCell>
                          {localizeSlotKind(slotKind as SlotKind)}
                        </TableCell>
                        <TableCell>{values.numNonCancelled}</TableCell>
                        <TableCell>{values.numCancelled} </TableCell>
                        <TableCell>
                          {((values.numCancelled / values.total) * 100).toFixed(
                            2
                          )}
                          %
                        </TableCell>
                        <TableCell>{values.total}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter className={styles.tableFooter}>
                    <TableCell>Summa</TableCell>
                    <TableCell>
                      {sumBookingsPerSlotKind(
                        bookingsReport.bookingsPerSlotKindInPeriod,
                        "numNonCancelled"
                      )}
                    </TableCell>
                    <TableCell>
                      {sumBookingsPerSlotKind(
                        bookingsReport.bookingsPerSlotKindInPeriod,
                        "numCancelled"
                      )}
                    </TableCell>
                    <TableCell>
                      {percent(
                        bookingsReport.bookingsPerSlotKindInPeriod,
                        "numCancelled",
                        "total"
                      )}
                      %
                    </TableCell>
                    <TableCell>
                      {sumBookingsPerSlotKind(
                        bookingsReport.bookingsPerSlotKindInPeriod,
                        "total"
                      )}
                    </TableCell>
                  </TableFooter>
                </Table>
              </TableContainer>
              <Typography style={{margin: "20px 0px"}} variant="h5">
                Nya möten som är bokade inom perioden
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small" style={{whiteSpace: "nowrap"}}>
                  <TableHead className={styles.tableHeader}>
                    <TableRow>
                      <TableCell>Källa</TableCell>
                      <TableCell>Mötestyp</TableCell>
                      <TableCell>Bokade</TableCell>
                      <TableCell>Avbokade</TableCell>
                      <TableCell>Avbokade %</TableCell>
                      <TableCell>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bookingsReport.bookingsPerSource.map(
                      ({metaSource, bookingsPerSlotKind}) => (
                        <>
                          <TableRow className={styles.summaryRow}>
                            <TableCell>
                              {metaSource === "" ? "Organisk" : metaSource}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                              {sumBookingsPerSlotKind(
                                bookingsPerSlotKind,
                                "numNonCancelled"
                              )}
                            </TableCell>
                            <TableCell>
                              {sumBookingsPerSlotKind(
                                bookingsPerSlotKind,
                                "numCancelled"
                              )}
                            </TableCell>
                            <TableCell>
                              {percent(
                                bookingsPerSlotKind,
                                "numCancelled",
                                "total"
                              )}
                              %
                            </TableCell>
                            <TableCell>
                              {sumBookingsPerSlotKind(
                                bookingsPerSlotKind,
                                "total"
                              )}
                            </TableCell>
                          </TableRow>
                          {Object.entries(bookingsPerSlotKind).map(
                            ([slotKind, values]) => (
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell>
                                  {localizeSlotKind(slotKind as SlotKind)}
                                </TableCell>
                                <TableCell>{values.numNonCancelled}</TableCell>
                                <TableCell>{values.numCancelled}</TableCell>
                                <TableCell>
                                  {(
                                    (values.numCancelled / values.total) *
                                    100
                                  ).toFixed(2)}
                                  %
                                </TableCell>
                                <TableCell>{values.total}</TableCell>
                              </TableRow>
                            )
                          )}
                        </>
                      )
                    )}
                  </TableBody>
                  <TableFooter className={styles.tableFooter}>
                    <TableCell colSpan={2}>Summa</TableCell>
                    <TableCell>
                      {bookingsReport.bookingsPerSource
                        .map(({bookingsPerSlotKind}) =>
                          sumBookingsPerSlotKind(
                            bookingsPerSlotKind,
                            "numNonCancelled"
                          )
                        )
                        .reduce((a, b) => a + b, 0)}
                    </TableCell>
                    <TableCell>
                      {bookingsReport.bookingsPerSource
                        .map(({bookingsPerSlotKind}) =>
                          sumBookingsPerSlotKind(
                            bookingsPerSlotKind,
                            "numCancelled"
                          )
                        )
                        .reduce((a, b) => a + b, 0)}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {bookingsReport.bookingsPerSource
                        .map(({bookingsPerSlotKind}) =>
                          sumBookingsPerSlotKind(bookingsPerSlotKind, "total")
                        )
                        .reduce((a, b) => a + b, 0)}
                    </TableCell>
                  </TableFooter>
                </Table>
              </TableContainer>
              <Typography style={{margin: "20px 0px"}} variant="h5">
                Genomförda möten per bokad person under perioden
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small" style={{whiteSpace: "nowrap"}}>
                  <TableHead className={styles.tableHeader}>
                    <TableRow>
                      <TableCell>Bokad person</TableCell>
                      <TableCell>Mötestyp</TableCell>
                      <TableCell>Bokade</TableCell>
                      <TableCell>Avbokade</TableCell>
                      <TableCell>Avbokade %</TableCell>
                      <TableCell>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bookingsReport.bookingsPerBookedPerson.map(
                      ({profile, bookingsPerSlotKind}) => (
                        <>
                          <TableRow className={styles.summaryRow}>
                            <TableCell>
                              {profile.givenName} {profile.surname},{" "}
                              {MedicalRolesFriendlyName(profile.medicalRoles)}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                              {sumBookingsPerSlotKind(
                                bookingsPerSlotKind,
                                "numNonCancelled"
                              )}
                            </TableCell>
                            <TableCell>
                              {sumBookingsPerSlotKind(
                                bookingsPerSlotKind,
                                "numCancelled"
                              )}
                            </TableCell>
                            <TableCell>
                              {percent(
                                bookingsPerSlotKind,
                                "numCancelled",
                                "total"
                              )}
                              %
                            </TableCell>
                            <TableCell>
                              {sumBookingsPerSlotKind(
                                bookingsPerSlotKind,
                                "total"
                              )}
                            </TableCell>
                          </TableRow>
                          {Object.entries(bookingsPerSlotKind).map(
                            ([slotKind, values]) => (
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell>
                                  {localizeSlotKind(slotKind as SlotKind)}
                                </TableCell>
                                <TableCell>{values.numNonCancelled}</TableCell>
                                <TableCell>{values.numCancelled}</TableCell>
                                <TableCell>
                                  {(
                                    (values.numCancelled / values.total) *
                                    100
                                  ).toFixed(2)}
                                  %
                                </TableCell>
                                <TableCell>{values.total}</TableCell>
                              </TableRow>
                            )
                          )}
                        </>
                      )
                    )}
                  </TableBody>
                  <TableFooter className={styles.tableFooter}>
                    <TableCell colSpan={2}>Summa</TableCell>
                    <TableCell>
                      {bookingsReport.bookingsPerBookedPerson
                        .map(({bookingsPerSlotKind}) =>
                          sumBookingsPerSlotKind(
                            bookingsPerSlotKind,
                            "numNonCancelled"
                          )
                        )
                        .reduce((a, b) => a + b, 0)}
                    </TableCell>
                    <TableCell>
                      {bookingsReport.bookingsPerBookedPerson
                        .map(({bookingsPerSlotKind}) =>
                          sumBookingsPerSlotKind(
                            bookingsPerSlotKind,
                            "numCancelled"
                          )
                        )
                        .reduce((a, b) => a + b, 0)}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {bookingsReport.bookingsPerBookedPerson
                        .map(({bookingsPerSlotKind}) =>
                          sumBookingsPerSlotKind(bookingsPerSlotKind, "total")
                        )
                        .reduce((a, b) => a + b, 0)}
                    </TableCell>
                  </TableFooter>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      }
    />
  );
};

export default BookingsReport;
