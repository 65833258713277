import {
  Button,
  ButtonGroup,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {MonthNames, getYearsInRange} from "../../util";

export interface MonthSelectorProps {
  yearMin: number;
  yearMax: number;
  value: Date;
  onChange: (date: Date) => void;
}

export const MonthSelector: React.VFC<MonthSelectorProps> = ({
  yearMin,
  yearMax,
  value,
  onChange,
}) => {
  const years = getYearsInRange(yearMin, yearMax);

  const onMonthChanged = (evt: SelectChangeEvent<number>) => {
    const newDate = new Date(value.getFullYear(), Number(evt.target.value));
    onChange(newDate);
  };

  const onYearChanged = (evt: SelectChangeEvent<number>) => {
    const newDate = new Date(Number(evt.target.value), value.getMonth());
    onChange(newDate);
  };

  const navigateToNextMonth = () => {
    const newDate = new Date(value.getFullYear(), value.getMonth() + 1);
    if (newDate.getFullYear() > yearMax) {
      return;
    }
    onChange(newDate);
  };

  const navigateToPrevMonth = () => {
    const newDate = new Date(value.getFullYear(), value.getMonth() - 1);
    if (newDate.getFullYear() < yearMin) {
      return;
    }
    onChange(newDate);
  };

  const navigateToNow = () => {
    const newDate = new Date(new Date().getFullYear(), new Date().getMonth());
    onChange(newDate);
  };

  return (
    <>
      <FormControl variant="outlined">
        <Select
          size="small"
          value={value.getFullYear()}
          onChange={onYearChanged}>
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined">
        <Select size="small" value={value.getMonth()} onChange={onMonthChanged}>
          {MonthNames.map((month, i) => (
            <MenuItem key={i} value={i}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <ButtonGroup
        style={{marginLeft: "20px", height: "38px"}}
        variant="outlined">
        <Button
          color="inherit"
          onClick={navigateToPrevMonth}
          disabled={value.getFullYear() === yearMin && value.getMonth() === 0}>
          &laquo;
        </Button>
        <Button
          color="primary"
          disabled={
            value.getFullYear() === new Date().getFullYear() &&
            value.getMonth() === new Date().getMonth()
          }
          onClick={navigateToNow}>
          Denna månad
        </Button>
        <Button
          color="inherit"
          onClick={navigateToNextMonth}
          disabled={value.getFullYear() === yearMax && value.getMonth() === 11}>
          &raquo;
        </Button>
      </ButtonGroup>
    </>
  );
};
