import React from "react";

export const NewLineText: React.VFC<{ text: string }> = ({ text }) => {
  const split = text.split("\n");
  const joined = split.map((text, i) => (
    <React.Fragment key={i}>
      {text} <br />
    </React.Fragment>
  ));
  return <>{joined}</>;
};
