import {Button, Box, Alert} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {DeleteNote} from "../api";
import {Note} from "../types";
import UserAvatar from "./UserAvatar";
import {L10nDate} from "./Date";

interface NotesProps {
  notes: Note[];
  loadNotes: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},

  noteBodyContainer: {
    float: "left",
    width: "calc(100% - 80px)",
  },
  avatarContainer: {
    float: "left",
    width: "40px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginRight: "20px",
  },
  noteContainer: {
    width: "100%",
    content: "",
    clear: "both",
    display: "table",
  },
  noteHeader: {
    paddingBottom: "7px",
  },
  noteBody: {
    padding: "20px",
    background: "#EFEFEF",
    borderRadius: "5px",
    whiteSpace: "pre-wrap",
  },
  viewed: {
    padding: "10px",
    fontSize: "10px",
    color: "green",
  },
  notViewed: {
    padding: "10px",
    fontSize: "10px",
    color: "red",
  },
}));

const NotesList: React.VFC<NotesProps> = ({notes, loadNotes}) => {
  const classes = useStyles();

  const deleteNote = async (id: string) => {
    if (
      !window.confirm("Är du säker på att du vill ta bort denna anteckning?")
    ) {
      return;
    }
    try {
      await DeleteNote(id);
      loadNotes();
    } catch (error) {
      alert("Något gick fel, var god försök igen");
    }
  };

  return (
    <>
      {notes.length === 0 && <Alert color="success">Inga anteckningar</Alert>}
      {notes.length > 0 &&
        notes.map((note, i) => (
          <div className={classes.noteContainer} key={i}>
            <div className={classes.avatarContainer}>
              <UserAvatar profilePicture={note.sender.profilePicture} />
            </div>
            <div className={classes.noteBodyContainer}>
              <div className={classes.noteHeader}>
                <b>
                  {note.sender.firstName} {note.sender.lastName},{" "}
                  {note.sender.role}
                </b>{" "}
                <L10nDate
                  options={{dateStyle: "short", timeStyle: "short"}}
                  dateTime={note.created}
                />
              </div>
              <div className={classes.noteBody}>{note.body}</div>
              <Box display="flex" justifyContent="right">
                <Button color="warning" onClick={() => deleteNote(note.id)}>
                  Ta bort anteckning
                </Button>
              </Box>
            </div>
          </div>
        ))}
    </>
  );
};

export default NotesList;
