import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {GetQuiz, GetQuizResult} from "../api";
import {Answer, Question, Quiz, QuizResult} from "../types";
import Nav from "../components/Nav";

interface Params {
  id: string;
}

const ViewQuizResult = () => {
  const {id} = useParams<Params>();
  const [quizResult, setQuizResult] = useState<QuizResult | null>(null);
  const [quiz, setQuiz] = useState<Quiz | null>(null);

  const getOptionTitle = (question: Question, value: string) => {
    return question?.options?.find((o) => o.value === value)?.title || "N/A";
  };

  const formatAnswer = (questionId: string, answer: Answer) => {
    const question = quiz?.questions?.find((q) => q.id === questionId);
    const answerValue = answer.answer;
    if (!question) return "ERROR";
    if (question.kind === "range")
      return `${answerValue} (${question.min} - ${question.max})`;
    if (Array.isArray(answerValue)) {
      return answerValue.map((a) => getOptionTitle(question, a)).join(", ");
    }
    return getOptionTitle(question, answerValue as string);
  };

  const loadQuiz = async () => {
    const quizResult = await GetQuizResult(id);
    setQuizResult(quizResult);
    const quiz = await GetQuiz(quizResult.quizType);
    setQuiz(quiz);
  };
  useEffect(() => {
    loadQuiz();
  }, [id]);

  if (!quiz || !quizResult) {
    return <Nav highlightedSection="none" content={<CircularProgress />} />;
  }

  return (
    <Nav
      highlightedSection="none"
      content={
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Fråga</TableCell>
                <TableCell>Patientens svar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(quizResult.answers)
                .map((key) => ({
                  questionId: key,
                  ...quizResult.answers[key],
                }))
                .sort((a, b) => Number(a.questionId) - Number(b.questionId))
                .map((answer, index) => (
                  <TableRow key={index}>
                    <TableCell>{answer.questionId}</TableCell>
                    <TableCell>{answer.question}</TableCell>
                    <TableCell>
                      {formatAnswer(answer.questionId, answer)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    />
  );
};

export default ViewQuizResult;
