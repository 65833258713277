import React, {useContext, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import AssessmentIcon from "@mui/icons-material/Assessment";
import HomeIcon from "@mui/icons-material/Home";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import RateReviewIcon from "@mui/icons-material/RateReview";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {Logo} from "../Svg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GroupIcon from "@mui/icons-material/Group";
import {Badge, Button, Divider, ListItemButton} from "@mui/material";
import ProfileSearch from "./ProfileSearch";
import {useClaimsMust} from "../hooks/useClaims";
import {InfoOutlined, Settings} from "@mui/icons-material";
import InfoModal from "./InfoModal";
import {Link} from "react-router-dom";
import {TasksContext} from "../contexts/TasksContext";
import GradingIcon from "@mui/icons-material/Grading";
import {FilesContext} from "../contexts/FilesContext";
import UserAvatar from "./UserAvatar";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    maxWidth: "1400px",
    padding: theme.spacing(3),
  },
  contentContainer: {
    flexGrow: 1,
    background: "#fafafa",
    minHeight: "100vh",
  },
  logo: {
    fill: "#FFF",
    maxWidth: "100px",
    minWidth: "100px",
  },
  toolbarButtons: {
    marginLeft: "auto",
  },
  toolbarCenter: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  claims: {
    float: "left",
    padding: "5px",
    borderRight: "1px solid rgba(255,255,255,0.3)",
    paddingRight: "10px",
    marginRight: "10px",
    "& svg, & img": {
      float: "left",
      "margin-right": "5px",
      "margin-top": "-5px",
    },
    "& span": {
      display: "block",
      float: "left",
      paddingTop: "0px",
    },
  },
}));

type HighlightedSection =
  | "none"
  | "home"
  | "tasks"
  | "bookings"
  | "my-profiles"
  | "follow-up-queue"
  | "files"
  | "reports"
  | "ratings"
  | "profiles"
  | "settings";

interface Props {
  content: React.ReactNode | React.ReactNode[];
  highlightedSection: HighlightedSection;
  disableDrawer?: boolean;
  disableBarItems?: boolean;
}

const Nav: React.VFC<Props> = ({
  content,
  highlightedSection,
  disableDrawer,
  disableBarItems,
}) => {
  const classes = useStyles();
  const claims = useClaimsMust();
  const {tasks} = useContext(TasksContext);
  const {files} = useContext(FilesContext);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const numTasks = tasks === null ? 0 : tasks.length;
  const numNewFiles = files === null ? 0 : files.length;
  return (
    <div className={classes.root}>
      {infoModalOpen && <InfoModal onClose={() => setInfoModalOpen(false)} />}
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Logo
            className={classes.logo}
            style={{cursor: "pointer"}}
            onClick={() => (window.location.href = "/")}
          />
          {!disableBarItems && (
            <>
              <div className={classes.toolbarCenter}>
                <ProfileSearch />
              </div>
              <div className={classes.toolbarButtons}>
                <div className={classes.claims}>
                  <UserAvatar
                    size="35px"
                    profilePicture={claims.profilePicture}
                  />
                  <span>
                    {claims.givenName} {claims.surname}
                  </span>
                </div>
                <Button
                  href={`${
                    process.env.REACT_APP_API_URL
                  }/auth/logout?redirectURL=${encodeURIComponent(
                    window.location.protocol + "//" + window.location.host
                  )}`}
                  startIcon={<ExitToAppIcon />}
                  color="inherit">
                  Logga ut
                </Button>
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
      {!disableDrawer && (
        <Drawer
          className={classes.drawer}
          variant="permanent"
          anchor="left"
          classes={{
            paper: classes.drawerPaper,
          }}>
          <Toolbar />
          <div className={classes.drawerContainer}>
            <List>
              <ListItemButton
                selected={highlightedSection === "home"}
                component={Link}
                to="/">
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={"Startsida"} />
              </ListItemButton>
              <ListItemButton
                selected={highlightedSection === "tasks"}
                component={Link}
                to="/tasks">
                {numTasks > 0 && (
                  <ListItemIcon>
                    <Badge badgeContent={numTasks} color="primary">
                      <GradingIcon />
                    </Badge>
                  </ListItemIcon>
                )}
                {numTasks === 0 && (
                  <ListItemIcon>
                    <GradingIcon />
                  </ListItemIcon>
                )}
                <ListItemText primary={"Ärenden"} />
              </ListItemButton>
              <ListItemButton
                selected={highlightedSection === "bookings"}
                component={Link}
                to="/bookings">
                <ListItemIcon>
                  <AccessTimeIcon />
                </ListItemIcon>
                <ListItemText primary={"Möten"} />
              </ListItemButton>
              <ListItemButton
                selected={highlightedSection === "my-profiles"}
                component={Link}
                to="/my-profiles">
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary={"Mina patienter"} />
              </ListItemButton>
              {claims.role === "admin" && (
                <ListItemButton
                  selected={highlightedSection === "files"}
                  component={Link}
                  to="/files">
                  {numNewFiles > 0 && (
                    <ListItemIcon>
                      <Badge badgeContent={numNewFiles} color="primary">
                        <FileCopyIcon />
                      </Badge>
                    </ListItemIcon>
                  )}
                  {numNewFiles === 0 && (
                    <ListItemIcon>
                      <FileCopyIcon />
                    </ListItemIcon>
                  )}
                  <ListItemText primary={"Filer"} />
                </ListItemButton>
              )}
              <Divider />
              {claims.role === "admin" && (
                <ListItemButton
                  selected={highlightedSection === "follow-up-queue"}
                  component={Link}
                  to="/follow-up-queue">
                  <ListItemIcon>
                    <CalendarMonthIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Uppföljningar"} />
                </ListItemButton>
              )}
              {claims.role === "admin" && (
                <ListItemButton
                  selected={highlightedSection === "profiles"}
                  component={Link}
                  to="/profiles">
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Användare"} />
                </ListItemButton>
              )}
              {claims.role === "admin" && (
                <ListItemButton
                  selected={highlightedSection === "reports"}
                  component={Link}
                  to="/reports">
                  <ListItemIcon>
                    <AssessmentIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Rapporter"} />
                </ListItemButton>
              )}
              <ListItemButton
                selected={highlightedSection === "ratings"}
                component={Link}
                to="/ratings">
                <ListItemIcon>
                  <RateReviewIcon />
                </ListItemIcon>
                <ListItemText primary={"Recensioner"} />
              </ListItemButton>
              <Divider />
              <ListItemButton onClick={() => setInfoModalOpen(true)}>
                <ListItemIcon>
                  <InfoOutlined />
                </ListItemIcon>
                <ListItemText primary={"Info & guider"} />
              </ListItemButton>
              <ListItemButton
                component={"a"}
                href="https://webdoc.atlan.se"
                target="_blank">
                <ListItemIcon>
                  <OpenInNewIcon />
                </ListItemIcon>
                <ListItemText primary={"Öppna Webdoc"} />
              </ListItemButton>
              {claims.role === "admin" && (
                <ListItemButton
                  component={Link}
                  selected={highlightedSection === "settings"}
                  to="/settings">
                  <ListItemIcon>
                    <Settings />
                  </ListItemIcon>
                  <ListItemText primary={"Inställningar"} />
                </ListItemButton>
              )}
            </List>
          </div>
        </Drawer>
      )}
      <div className={classes.contentContainer}>
        <main className={classes.content}>
          <Toolbar />
          {content}
        </main>
      </div>
    </div>
  );
};

export default Nav;
