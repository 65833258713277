import {Button} from "@mui/material";
import {useState} from "react";
import {useParams} from "react-router-dom";
import PatientRecordModal from "../components/PatientRecordModal";
import Nav from "../components/Nav";

interface Params {
  personalNumber: string;
}
const AddJournalRecord = () => {
  const {personalNumber} = useParams<Params>();
  const [journalRecordModalOpen, setJournalRecordModalOpen] = useState(true);
  return (
    <Nav
      highlightedSection="none"
      content={
        <>
          {journalRecordModalOpen && (
            <PatientRecordModal
              personalNumber={personalNumber}
              onClose={() => setJournalRecordModalOpen(false)}
            />
          )}
          {!journalRecordModalOpen && (
            <>
              <Button
                href={`/profiles/${personalNumber}`}
                color="primary"
                variant="contained"
                fullWidth>
                Tillbaka till patientens sida
              </Button>
            </>
          )}
        </>
      }
    />
  );
};

export default AddJournalRecord;
