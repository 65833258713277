import Rollbar from "rollbar";
import { Logger } from "../types";

const rollbar =
  process.env.REACT_APP_ROLLBAR_ENV === "development"
    ? console
    : new Rollbar({
        accessToken: "53723a91e883401481b861656ad5ad0d",
        environment: "production",
        captureUncaught: true,
        captureUnhandledRejections: true,
      });

const useLogger = (): Logger => {
  return rollbar;
};

export default useLogger;
