import React, {useCallback, useEffect, useState} from "react";
import {
  Booking,
  MapPoint,
  PointOfInterest,
  LabAnalysis,
  LabAnalysisConfiguration,
  CreateReferralRequest,
  CreateReferralResponse,
  Profile,
} from "../types";
import {
  GetMyProfile,
  GetPointsOfInterest,
  GetProfileLocation,
  GetReferralLabsAnalyses,
  PostMessage,
  PostReferrals,
  PutBookingHasHandledBloodSampleReferral,
} from "../api";
import {addDaysToDate, getMapDistance} from "../util";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Input,
  List,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import useLogger from "../hooks/useLogger";
import {Divider} from "./Divider";
import {
  Check,
  ChevronRight,
  ClearOutlined,
  Delete,
  Search,
  Star,
} from "@mui/icons-material";
import {L10nDate} from "./Date";
import analysesConfigurations from "../resources/analyses-ui-configuration.json";
const messageBodyTemplateDropIn = `Hej {firstName},
  
Du har nu blivit remitterad till {poi_title}
  
Dom har drop-in så du kan besöka dem på följande adress:
{poi_address}
  
Elektronisk remiss är skickad, ta med legitimation vid besöket.`;

const messageBodyTemplateBook = `Hej {firstName},
  
Du har nu blivit remitterad till {poi_title}
 
Du behöver boka en tid för att besöka dem, vilket du kan göra via följande länk: {poi_url}

Elektronisk remiss är skickad, ta med legitimation vid besöket.`;

const nameMap: Record<string, LabAnalysisConfiguration> = {};
const codeMap: Record<string, LabAnalysisConfiguration> = {};

(() => {
  analysesConfigurations.forEach((ana) => {
    ana.names.forEach((name) => {
      nameMap[name] = ana;
    });
    ana.codes.forEach((code) => {
      codeMap[code] = ana;
    });
  });
})();

const getAnalysisConfiguration = (analysis: LabAnalysis) => {
  return (
    codeMap[analysis.code] ||
    nameMap[analysis.name] || {
      names: [analysis.name],
      codes: [analysis.code],
      messageAppend: "",
      referralDuplicationsAfterDays: [],
      highlighted: false,
    }
  );
};

interface CreateReferralsRequestWithState extends CreateReferralRequest {
  hasBeenPosted: boolean;
  response: CreateReferralResponse | null;
  error: any | null;
}

interface ReferralFormProps {
  patientProfile: Profile;
  booking?: Booking;
  onContinue: () => void;
}

interface LabAnalysisWithHighlight extends LabAnalysis {
  isHighlighted: boolean;
}

const ReferralForm: React.VFC<ReferralFormProps> = ({
  patientProfile,
  booking,
  onContinue,
}) => {
  const {personalNumber, givenName} = patientProfile;
  const logger = useLogger();
  const [userProfile, setUserProfile] = useState<Profile | null>(null);
  const [pointsOfInterest, setPointsOfInterest] = useState<
    PointOfInterest[] | null
  >(null);
  const [profileLocation, setProfileLocation] = useState<MapPoint | null>(null);
  const [messageBody, setMessageBody] = useState("");
  const [selectedPointOfInterest, setSelectedPointOfInterest] =
    useState<PointOfInterest | null>(null);
  const [profileLocationLoaded, setProfileLocationLoaded] = useState(false);
  const [labAnalyses, setLabAnalyses] = useState<
    LabAnalysisWithHighlight[] | null
  >(null);
  const [selectedAnalyses, setSelectedAnalyses] = useState<
    LabAnalysisWithHighlight[]
  >([]);
  const [referralRequests, setReferralRequests] = useState<
    CreateReferralsRequestWithState[] | null
  >(null);
  const [showContinueButton, setShowContinueButton] = useState<boolean>(false);
  const [messageIsPosted, setMessageIsPosted] = useState<boolean>(false);
  const [selectedAnalysesConfirmed, setSelectedAnalysesConfirmed] =
    useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  useEffect(() => {
    GetMyProfile().then(setUserProfile).catch(logger.error);
    GetPointsOfInterest()
      .then(setPointsOfInterest)
      .then(() => setProfileLocationLoaded(false))
      .catch(logger.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadProfileLocation = useCallback(async () => {
    if (!pointsOfInterest || profileLocationLoaded) {
      return;
    }
    setProfileLocationLoaded(true);
    try {
      const location = await GetProfileLocation(personalNumber);
      setProfileLocation(location);
      const mutablePointsOfInterest = [...pointsOfInterest];
      mutablePointsOfInterest.sort((pa, pb) => {
        return (
          getMapDistance(pa.location, location) -
          getMapDistance(pb.location, location)
        );
      });
      setPointsOfInterest(mutablePointsOfInterest);
    } catch (error) {}
  }, [pointsOfInterest, personalNumber, profileLocationLoaded]);

  useEffect(() => {
    if (pointsOfInterest && !profileLocationLoaded) {
      loadProfileLocation();
    }
  }, [profileLocationLoaded, pointsOfInterest, loadProfileLocation]);

  useEffect(() => {
    if (
      !selectedAnalysesConfirmed ||
      !selectedPointOfInterest ||
      !userProfile ||
      !userProfile.externalIds["labportalen"]
    ) {
      return;
    }
    const senderUserGuid = userProfile.externalIds["labportalen"];
    let crrs: CreateReferralsRequestWithState[] = [];
    crrs.push({
      bookingId: booking?.id,
      labCode: selectedPointOfInterest.labCode,
      analyses: selectedAnalyses,
      date: new Date().toISOString(),
      comment: "",
      action: selectedPointOfInterest.labReferralAction,
      hasBeenPosted: false,
      response: null,
      error: null,
      patientPersonalNumber: personalNumber,
      senderUserGuid: senderUserGuid,
    });

    selectedAnalyses.forEach((analysis) => {
      const configuration = getAnalysisConfiguration(analysis);
      configuration.referralDuplicationsAfterDays.forEach((numberOfDays) => {
        crrs.push({
          bookingId: booking?.id,
          labCode: selectedPointOfInterest.labCode,
          date: addDaysToDate(new Date(), numberOfDays).toISOString(),
          comment: "",
          action: selectedPointOfInterest.labReferralAction,
          analyses: [analysis],
          hasBeenPosted: false,
          response: null,
          error: null,
          patientPersonalNumber: personalNumber,
          senderUserGuid: senderUserGuid,
        });
      });
    });
    setReferralRequests(crrs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnalysesConfirmed]);

  useEffect(() => {
    if (!selectedPointOfInterest) {
      setLabAnalyses(null);
      setSelectedAnalyses([]);
      return;
    }
    GetReferralLabsAnalyses(selectedPointOfInterest.labCode).then(
      (labAnalyses) => {
        const analyses = labAnalyses.analyses
          .sort((a, b) => {
            const ah = getAnalysisConfiguration(a).highlighted;
            const bh = getAnalysisConfiguration(b).highlighted;
            if (ah === bh) return a.name.localeCompare(b.name);
            if (ah && !bh) return -1;
            return 1;
          })
          .map((la) => ({
            ...la,
            isHighlighted: getAnalysisConfiguration(la).highlighted,
          }));
        setLabAnalyses(analyses);
      }
    );
  }, [selectedPointOfInterest]);

  useEffect(() => {
    if (selectedPointOfInterest) {
      let template = selectedPointOfInterest.dropInVisits
        ? messageBodyTemplateDropIn
        : messageBodyTemplateBook;

      selectedAnalyses.forEach((analysis) => {
        const configuration = getAnalysisConfiguration(analysis);
        if (configuration.messageAppend !== "") {
          template += "\n\n" + configuration.messageAppend;
        }
      });

      setMessageBody(
        template
          .replace("{firstName}", givenName)
          .replace("{poi_address}", selectedPointOfInterest.address)
          .replace("{poi_title}", selectedPointOfInterest.title)
          .replace("{poi_url}", selectedPointOfInterest.url)
      );
    }
  }, [selectedPointOfInterest, selectedAnalyses, givenName]);

  const continueExternally = async () => {
    try {
      if (booking) {
        await PutBookingHasHandledBloodSampleReferral(booking.id);
      }

      onContinue();
    } catch (error) {
      logger.error();
    }
  };

  const postReferralsAndMessage = async () => {
    if (referralRequests == null) {
      return;
    }
    const copy = await Promise.all(
      referralRequests.map(async (rr, i) => {
        if (rr.hasBeenPosted && rr.error == null) {
          return rr;
        }
        try {
          rr.response = await PostReferrals(rr);
        } catch (error: unknown) {
          let message = "Unknown Error";
          if (error instanceof Error) message = error.message;
          rr.error = message;
          logger.error(error);
        }
        rr.hasBeenPosted = true;
        return rr;
      })
    );
    setReferralRequests([...copy]);
    setHasErrors(
      copy.findIndex(
        (p) => p.error || p.response?.errorText || p.response?.warningText
      ) !== -1
    );
    try {
      if (messageIsPosted === false) {
        await PostMessage({
          referenceType: "referral",
          body: messageBody,
          personalNumber: personalNumber,
        });
      }
      setMessageIsPosted(true);
    } catch (error) {
      logger.error(error);
    }
    try {
      if (booking) {
        await PutBookingHasHandledBloodSampleReferral(booking.id);
      }
    } catch (error) {
      logger.error(error);
    }
    setShowContinueButton(true);
  };

  if (!userProfile || !pointsOfInterest || !profileLocationLoaded) {
    return <CircularProgress />;
  }

  if (!userProfile.externalIds["labportalen"]) {
    return (
      <>
        <Alert color="error">
          <AlertTitle>Din profil är inte kopplad till labbportalen.</AlertTitle>
          Kontakta en administratör för att aktivera denna funktionalitet.
        </Alert>
        <Alert color="warning" style={{marginTop: "20px"}}>
          Behöver du skapa en remiss i nuläget kan du skapa den i webdoc (
          <a href="https://webdoc.atlan.se" target="_blank" rel="noreferrer">
            klicka här för att öppna webdoc i ett nytt fönster
          </a>
          ).
        </Alert>
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
          <Button
            style={{marginTop: "20px"}}
            color="primary"
            variant="contained"
            onClick={continueExternally}>
            Fortsätt
          </Button>
        </Box>
      </>
    );
  }

  if (!selectedPointOfInterest) {
    return (
      <POIChooser
        patientProfile={patientProfile}
        pointsOfInterest={pointsOfInterest}
        setSelectedPointOfInterest={setSelectedPointOfInterest}
        isSortedByProfileLocation={profileLocation !== null}
      />
    );
  }

  if (!labAnalyses) {
    return <CircularProgress />;
  }

  if (selectedAnalysesConfirmed === false) {
    return (
      <>
        <Typography variant="h5">
          {selectedPointOfInterest.title}
          {` `}
          <Button href="#" onClick={() => setSelectedPointOfInterest(null)}>
            Ändra
          </Button>
        </Typography>
        <Divider />
        <AnalysesChooser
          selectedAnalyses={selectedAnalyses}
          setSelectedAnalysesConfirmed={setSelectedAnalysesConfirmed}
          setSelectedAnalyses={setSelectedAnalyses}
          labAnalyses={labAnalyses}
        />
      </>
    );
  }

  if (referralRequests === null) {
    return <CircularProgress />;
  }

  return (
    <>
      <Typography variant="h5" style={{marginBottom: "20px"}}>
        Följande remisser kommer skapas
        {messageIsPosted === false && (
          <Button href="#" onClick={() => setSelectedAnalysesConfirmed(false)}>
            Ändra
          </Button>
        )}
      </Typography>
      <ReferralsSummary
        referralsRequests={referralRequests}
        onChange={(rr) => setReferralRequests([...rr])}
      />
      <Typography
        variant="h5"
        style={{marginBottom: "20px", marginTop: "20px"}}>
        Meddelande till patient
      </Typography>
      <TextareaAutosize
        minRows={5}
        disabled={messageIsPosted}
        value={messageBody}
        onChange={(evt) => setMessageBody(evt.target.value)}
        style={{width: "100%"}}
      />
      {messageIsPosted && <Alert color="success">Meddelandet är skickat</Alert>}
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        {showContinueButton === false && (
          <Button
            style={{marginTop: "20px"}}
            color="primary"
            variant="contained"
            disabled={messageBody === ""}
            onClick={postReferralsAndMessage}>
            Skapa remisser &amp; skicka meddelande
          </Button>
        )}
        {showContinueButton === true && (
          <Button
            style={{marginTop: "20px"}}
            color="primary"
            variant="contained"
            onClick={onContinue}>
            Fortsätt
          </Button>
        )}
      </Box>
      {showContinueButton && hasErrors && (
        <div style={{marginTop: "20px"}}>
          <Alert color="warning">
            OBS: Hantera felaktiga remisser innan du fortsätter.
          </Alert>
        </div>
      )}
    </>
  );
};

interface ReferralsSummaryProps {
  referralsRequests: CreateReferralsRequestWithState[];
  onChange: (referralsRequests: CreateReferralsRequestWithState[]) => void;
}

const ReferralsSummary: React.VFC<ReferralsSummaryProps> = ({
  referralsRequests,
  onChange,
}) => {
  const removeReferral = (index: number) => {
    referralsRequests.splice(index, 1);
    onChange(referralsRequests);
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <colgroup>
            <col style={{width: "15%"}} />
            <col style={{width: "15%"}} />
            <col style={{width: "40%"}} />
            <col style={{width: "15%"}} />
            <col style={{width: "15%"}} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell>Labb</TableCell>
              <TableCell style={{maxWidth: "50%"}}>Analyser</TableCell>
              <TableCell>Åtgärd</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {referralsRequests.map((referral, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>
                    <L10nDate
                      dateTime={referral.date}
                      options={{dateStyle: "medium", timeStyle: undefined}}
                    />
                  </TableCell>
                  <TableCell>{referral.labCode}</TableCell>
                  <TableCell>
                    {referral.analyses.map((ana) => ana.name).join(", ")}
                  </TableCell>
                  <TableCell>
                    {referral.action === "send" && <>Skickas till labb</>}
                    {referral.action === "save" && <>Spara till labportalen</>}
                  </TableCell>
                  <TableCell>
                    {referral.hasBeenPosted && (
                      <>
                        {referral.response != null &&
                          referral.response.warningText != null && (
                            <>Varning, se nedan</>
                          )}
                        {referral.response != null &&
                          referral.response.errorText != null && (
                            <>Ett fel inträffade, se nedan</>
                          )}
                        {referral.error != null && (
                          <>Tekniskt fel: {JSON.stringify(referral.error)}</>
                        )}
                        {!referral.error &&
                          !referral.response?.warningText &&
                          !referral.response?.errorText && (
                            <>
                              <Check />
                              Skickad
                            </>
                          )}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      disabled={referralsRequests.length < 2}
                      edge="end"
                      aria-label="delete"
                      onClick={() =>
                        window.confirm(
                          "Är du säker att du vill ta bort denna remiss?"
                        ) && removeReferral(index)
                      }>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
                {referral.response != null &&
                  referral.response.warningText != null && (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Alert color="warning">
                          <pre>{referral.response.warningText}</pre>
                          <Button
                            color="warning"
                            variant="outlined"
                            component={"a"}
                            href="https://webdoc.atlan.se"
                            target="_blank">
                            Öppna Webdoc och redigera remissen
                          </Button>
                        </Alert>
                      </TableCell>
                    </TableRow>
                  )}
                {referral.response != null &&
                  referral.response.errorText != null && (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Alert color="error">
                          <pre>{referral.response.errorText}</pre>
                          <Button
                            color="error"
                            variant="outlined"
                            component={"a"}
                            href="https://webdoc.atlan.se"
                            target="_blank">
                            Öppna Webdoc och skapa remiss manuellt
                          </Button>
                        </Alert>
                      </TableCell>
                    </TableRow>
                  )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

interface POIChooserProps {
  patientProfile: Profile;
  isSortedByProfileLocation: boolean;
  pointsOfInterest: PointOfInterest[];
  setSelectedPointOfInterest: (p: PointOfInterest) => void;
}

const POIChooser: React.VFC<POIChooserProps> = ({
  pointsOfInterest,
  setSelectedPointOfInterest,
  isSortedByProfileLocation,
  patientProfile,
}) => {
  const {givenName, surname} = patientProfile;
  return (
    <>
      <Typography variant="h5">
        Vilket blodprovställe skall {givenName} {surname} remitteras till?
      </Typography>

      <List>
        {pointsOfInterest.map((poi, index) => (
          <div
            key={index}
            style={{
              clear: "both",
              borderBottom: "1px solid #CCC",
              margin: "10px",
            }}>
            <Button
              color="primary"
              variant="contained"
              style={{float: "right"}}
              onClick={() =>
                setSelectedPointOfInterest(poi as PointOfInterest)
              }>
              Välj
            </Button>
            <div>
              <b>{poi.title}</b>
              {isSortedByProfileLocation && index === 0 && (
                <Chip
                  label="Närmast patientens bostad"
                  variant="outlined"
                  style={{marginLeft: "10px"}}
                />
              )}
            </div>
            <p>{poi.address}</p>
          </div>
        ))}
      </List>
    </>
  );
};

interface AnalysesChooserProps {
  setSelectedAnalyses: (l: LabAnalysisWithHighlight[]) => void;
  selectedAnalyses: LabAnalysisWithHighlight[];
  labAnalyses: LabAnalysisWithHighlight[];
  setSelectedAnalysesConfirmed: (v: boolean) => void;
}

const AnalysesChooser: React.VFC<AnalysesChooserProps> = ({
  setSelectedAnalyses,
  selectedAnalyses,
  labAnalyses,
  setSelectedAnalysesConfirmed,
}) => {
  const [analysesSearchTerm, setAnalysesSearchTerm] = useState("");
  const [limit, setLimit] = useState(20);

  const matchesSearchTerm = (a: LabAnalysisWithHighlight) => {
    if (analysesSearchTerm === "") return true;
    return a.name
      .toLocaleLowerCase()
      .includes(analysesSearchTerm.toLowerCase());
  };

  const notInSelectedAnalysesList = (a: LabAnalysisWithHighlight) => {
    return selectedAnalyses.findIndex((b) => b.code === a.code) === -1;
  };

  const addAnalysisSelectedAnalysesList = (
    analysis: LabAnalysisWithHighlight
  ) => {
    const mutableCopy = [...selectedAnalyses];
    mutableCopy.push(analysis);
    setSelectedAnalyses(mutableCopy);
  };

  const removeAnalysisSelectedAnalysesList = (
    analysis: LabAnalysisWithHighlight
  ) => {
    const mutableCopy = [
      ...selectedAnalyses.filter((a) => a.code !== analysis.code),
    ];
    setSelectedAnalyses(mutableCopy);
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="h5" style={{marginBottom: "10px"}}>
          Välj analyser
        </Typography>
        <Input
          type="text"
          fullWidth
          style={{marginBottom: "10px"}}
          startAdornment={<Search />}
          endAdornment={
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => setAnalysesSearchTerm("")}>
              <ClearOutlined />
            </IconButton>
          }
          placeholder="Sök efter analys"
          value={analysesSearchTerm}
          onChange={(e) => setAnalysesSearchTerm(e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h5">
          Valda analyser ({selectedAnalyses.length})
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <div
          style={{
            height: "33vh",
            overflowY: "scroll",
          }}>
          <Table size="small">
            <TableBody>
              {labAnalyses
                .filter(notInSelectedAnalysesList)
                .filter(matchesSearchTerm)
                .filter((v, i) => i < limit)
                .map((a) => (
                  <TableRow
                    style={{cursor: "pointer"}}
                    selected={a.isHighlighted}
                    key={a.code}
                    onClick={() => addAnalysisSelectedAnalysesList(a)}>
                    <TableCell key={a.code}>
                      {a.isHighlighted && (
                        <IconButton edge="start">
                          <Star />
                        </IconButton>
                      )}
                      {a.name}
                    </TableCell>
                    <TableCell>{a.code}</TableCell>
                    <TableCell align="right">
                      <IconButton edge="end">
                        <ChevronRight />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            {limit < labAnalyses.length && analysesSearchTerm === "" && (
              <TableFooter>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    <Button onClick={() => setLimit(limit + 20)}>
                      Visa fler analyser (totalt {labAnalyses.length})
                    </Button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div
          style={{
            height: "30vh",
            overflowY: "scroll",
          }}>
          <Table size="small">
            <TableBody>
              {selectedAnalyses.map((a) => (
                <TableRow
                  style={{cursor: "pointer"}}
                  key={a.code}
                  onClick={() => removeAnalysisSelectedAnalysesList(a)}>
                  <TableCell key={a.code}>{a.name}</TableCell>
                  <TableCell>{a.code}</TableCell>
                  <TableCell align="right">
                    <IconButton edge="end">
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
          <Button
            style={{marginTop: "20px"}}
            color="primary"
            variant="contained"
            onClick={() => setSelectedAnalysesConfirmed(true)}
            disabled={selectedAnalyses.length === 0}>
            Bekräfta valda analyser och fortsätt &raquo;
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReferralForm;
