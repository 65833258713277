import {
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import {Task} from "../types";
import {Link} from "react-router-dom";
import {L10nDate} from "./Date";
import {localizeTaskKind} from "../util";
import TaskModal from "./TaskModal";
import {useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  expiredRow: {
    "& td": {
      backgroundColor: "#ffd6d6",
    },
  },
}));

type TaskListColumn =
  | "date"
  | "kind"
  | "created-by"
  | "assigned-to"
  | "status"
  | "action";

interface TaskListProps {
  onTaskReloadRequired: () => void;
  tasks: Task[];
  hideColumns?: TaskListColumn[];
  sortBy?: string;
  sortAscending?: boolean;
  onSortChanged: (column: string, ascending: boolean) => void;
}
const TaskList: React.VFC<TaskListProps> = ({
  tasks,
  onTaskReloadRequired,
  onSortChanged,
  hideColumns,
  sortBy,
  sortAscending,
}) => {
  const styles = useStyles();
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const isColumnVisible = (column: TaskListColumn) => {
    if (!hideColumns) return true;
    return !hideColumns.includes(column);
  };
  const isExpired = (task: Task): boolean => {
    return (
      task.closed === false &&
      new Date().getTime() - new Date(task.createdAt).getTime() >
        7 * 24 * 60 * 60 * 1000
    );
  };
  return (
    <>
      {selectedTask && (
        <TaskModal
          task={selectedTask}
          onClose={() => {
            setSelectedTask(null);
            onTaskReloadRequired();
          }}
        />
      )}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {isColumnVisible("date") && (
                <>
                  <TableCell>
                    <TableSortLabel
                      onClick={() => onSortChanged("createdat", !sortAscending)}
                      active={sortBy === "createdat"}
                      direction={sortAscending ? "asc" : "desc"}>
                      Datum
                    </TableSortLabel>
                  </TableCell>
                </>
              )}
              {isColumnVisible("kind") && <TableCell>Ärendetyp</TableCell>}
              {isColumnVisible("created-by") && (
                <TableCell>Skapad av</TableCell>
              )}
              {isColumnVisible("assigned-to") && (
                <TableCell>Tilldelad till</TableCell>
              )}
              {isColumnVisible("status") && <TableCell>Status</TableCell>}
              {isColumnVisible("action") && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((task, index) => (
              <TableRow
                key={index}
                className={clsx({[styles.expiredRow]: isExpired(task)})}>
                {isColumnVisible("date") && (
                  <TableCell>
                    <L10nDate
                      dateTime={task.createdAt}
                      options={{dateStyle: "short", timeStyle: "short"}}
                    />
                  </TableCell>
                )}
                {isColumnVisible("kind") && (
                  <TableCell>{localizeTaskKind(task.taskKind)}</TableCell>
                )}
                {isColumnVisible("created-by") && (
                  <TableCell>
                    <Link to={`/profiles/${task.createdBy.personalNumber}`}>
                      {task.createdBy.givenName} {task.createdBy.surname}
                    </Link>
                  </TableCell>
                )}
                {isColumnVisible("assigned-to") && (
                  <TableCell>
                    {task.assignees.map((assignee) => (
                      <Link
                        key={assignee.id}
                        to={`/profiles/${assignee.personalNumber}`}>
                        {assignee.givenName} {assignee.surname}
                      </Link>
                    ))}
                  </TableCell>
                )}
                {isColumnVisible("status") && (
                  <TableCell>
                    {task.closed && <Chip color="default" label="Avslutad" />}
                    {!task.closed && <Chip color="warning" label="Öppen" />}
                  </TableCell>
                )}
                {isColumnVisible("action") && (
                  <TableCell align="right">
                    <Button
                      onClick={() => setSelectedTask(task)}
                      color="primary"
                      variant="contained">
                      Hantera
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TaskList;
