import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Tooltip,
  Box,
} from "@mui/material";
import {useEffect, useState} from "react";
import {GetInvoicingStats, GetInvoiceItems} from "../../api";
import {Divider} from "../../components/Divider";
import {
  InvoicingByPersonAggregate,
  InvoiceItemWithQuantity,
  DateRange,
} from "../../types";
import {formatNumeric} from "../../util";
import Nav from "../../components/Nav";
import DateRangeSelector from "../../components/dates/DateRangeSelector";

const InvoicingReport = () => {
  const [range, setRange] = useState<DateRange>();
  const [invoiceItems, setInvoiceItems] = useState<
    InvoiceItemWithQuantity[] | null
  >(null);

  const nonInvoicedBookings = (agg: InvoicingByPersonAggregate): number => {
    return agg.totals.total - agg.totals.invoiced - agg.totals.cancelled;
  };

  const [stats, setStats] = useState<InvoicingByPersonAggregate[] | null>(null);

  useEffect(() => {
    if (!range) {
      return;
    }
    setStats(null);
    const {start, end} = range;
    GetInvoicingStats({
      from: start.toISOString(),
      to: end.toISOString(),
    }).then(setStats);
    GetInvoiceItems().then(setInvoiceItems);
  }, [range]);

  return (
    <Nav
      highlightedSection="reports"
      content={
        <>
          <Grid container>
            <Grid item sm={6}>
              <Typography variant="h4">Fakturering</Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <DateRangeSelector defaultMode="month" onChange={setRange} />
              </Box>
            </Grid>
          </Grid>
          <Divider />
          {invoiceItems && stats && (
            <>
              <TableContainer component={Paper}>
                <Table size="small" style={{whiteSpace: "nowrap"}}>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell colSpan={4}>Möten</TableCell>
                      <TableCell
                        style={{
                          borderLeft: "1px solid #CCC",
                        }}></TableCell>
                      <TableCell
                        colSpan={invoiceItems.length}
                        style={{
                          borderLeft: "1px solid #CCC",
                        }}>
                        Fakturerade produkt-nummer
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Person</TableCell>
                      <TableCell align="right">
                        <Tooltip title="Antal fakturerade möten">
                          <span># F</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Antal avbokade möten">
                          <span># A</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Antal icke-fakturerade möten">
                          <span># IF</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          borderLeft: "1px solid #EFEFEF",
                          borderRight: "1px solid #CCC",
                        }}>
                        <Tooltip title="Totalt antal möten">
                          <span># M</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          fontWeight: "bold",
                          borderLeft: "1px solid #EFEFEF",
                          borderRight: "1px solid #CCC",
                        }}>
                        SUMMA SEK
                      </TableCell>
                      {invoiceItems.map((invoiceItem, i) => (
                        <TableCell key={i} align="right">
                          <Tooltip title={invoiceItem.title}>
                            <span># {invoiceItem.itemNumber}</span>
                          </Tooltip>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stats.map((stat) => (
                      <TableRow key={stat.email}>
                        <TableCell>
                          {stat.profile && (
                            <span>
                              {stat.profile.givenName} {stat.profile.surname}
                            </span>
                          )}
                          {!stat.profile && stat.email}
                        </TableCell>
                        <TableCell align="right">
                          {stat.totals.invoiced}
                        </TableCell>
                        <TableCell align="right">
                          {stat.totals.cancelled}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            backgroundColor:
                              nonInvoicedBookings(stat) > 0
                                ? "#ffd5d5"
                                : "transparent",
                          }}>
                          {nonInvoicedBookings(stat)}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            fontWeight: "bold",
                            borderLeft: "1px solid #EFEFEF",
                            borderRight: "1px solid #CCC",
                          }}>
                          {stat.totals.total}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            fontWeight: "bold",
                            borderLeft: "1px solid #EFEFEF",
                            borderRight: "1px solid #CCC",
                          }}>
                          {formatNumeric(stat.totals.amount || 0)} kr
                        </TableCell>
                        {invoiceItems.map((invoiceItem, i) => (
                          <TableCell key={i} align="right">
                            {formatNumeric(
                              stat.totals["product" + invoiceItem.itemNumber] ||
                                0
                            )}{" "}
                            kr
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      }
    />
  );
};

export default InvoicingReport;
