import PersonIcon from "@mui/icons-material/Person";
import {ProfilePicture} from "../types";

interface UserAvatarProps {
  profilePicture?: ProfilePicture | null;
  size?: string;
}

const UserAvatar: React.VFC<UserAvatarProps> = ({
  profilePicture,
  size = "40px",
}) => {
  return (
    <>
      {!profilePicture && (
        <PersonIcon fontSize={"large"} style={{width: size, height: size}} />
      )}
      {profilePicture && (
        <img
          src={profilePicture.small}
          alt={"Profile"}
          style={{borderRadius: "50%", width: size, height: size}}
        />
      )}
    </>
  );
};

export default UserAvatar;
