import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  CircularProgress,
  TableContainer,
  Button,
  ButtonGroup,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Divider,
} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {
  DeleteFollowUpQueueItem,
  GetBookingPageConfigurations,
  GetFollowUpQueue,
  GetFollowUpQueueStats,
} from "../api";
import {
  FollowUpQueueItem,
  Profile,
  FollowUpQueueWeeklyCount,
  SlotKind,
  BookingPageConfiguration,
} from "../types";
import {getFutureDate, localizeBookingReason} from "../util";
import Nav from "../components/Nav";

const showAll = "all";

const FollowUpQueue = () => {
  const [until] = useState<Date>(getFutureDate(30 * 3));
  const [followUpQueueItems, setFollowUpQueueItems] = useState<
    FollowUpQueueItem[] | null
  >(null);

  const [followUpQueueStats, setFollowUpQueueStats] = useState<
    FollowUpQueueWeeklyCount[] | null
  >(null);

  const [selectedStaff, setSelectedStaff] = useState<string>(showAll);
  const [bookingPageConfigurations, setBookingPageConfigurations] = useState<
    BookingPageConfiguration[] | null
  >();

  const [staff, setStaff] = useState<Profile[] | null>(null);

  const reload = useCallback(
    async () => {
      const query: any = {
        to: until.toISOString(),
      };
      if (selectedStaff !== showAll) {
        query.bookedPersonProfileId = selectedStaff;
      }
      const fuqi = await GetFollowUpQueue(query);
      setFollowUpQueueItems(fuqi);
      if (staff === null) {
        setStaff(
          fuqi
            .map((f) => f.personToBook)
            .filter((b) => b)
            .filter(
              (v, i, self) => i === self.findIndex((o) => o?.id === v?.id)
            ) as Profile[]
        );
      }

      GetFollowUpQueueStats(query).then(setFollowUpQueueStats);
      GetBookingPageConfigurations().then(setBookingPageConfigurations);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedStaff, until]
  );

  useEffect(() => {
    reload();
  }, [reload]);

  if (!followUpQueueItems || !bookingPageConfigurations) {
    return (
      <Nav
        highlightedSection="follow-up-queue"
        content={<CircularProgress />}
      />
    );
  }
  return (
    <Nav
      highlightedSection="follow-up-queue"
      content={
        <>
          {staff && (
            <div style={{marginBottom: "10px"}}>
              <Grid container>
                <Grid item xs={6}>
                  <FormControl variant="outlined">
                    <Select
                      size="small"
                      value={selectedStaff}
                      onChange={(evt) => setSelectedStaff(evt.target.value)}>
                      <MenuItem value={showAll}>All personal</MenuItem>
                      {staff.map((s) => (
                        <MenuItem key={s.id} value={s.id}>
                          {s.givenName} {s.surname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          )}
          {followUpQueueItems.length === 0 && (
            <p>
              Inga patienter väntar på att boka uppföljning den kommande
              perioden
            </p>
          )}
          <TableContainer component={Paper}>
            {followUpQueueStats && (
              <WeekTable followUpQueueStats={followUpQueueStats} />
            )}
          </TableContainer>
          <Divider style={{margin: "10px 0px"}} />
          <TableContainer component={Paper}>
            {followUpQueueItems.length > 0 && (
              <FollowUpQueueItemsTable
                bookingPageConfigurations={bookingPageConfigurations}
                followUpQueueItems={followUpQueueItems}
                onUpdated={reload}
              />
            )}
          </TableContainer>
        </>
      }
    />
  );
};

interface WeekTableProps {
  followUpQueueStats: FollowUpQueueWeeklyCount[];
}

const makeUniqueFilter = (equality: (a: any, b: any) => boolean) => {
  return (value: any, index: number, self: any[]) => {
    return self.findIndex((v) => equality(v, value)) === index;
  };
};

const unique = (value: any, index: number, self: any[]) => {
  return self.indexOf(value) === index;
};

const WeekTable: React.VFC<WeekTableProps> = ({followUpQueueStats}) => {
  const persons = followUpQueueStats
    .map((item) => item.profile)
    .filter(Boolean)
    .filter(makeUniqueFilter((a, b) => a.id === b.id)) as Profile[];

  const weeks = followUpQueueStats.map((item) => item.week).filter(unique);

  const groupedByPersonAndWeek: Record<
    string,
    Record<number, number>
  > = (() => {
    let res: Record<string, Record<number, number>> = {};
    followUpQueueStats.forEach((f) => {
      if (!f.profile) return;
      if (!res[f.profile.id]) {
        res[f.profile.id] = {};
      }
      if (!res[f.profile.id][f.week]) {
        res[f.profile.id][f.week] = 0;
      }
      res[f.profile.id][f.week] += f.followUps;
    });
    return res;
  })();

  return (
    <Table size="small" color="">
      <TableHead style={{backgroundColor: "#CCC"}}>
        <TableRow>
          <TableCell
            style={{padding: "0px", borderColor: "#C1C1C1"}}></TableCell>
          <TableCell
            style={{padding: "0px", borderColor: "#C1C1C1"}}
            colSpan={weeks.length}
            align="center">
            Veckonummer
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell></TableCell>
          {weeks.map((wn) => (
            <TableCell key={wn}>{wn}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {persons.map((person) => (
          <TableRow key={person.id}>
            <TableCell>
              {person.givenName} {person.surname}
            </TableCell>
            {weeks.map((wn) => (
              <TableCell key={person.id + wn}>
                {groupedByPersonAndWeek[person.id][wn]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

interface FollowUpQueueItemsTableProps {
  followUpQueueItems: FollowUpQueueItem[];
  bookingPageConfigurations: BookingPageConfiguration[];
  onUpdated: () => void;
}

const FollowUpQueueItemsTable: React.VFC<FollowUpQueueItemsTableProps> = ({
  followUpQueueItems,
  bookingPageConfigurations,
  onUpdated,
}) => {
  const [hiddenItems, setHiddenItems] = useState<string[]>([]);
  const bookingLink = (followUpQueueItem: FollowUpQueueItem) => {
    const {patient, reason} = followUpQueueItem;
    const slotKind = bookingPageConfigurations.find(
      (bpc) => bpc.id === followUpQueueItem.bookingPageId
    )?.kind;
    if (!slotKind) {
      window.alert("Booking page configuration is missing");
      return window.location.href;
    }
    const bookProfileId = followUpQueueItem.personToBook
      ? `&profileId=${followUpQueueItem.personToBook.id}`
      : "";
    return `${process.env.REACT_APP_APP_URL}/calendar/${slotKind}?reason=${reason}&showProfile=true&patientProfileId=${patient.id}${bookProfileId}`;
  };
  const hideItem = (followUpQueueItem: FollowUpQueueItem) => {
    const newHiddenItems = [...hiddenItems, followUpQueueItem.id];
    setHiddenItems(newHiddenItems);
  };

  const isHidden = (followUpQueueItem: FollowUpQueueItem): boolean => {
    return hiddenItems.includes(followUpQueueItem.id);
  };

  const removeFollowUpQueueItem = async (
    followUpQueueItem: FollowUpQueueItem
  ) => {
    if (!window.confirm("Är du säker?")) return;
    try {
      await DeleteFollowUpQueueItem(followUpQueueItem.id);
      onUpdated();
    } catch (error) {
      alert("Något gick fel, försök igen");
    }
  };

  return (
    <Table>
      <colgroup>
        <col style={{width: "10%"}} />
        <col style={{width: "20%"}} />
        <col style={{width: "10%"}} />
        <col style={{width: "10%"}} />
        <col style={{width: "20%"}} />
        <col style={{width: "30%"}} />
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell>Önskat datum</TableCell>
          <TableCell>Patient</TableCell>
          <TableCell>Boka person</TableCell>
          <TableCell>Besökstyp</TableCell>
          <TableCell>Övrigt</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {followUpQueueItems.map((followUpQueueItem, index) => (
          <TableRow
            key={index}
            style={
              isHidden(followUpQueueItem) ? {backgroundColor: "#CCC"} : {}
            }>
            <TableCell>
              {new Date(followUpQueueItem.after).toLocaleDateString()}
            </TableCell>
            <TableCell>
              {followUpQueueItem.patient && (
                <>
                  <b>
                    {followUpQueueItem.patient.givenName}{" "}
                    {followUpQueueItem.patient.surname}
                  </b>
                  <br />
                  {followUpQueueItem.patient.telephoneNumber} <br />
                  {followUpQueueItem.patient.email} <br />
                  <a
                    href={`/profiles/${followUpQueueItem.patient.personalNumber}`}>
                    {followUpQueueItem.patient.personalNumber}
                  </a>{" "}
                  <br />
                </>
              )}
            </TableCell>
            <TableCell>
              {followUpQueueItem.personToBook && (
                <>
                  {followUpQueueItem.personToBook.givenName}{" "}
                  {followUpQueueItem.personToBook.surname},{" "}
                  {followUpQueueItem.personToBook.title}
                </>
              )}
            </TableCell>
            <TableCell>
              {bookingPageConfigurations.find(
                (bpc) => bpc.id === followUpQueueItem.bookingPageId
              )?.friendlyName || "Bokningssida saknas"}
              {` `}
              {localizeBookingReason(followUpQueueItem.reason)}
            </TableCell>
            <TableCell>{followUpQueueItem.notes}</TableCell>
            <TableCell align="right">
              {!isHidden(followUpQueueItem) && (
                <ButtonGroup>
                  <Button
                    onClick={() => {
                      hideItem(followUpQueueItem);
                      window.open(bookingLink(followUpQueueItem), "_blank");
                    }}
                    variant="contained"
                    color="primary">
                    Boka uppföljning
                  </Button>
                  <Button
                    onClick={() => removeFollowUpQueueItem(followUpQueueItem)}
                    variant="contained"
                    color="secondary">
                    Ta bort
                  </Button>
                </ButtonGroup>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default FollowUpQueue;
