import {
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Nav from "../../components/Nav";
import {YearSelector} from "../../components/dates/YearSelector";
import {AggregateUnit, TasksStats} from "../../types";
import React, {useEffect, useRef, useState} from "react";
import {GetTasksStats} from "../../api";
import {Chart} from "chart.js";
import {MonthNames, getWeekNumber, minutes} from "../../util";

const TasksReport = () => {
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [taskStats, setTasksStats] = useState<TasksStats[] | null>(null);
  const [aggregateUnit, setAggregateUnit] = useState<AggregateUnit>("day");

  useEffect(() => {
    const from = `${year}-01-01T00:00:00Z`;
    const to = `${year + 1}-01-01T00:00:00Z`;
    setTasksStats(null);
    GetTasksStats({from, to, aggregateUnit}).then(setTasksStats);
  }, [year, aggregateUnit]);
  return (
    <Nav
      highlightedSection="reports"
      content={
        <>
          <Grid container style={{marginBottom: "10px"}}>
            {" "}
            <Grid item sm={8}>
              <Typography variant="h4">Ärenden</Typography>
            </Grid>
            <Grid item xs={4} justifyContent="flex-end">
              <div style={{float: "right"}}>
                <YearSelector
                  onChange={setYear}
                  yearMin={2022}
                  yearMax={new Date().getFullYear()}
                  value={year}
                />
              </div>
              <div style={{float: "right"}}>
                <Select
                  size="small"
                  value={aggregateUnit}
                  onChange={(evt) =>
                    setAggregateUnit(evt.target.value as AggregateUnit)
                  }>
                  <MenuItem value={"day"}>Gruppera per dag</MenuItem>
                  <MenuItem value={"week"}>Gruppera per vecka</MenuItem>
                  <MenuItem value={"month"}>Gruppera per månad</MenuItem>
                </Select>
              </div>
            </Grid>
          </Grid>
          <Divider />
          {taskStats === null && <CircularProgress />}
          {taskStats !== null && (
            <>
              <TaskBarChart
                taskStats={taskStats}
                aggregateUnit={aggregateUnit}
              />
            </>
          )}
        </>
      }
    />
  );
};

interface TaskBarChartProps {
  taskStats: TasksStats[];
  aggregateUnit: AggregateUnit;
}

const unique = (value: string, index: number, array: string[]) => {
  return array.indexOf(value) === index;
};

const TaskBarChart: React.VFC<TaskBarChartProps> = ({
  taskStats,
  aggregateUnit,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    const ctx = canvasRef.current?.getContext("2d");
    if (!ctx) {
      return;
    }
    const labels = taskStats.map((ts) => ts.date).filter(unique);
    const data = labels.map((l) => {
      const perscriptionOpen = taskStats.find(
        (ts) => ts.date === l && ts.taskKind === "prescription" && !ts.closed
      );
      const perscriptionClosed = taskStats.find(
        (ts) => ts.date === l && ts.taskKind === "prescription" && ts.closed
      );
      const contactOpen = taskStats.find(
        (ts) => ts.date === l && ts.taskKind === "contact" && !ts.closed
      );
      const contactClosed = taskStats.find(
        (ts) => ts.date === l && ts.taskKind === "contact" && ts.closed
      );
      const totalLeadTimeMinutes =
        (contactClosed?.leadTimeMinutes || 0) +
        (perscriptionClosed?.leadTimeMinutes || 0);
      const totalClosed =
        (contactClosed?.count || 0) + (perscriptionClosed?.count || 0);
      const averageLeadTimeMinutes =
        totalClosed === 0 ? 0 : totalLeadTimeMinutes / totalClosed;
      return {
        perscription: {
          open: perscriptionOpen?.count || 0,
          closeTimeMinutes: perscriptionClosed?.leadTimeMinutes || 0,
          closed: perscriptionClosed?.count || 0,
        },
        contact: {
          open: contactOpen?.count || 0,
          closeTimeMinutes: contactClosed?.leadTimeMinutes || 0,
          closed: contactClosed?.count || 0,
        },
        averageLeadTimeMinutes: minutes(averageLeadTimeMinutes),
      };
    });
    const chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels.map((l) => {
          if (aggregateUnit === "month")
            return MonthNames[new Date(l).getMonth()];
          if (aggregateUnit === "week")
            return "Vecka " + getWeekNumber(new Date(l));
          return l.substring(0, 10);
        }),
        datasets: [
          {
            label: "Ledtid för ärenden (dagar):",
            backgroundColor: "red",
            data: data.map((ts) => ts.averageLeadTimeMinutes.days()),
            yAxisID: "y1",
            type: "line",
            borderColor: "blue",
            borderDash: [1, 1],
            pointBackgroundColor: "red",
            pointRadius: 3,
          },
          {
            label: "Kontakt - stängda",
            backgroundColor: "#727ff7",
            data: data.map((ts) => ts.contact.closed),
            yAxisID: "y",
          },
          {
            label: "Kontakt - öppna",
            backgroundColor: "#cf3062",
            data: data.map((ts) => ts.contact.open),
            yAxisID: "y",
          },
          {
            label: "Recept - stängda",
            backgroundColor: "#5d68cf",
            data: data.map((ts) => ts.perscription.closed),
            yAxisID: "y",
          },
          {
            label: "Recept - öppna",
            backgroundColor: "#ff3b79",
            data: data.map((ts) => ts.perscription.open),
            yAxisID: "y",
          },
        ],
      },

      options: {
        scales: {
          x: {
            stacked: true,
            ticks: {
              maxTicksLimit: 10,
              maxRotation: 0,
            },
          },
          y: {
            stacked: true,
          },
          y1: {
            type: "linear",
            display: true,
            position: "right",
            grid: {
              drawOnChartArea: false,
            },
          },
        },
        responsive: false,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: true,
          },
        },
      },
    });
    return () => {
      chart.destroy();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <canvas ref={canvasRef} style={{width: "100%", height: "600px"}} />;
};

export default TasksReport;
