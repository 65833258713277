import {
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {useEffect, useState} from "react";
import {GetMyFollowedProfiles} from "../api";
import {Profile} from "../types";
import Nav from "../components/Nav";
import {Link} from "react-router-dom";

const MyProfiles = () => {
  const [profiles, setProfiles] = useState<Profile[] | null>(null);

  useEffect(() => {
    GetMyFollowedProfiles().then(setProfiles);
  }, []);

  if (!profiles) {
    return (
      <Nav highlightedSection="my-profiles" content={<CircularProgress />} />
    );
  }

  return (
    <Nav
      highlightedSection="my-profiles"
      content={
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Efternamn</TableCell>
                <TableCell>Förnamn</TableCell>
                <TableCell>Personnummer</TableCell>
                <TableCell>E-postaddress</TableCell>
                <TableCell>Telefonnummer</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {profiles.map((profile, index) => (
                <TableRow key={index}>
                  <TableCell>{profile.surname}</TableCell>
                  <TableCell>{profile.givenName}</TableCell>
                  <TableCell>{profile.personalNumber}</TableCell>
                  <TableCell>{profile.email}</TableCell>
                  <TableCell>{profile.telephoneNumber}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      component={Link}
                      color="primary"
                      to={"/profiles/" + profile.personalNumber}>
                      Visa patientinfo
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    />
  );
};

export default MyProfiles;
