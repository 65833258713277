import {createContext} from "react";
import {FileWithProfile} from "../types";

export interface FilesContextData {
  files: FileWithProfile[] | null;
  setFiles: (tasks: FileWithProfile[]) => void;
}

export const FilesContext = createContext<FilesContextData>({
  files: null,
  setFiles: () => {},
});
