import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import {
  Button,
  Card,
  Grid,
  Table,
  TableCell,
  TableBody,
  ButtonGroup,
  CircularProgress,
  Tabs,
  Tab,
  Box,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Typography,
  Divider,
  Badge,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Alert,
  Tooltip,
} from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";
import {
  Edit as EditIcon,
  Call as CallIcon,
  NoteAdd as NoteAddIcon,
  Receipt as ReceiptIcon,
  AccessTime as AccessTimeIcon,
} from "@mui/icons-material";
import React, {useEffect, useState, useCallback} from "react";
import {
  GetBookings,
  GetProfile,
  GetFiles,
  GetQuizResults,
  GetQuestionnaireResponses,
  GetFollowUpQueue,
  GetInvoices,
  GetNotes,
  GetTasks,
  PostProfilesBan,
  GetSamples,
  GetBookingPageConfigurations,
} from "../api";
import {Link, useParams} from "react-router-dom";
import {
  Booking,
  Profile,
  FileWithProfile,
  QuizResult,
  QuestionnaireResponse,
  QuestionnaireQuestion,
  FollowUpQueueItem,
  Note,
  Invoice,
  Task,
  TaskQuery,
  Sample,
  BookingPageConfiguration,
} from "../types";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import ProfileMap from "../components/ProfileMap";
import {
  ageFromPersonalNumber,
  genderFromPersonalNumber,
  getQuizTypeName,
  getTypeName,
  isFuture,
  isPast,
} from "../util";
import CloseIcon from "@mui/icons-material/Close";
import BookingList from "../components/BookingList";
import IdentificationModal from "../components/IdentificationModal";
import MessageFormAndListCombo from "../components/MessageFormAndListCombo";
import FollowUpModal from "../components/FollowUpModal";
import {EditProfileForm} from "../components/EditProfileForm";
import {BoxModal} from "../components/BoxModal";
import {BankIDSmall} from "../Svg";
import Notes from "../components/Notes";
import PersonIcon from "@mui/icons-material/Person";
import ProfilePictureForm from "../components/ProfilePictureForm";
import BookingLinksModal from "../components/BookingLinksModal";
import InvoiceProfileModal from "../components/InvoiceProfileModal";
import Nav from "../components/Nav";
import TabPanel from "../components/TabPanel";
import TaskList from "../components/TaskList";
import {useClaimsMust} from "../hooks/useClaims";
import {L10nDate} from "../components/Date";
import ProfileActionButtons from "../components/ProfileActionButtons";

interface Params {
  personalNumber: string;
}

const ViewProfile = () => {
  const {personalNumber} = useParams<Params>();
  const [selectedTab, setSelectedTab] = useState(0);
  const [quizResults, setQuizResults] = useState<QuizResult[] | null>(null);
  const [tasks, setTasks] = useState<Task[] | null>(null);
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [profilePictureModalVisible, setProfilePictureModalVisible] =
    useState<boolean>(false);

  const [notes, setNotes] = useState<Note[] | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [bookings, setBookings] = useState<Booking[] | null>(null);
  const [unpaidInvoiceCount, setUnpaidInvoiceCount] = useState(0);
  const [openTasksCount, setOpenTasksCount] = useState(0);
  const [samplesCount, setSamplesCount] = useState(0);
  const [invoices, setInvoices] = useState<Invoice[] | null>(null);
  const [bookingPageConfigurations, setBookingPageConfigurations] = useState<
    BookingPageConfiguration[] | null
  >(null);
  const [followUpQueueItems, setFollowUpQueueItems] = useState<
    FollowUpQueueItem[] | null
  >(null);
  const [questionnaireResponses, setQuestionnareResponses] = useState<
    QuestionnaireResponse[] | null
  >(null);
  const [files, setFiles] = useState<FileWithProfile[] | null>(null);
  const loadFollowUpQueueItems = useCallback(async () => {
    const followUpQueueItems = await GetFollowUpQueue({
      personalNumber,
    });
    setFollowUpQueueItems(followUpQueueItems);
  }, [personalNumber]);

  const loadBookings = useCallback(async () => {
    const bookings = await GetBookings({personalNumber});
    setBookings(bookings);
  }, [personalNumber]);

  const loadProfile = useCallback(async () => {
    const profile = await GetProfile(personalNumber);
    setProfile(profile);
  }, [personalNumber]);

  const loadInvoices = useCallback(async () => {
    const invoices = await GetInvoices({personalNumber});
    setInvoices(invoices);
    setUnpaidInvoiceCount(
      invoices.filter(
        (invoice) =>
          invoice.paymentStatus !== "Paid" &&
          invoice.paymentStatus !== "Credited" &&
          invoice.paymentStatus !== "Ended"
      ).length
    );
  }, [personalNumber]);

  const loadNotes = useCallback(() => {
    GetNotes({personalNumber}).then(setNotes);
  }, [personalNumber]);

  useEffect(() => {
    loadNotes();
  }, [personalNumber, loadNotes]);

  const [taskQuery, setTaskQuery] = useState<TaskQuery>({
    createdBy: profile?.id,
    sortBy: "createdat",
    sortAscending: true,
    limit: 999,
  });

  const loadTasks = useCallback(async () => {
    if (!profile) {
      return;
    }
    const tasks = await GetTasks(taskQuery);
    setOpenTasksCount(tasks.filter((t) => t.closed === false).length);
    setTasks(tasks);
  }, [taskQuery]);

  const onSortChanged = (column: string, sortAscending: boolean) => {
    setTaskQuery({
      ...taskQuery,
      sortBy: column,
      sortAscending,
    });
    loadTasks();
  };

  useEffect(() => {
    loadTasks();
  }, [loadTasks]);

  useEffect(() => {
    GetSamples({patientPersonalNumber: personalNumber}).then((samples) => {
      setSamplesCount(samples.length);
    });
  }, []);

  useEffect(() => {
    if (profile) {
      setTaskQuery({
        ...taskQuery,
        createdBy: profile.id,
      });
    }
  }, [profile]);

  useEffect(() => {
    loadFollowUpQueueItems();
    loadBookings();
    loadProfile();
    loadInvoices();
    GetFiles({personalNumber}).then(setFiles);
    GetQuizResults({personalNumber}).then(setQuizResults);
    GetQuestionnaireResponses({personalNumber}).then(setQuestionnareResponses);
    GetBookingPageConfigurations().then(setBookingPageConfigurations);
  }, [
    personalNumber,
    loadBookings,
    loadProfile,
    loadFollowUpQueueItems,
    loadInvoices,
  ]);

  const onProfileFormEdited = async () => {
    await loadProfile();
    setEditModalVisible(false);
  };

  const onProfilePictureEdited = async () => {
    await loadProfile();
    setProfilePictureModalVisible(false);
  };

  if (!profile) {
    return <Nav highlightedSection="none" content={<CircularProgress />} />;
  }

  return (
    <Nav
      highlightedSection="none"
      content={
        <>
          {editModalVisible && (
            <BoxModal width={"50vw"} open={true} onClose={onProfileFormEdited}>
              <EditProfileForm
                profile={profile}
                onSubmitted={onProfileFormEdited}
              />
            </BoxModal>
          )}
          {profilePictureModalVisible && (
            <BoxModal
              width={"50vw"}
              open={true}
              onClose={() => setProfilePictureModalVisible(false)}>
              <ProfilePictureForm
                profile={profile}
                onSubmitted={onProfilePictureEdited}
              />
            </BoxModal>
          )}
          <Grid container>
            <div style={{float: "left", marginRight: "10px"}}>
              <IconButton onClick={() => setProfilePictureModalVisible(true)}>
                {!profile.profilePicture && (
                  <PersonIcon
                    fontSize={"large"}
                    style={{width: "40px", height: "40px"}}
                  />
                )}
                {profile.profilePicture && (
                  <img
                    src={profile.profilePicture.small}
                    alt={profile.givenName}
                    style={{borderRadius: "50%", width: "40px", height: "40px"}}
                  />
                )}
              </IconButton>
            </div>
            <Typography variant="h4" style={{marginTop: "5px"}}>
              {profile.givenName} {profile.surname}{" "}
              <span>
                {genderFromPersonalNumber(profile.personalNumber) === "male"
                  ? "Man"
                  : "Kvinna"}
                {", "}
                {ageFromPersonalNumber(profile.personalNumber)} år
              </span>
            </Typography>
          </Grid>
          <Divider style={{marginBottom: "20px"}} />
          {profile.isBannedFromBooking && (
            <>
              <Alert color="warning">
                Användaren är blockerad från att boka möten hos Womni
              </Alert>
              <div style={{marginBottom: "20px"}} />
            </>
          )}
          <Grid container spacing={5}>
            <Grid item md={5} xs={12}>
              <Grid container justifyContent="flex-end">
                <Button
                  startIcon={<EditIcon />}
                  style={{marginBottom: "-50px"}}
                  onClick={() => setEditModalVisible(true)}>
                  Ändra
                </Button>
              </Grid>
              <ProfileTable profile={profile} />
            </Grid>
            <Grid item md={7} xs={12}>
              {followUpQueueItems && bookings && (
                <ProfileActionButtons
                  profile={profile}
                  bookings={bookings}
                  onProfileChanged={loadProfile}
                  onFollowUpChanged={loadFollowUpQueueItems}
                  onInvoicesChanged={loadInvoices}
                  followUpQueueItems={followUpQueueItems}
                />
              )}
            </Grid>
          </Grid>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: "10px",
            }}>
            <Tabs
              value={selectedTab}
              variant="scrollable"
              onChange={(evt, newValue) => setSelectedTab(newValue)}
              indicatorColor="primary"
              textColor="primary">
              <Tab label="Möten" />
              <Tab label="Meddelanden" />
              <Tab
                label={
                  <>
                    <Badge badgeContent={notes && notes.length} color="info">
                      <div style={{marginRight: "12px", fontSize: "0.875rem"}}>
                        Anteckningar
                      </div>
                    </Badge>
                  </>
                }
              />
              <Tab
                label={
                  <>
                    <Badge badgeContent={openTasksCount} color="primary">
                      <div style={{marginRight: "12px", fontSize: "0.875rem"}}>
                        Ärenden
                      </div>
                    </Badge>
                  </>
                }
              />
              <Tab label="Filer" />
              <Tab
                label={
                  <>
                    <Badge badgeContent={samplesCount} color="info">
                      <div style={{marginRight: "12px", fontSize: "0.875rem"}}>
                        Blodprover
                      </div>
                    </Badge>
                  </>
                }
              />
              <Tab label="Självskattningstest" />
              <Tab label="Formulär inför möten" />
              <Tab
                label={
                  <>
                    <Badge badgeContent={unpaidInvoiceCount} color="error">
                      <div style={{marginRight: "12px", fontSize: "0.875rem"}}>
                        Fakturor
                      </div>
                    </Badge>
                  </>
                }
              />
            </Tabs>
          </Box>
          <TabPanel value={selectedTab} index={0}>
            {bookings && followUpQueueItems && bookingPageConfigurations && (
              <BookingNavigator
                bookingPageConfigurations={bookingPageConfigurations}
                onReloadNeeded={() => {
                  loadBookings();
                  loadFollowUpQueueItems();
                }}
                bookings={bookings}
                followUpQueueItems={followUpQueueItems}
              />
            )}
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <MessageFormAndListCombo personalNumber={profile.personalNumber} />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            {notes && (
              <Notes
                notes={notes}
                loadNotes={loadNotes}
                personalNumber={profile.personalNumber}
              />
            )}
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <TableContainer component={Paper}>
              {tasks && (
                <TaskList
                  onSortChanged={onSortChanged}
                  sortAscending={taskQuery.sortAscending}
                  sortBy={taskQuery.sortBy}
                  onTaskReloadRequired={loadTasks}
                  tasks={tasks}
                />
              )}
            </TableContainer>
          </TabPanel>
          <TabPanel value={selectedTab} index={4}>
            <TableContainer component={Paper}>
              {files && <FileTable files={files} />}
            </TableContainer>
          </TabPanel>
          <TabPanel value={selectedTab} index={5}>
            <Samples personalNumber={personalNumber} />
          </TabPanel>
          <TabPanel value={selectedTab} index={6}>
            {quizResults && (
              <TableContainer component={Paper}>
                <QuizresultsTable quizResults={quizResults} />
              </TableContainer>
            )}
          </TabPanel>
          <TabPanel value={selectedTab} index={7}>
            <TableContainer component={Paper}>
              {questionnaireResponses && (
                <QuestionnaireComparison
                  questionnaireResponses={questionnaireResponses}
                />
              )}
            </TableContainer>
          </TabPanel>
          <TabPanel value={selectedTab} index={8}>
            <TableContainer component={Paper}>
              {invoices && <InvoiceTable invoices={invoices} />}
            </TableContainer>
          </TabPanel>
        </>
      }
    />
  );
};

interface SamplesProps {
  personalNumber: string;
}

const Samples: React.VFC<SamplesProps> = ({personalNumber}) => {
  const [samples, setSamples] = useState<Sample[]>([]);
  useEffect(() => {
    GetSamples({patientPersonalNumber: personalNumber}).then(setSamples);
  }, []);
  return (
    <>
      {samples.map((sample, index) => (
        <div key={index} style={{marginBottom: "20px"}}>
          <div
            style={{
              padding: "10px",
              backgroundColor: "#FFF",
              border: "1px solid #EFEFEF",
              borderBottom: "0px",
            }}>
            <div>
              Datum:{" "}
              <L10nDate
                dateTime={sample.drawTime}
                options={{dateStyle: "short", timeStyle: "short"}}
              />
            </div>
            <div>Lab ID: {sample.laboratoryId}</div>
          </div>

          <Table size="small" component={Paper}>
            <TableHead>
              <TableRow>
                <TableCell>Analysnamn</TableCell>
                <TableCell>Analyskod</TableCell>
                <TableCell>Värde</TableCell>
                <TableCell>Referensvärde</TableCell>
                <TableCell>Kommentar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sample.analyses.map((ana) => (
                <TableRow key={ana.testMethodCode}>
                  <TableCell>{ana.name}</TableCell>
                  <TableCell>{ana.testMethodCode}</TableCell>
                  <TableCell>
                    {ana.value} {ana.unit}
                  </TableCell>
                  <TableCell>{ana.refText}</TableCell>
                  <TableCell>
                    {ana.commentText !== "" && (
                      <Tooltip
                        title={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: ana.commentText.replace("\n", "<br />"),
                            }}></div>
                        }>
                        <IconButton>
                          <CommentIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ))}
    </>
  );
};

const Empty: React.VFC<{reason: String}> = ({reason}) => (
  <div style={{padding: "20px"}}>{reason}</div>
);

interface InvoiceTableProps {
  invoices: Invoice[];
}

const InvoiceTable: React.VFC<InvoiceTableProps> = ({invoices}) => {
  const invoiceStatusLabel = (invoice: Invoice): string => {
    if (invoice.exportStatus === "enqueued") return "Väntar på export";
    if (invoice.exportStatus === "error") return "Tekniskt fel";
    return invoice.paymentStatus;
  };
  if (invoices.length === 0) {
    return <Empty reason="Inga fakturor" />;
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Status</TableCell>
          <TableCell>Fakturadatum</TableCell>
          <TableCell>Förfallodatum</TableCell>
          <TableCell>Belopp</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {invoices.map((invoice, index) => (
          <TableRow key={index}>
            <TableCell>{invoiceStatusLabel(invoice)}</TableCell>
            <TableCell>
              {new Date(invoice.createdAt).toLocaleDateString()}
            </TableCell>
            <TableCell>
              {invoice.dueDate &&
                new Date(invoice.dueDate).toLocaleDateString()}
            </TableCell>
            <TableCell>
              {(invoice.totalSum && invoice.totalSum + " SEK") || "N/A"}
            </TableCell>
            <TableCell align="right">
              {invoice.billogramId && (
                <Button
                  variant="contained"
                  color="primary"
                  target="_blank"
                  href={`https://billogram.com/billogram/show/${invoice.billogramId}`}>
                  Visa i billogram
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

interface QuizResultsTableProps {
  quizResults: QuizResult[];
}

const QuizresultsTable: React.VFC<QuizResultsTableProps> = ({quizResults}) => {
  if (quizResults.length === 0) {
    return <Empty reason="Inga test" />;
  }
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Datum</TableCell>
          <TableCell>Typ</TableCell>
          <TableCell>Resultat</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {quizResults.map((quizResult, index) => (
          <TableRow key={index}>
            <TableCell>
              {new Date(quizResult.createdAt).toLocaleDateString()}
            </TableCell>
            <TableCell>{getQuizTypeName(quizResult.quizType)}</TableCell>
            <TableCell>
              {getTypeName(quizResult.quizType, quizResult.typeScores)}
            </TableCell>
            <TableCell align="right">
              {" "}
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={`/quiz-results/${quizResult.id}`}>
                Visa svaren
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

interface FileTableProps {
  files: FileWithProfile[];
}
const FileTable: React.VFC<FileTableProps> = ({files}) => {
  if (files.length === 0) {
    return <Empty reason="Inga filer" />;
  }
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Datum, uppladdad</TableCell>
          <TableCell>Namn</TableCell>
          <TableCell>Vidimerad</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {files.map((file, i) => (
          <TableRow key={i}>
            <TableCell>{file.file.uploadedAt}</TableCell>
            <TableCell>{file.file.name}</TableCell>
            <TableCell>{file.file.acknowledged ? "Ja" : "Nej"}</TableCell>
            <TableCell align="right">
              <Button
                variant="outlined"
                href={`${process.env.REACT_APP_API_URL}/file-serve/${file.file.id}/${file.file.name}`}
                target="_blank"
                rel="noreferrer">
                Visa filen
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

interface ProfileTableProps {
  profile: Profile;
}

const ProfileTable: React.VFC<ProfileTableProps> = ({profile}) => {
  const [profileMapVisible, setProfileMapVisible] = useState<boolean>(false);
  const secondaryEmails = profile.emailHistory?.filter(
    (email) => email !== profile.email
  );
  return (
    <>
      {profileMapVisible && (
        <Dialog open={true} onClose={() => setProfileMapVisible(false)}>
          <DialogTitle>
            Karta{" "}
            <IconButton
              aria-label="close"
              onClick={() => setProfileMapVisible(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{width: "600px"}}>
            <ProfileMap profile={profile} />
          </DialogContent>
        </Dialog>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell width="100">Personnummer</TableCell>
              <TableCell>{profile.personalNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>E-postaddress</TableCell>
              <TableCell>{profile.email}</TableCell>
            </TableRow>
            {secondaryEmails && secondaryEmails.length > 0 && (
              <TableRow>
                <TableCell>Sekundära e-postaddresser</TableCell>
                <TableCell>{secondaryEmails.join(", ")}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Telefonnummer</TableCell>
              <TableCell>{profile.telephoneNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Adress</TableCell>
              <TableCell>
                {!profile.primaryAddress && "N/A"}
                {profile.primaryAddress &&
                  profile.primaryAddress.formattedAddress.map((s, i) => (
                    <React.Fragment key={i}>
                      {s} <br />
                    </React.Fragment>
                  ))}
                {profile.primaryAddress && (
                  <a href="#map" onClick={() => setProfileMapVisible(true)}>
                    Visa på karta
                  </a>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

interface QuestionnaireComparisonProps {
  questionnaireResponses: QuestionnaireResponse[];
}

const Answer: React.VFC<{value: any}> = ({value}) => {
  if (typeof value === "object") {
    return (
      <span>
        {Object.keys(value).map((k) => (
          <React.Fragment key={k}>
            <b>{k}</b>: {value[k]},{" "}
          </React.Fragment>
        ))}
      </span>
    );
  }
  if (typeof value === "boolean") {
    return <span>{value ? "Ja" : "Nej"}</span>;
  }
  return <span>{value}</span>;
};

const QuestionnaireComparison: React.VFC<QuestionnaireComparisonProps> = ({
  questionnaireResponses,
}) => {
  const selectables = questionnaireResponses
    .map((q) => q.questionnaire.id)
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  const [selectedType] = useState(selectables[0]);
  if (questionnaireResponses.length === 0) {
    return <Empty reason="Inga formulär" />;
  }
  const filtered = questionnaireResponses.filter(
    (q) => q.questionnaire.id === selectedType
  );
  const questionnaire = filtered[0].questionnaire;
  const flattenedQuestions: QuestionnaireQuestion[] = [];
  const flattenQuestions = (qs: QuestionnaireQuestion[]) => {
    qs.forEach((q) => {
      flattenedQuestions.push(q);
      if (q.questions) {
        flattenQuestions(q.questions);
      }
    });
  };
  flattenQuestions(questionnaire.questions);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          {filtered.map((questionnaireResponse, i) => (
            <TableCell key={i}>
              {new Date(questionnaireResponse.createdAt).toLocaleString()}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {flattenedQuestions.map((question, i) => (
          <TableRow key={i}>
            <TableCell>
              {question.id} - {question.title}
            </TableCell>
            {filtered.map((questionnaireResponse, i) => (
              <TableCell key={i}>
                <Answer value={questionnaireResponse.answers[question.id]} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

interface BookingNavigatorProps {
  bookings: Booking[];
  followUpQueueItems: FollowUpQueueItem[];
  bookingPageConfigurations: BookingPageConfiguration[];
  onReloadNeeded: () => void;
}

const BookingNavigator: React.VFC<BookingNavigatorProps> = ({
  bookings,
  followUpQueueItems,
  bookingPageConfigurations,
  onReloadNeeded,
}) => {
  const [pastBookingsVisible, setPastBookingsVisible] = useState(false);
  const [selectedFollowUp, setSelectedFollowUp] =
    useState<FollowUpQueueItem | null>(null);
  const pastBookings = bookings.filter(isPast);
  const futureBookings = bookings.filter(isFuture);
  let bookingsToPresent = futureBookings;
  if (pastBookingsVisible) {
    bookingsToPresent = [...pastBookings, ...futureBookings];
  }
  return (
    <>
      {selectedFollowUp && (
        <FollowUpModal
          bookings={bookings}
          personalNumber={selectedFollowUp.personalNumber}
          prefillWith={selectedFollowUp}
          onClose={() => {
            setSelectedFollowUp(null);
            onReloadNeeded();
          }}
        />
      )}
      {!pastBookingsVisible && pastBookings.length > 0 && (
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => setPastBookingsVisible(true)}
          style={{
            marginBottom: "10px",
          }}>
          Visa tidigare möten ({pastBookings.length})
        </Button>
      )}
      {bookingsToPresent.length > 0 && (
        <BookingList
          showFollowUpSummary={true}
          bookings={bookingsToPresent}
          onReloadNeeded={onReloadNeeded}
        />
      )}
      {followUpQueueItems.map((followUpQueueItem) => (
        <Card key={followUpQueueItem.id} style={{padding: "20px"}}>
          <Grid container>
            <Grid item>
              <Typography variant="h5">Planerad uppföljning </Typography>{" "}
            </Grid>
            <Grid item>
              <Button
                startIcon={<EditIcon />}
                onClick={() => setSelectedFollowUp(followUpQueueItem)}>
                Ändra
              </Button>
            </Grid>
          </Grid>
          <div>
            Önskat datum:{" "}
            <b>{new Date(followUpQueueItem.after).toLocaleDateString()}</b>
          </div>
          <div>
            Besökstyp:{" "}
            <b>
              {bookingPageConfigurations.find(
                (bpc) => bpc.id === followUpQueueItem.bookingPageId
              )?.friendlyName || "Bokningssida saknas"}
            </b>
          </div>
          <div>
            Önskad person:{" "}
            <b>
              {followUpQueueItem.personToBook && (
                <>
                  {followUpQueueItem.personToBook.givenName}{" "}
                  {followUpQueueItem.personToBook.surname}
                </>
              )}
            </b>
          </div>
        </Card>
      ))}
    </>
  );
};

export default ViewProfile;
