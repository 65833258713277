import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  ButtonGroup,
  FormControl,
  TextareaAutosize,
  Typography,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useEffect, useState } from "react";
import {
  GetQuestionnaire,
  GetQuestionnaireResponses,
  PostPatientRecords,
} from "../api";
import useLogger from "../hooks/useLogger";
import {
  Questionnaire,
  QuestionnaireQuestion,
  QuestionnaireResponse,
} from "../types";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: "20px",
  },
}));

interface Props {
  personalNumber: string;
  onClose: () => void;
}

type FormState = "loading" | "input" | "submitting" | "finished" | "error";

const PatientRecordModal: React.VFC<Props> = ({ personalNumber, onClose }) => {
  const css = useStyles();
  const logger = useLogger();
  const [anamnesis, setAnamnesis] = useState("");
  const [status, setStatus] = useState("");
  const [assessment, setAssessment] = useState(
    "Patienten bedöms inte ha kontraindikationer för behandling med MHT."
  );
  const [action, setAction] = useState(
    "Patienten är informerad om biverkningar och risker med behandling."
  );
  const [formState, setFormState] = useState<FormState>("input");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const submitRecord = async () => {
    setFormState("submitting");
    try {
      await PostPatientRecords({
        anamnesis,
        status,
        assessment,
        action,
        personalNumber,
      });
      setFormState("finished");
    } catch (error: any) {
      setErrorMessage(error.message);
      setFormState("error");
    }
  };

  const constructRecord = useCallback(async () => {
    try {
      const responses = await GetQuestionnaireResponses({ personalNumber });
      if (responses.length > 0) {
        const response = responses[0];
        const questionnaire = await GetQuestionnaire(response.questionnaire.id);
        setAnamnesis(buildAnamnesis(response, questionnaire));
      }
    } catch (error) {
      logger.error(error);
    }
    setFormState("input");
  }, [logger, personalNumber]);

  useEffect(() => {
    constructRecord();
  }, [constructRecord]);

  return (
    <Dialog
      fullScreen={false}
      fullWidth
      maxWidth="lg"
      open={true}
      onClose={() => onClose()}
    >
      {formState === "loading" && (
        <DialogContent>
          <CircularProgress />
          <p>Laddar patientdata</p>
        </DialogContent>
      )}
      {formState === "error" && (
        <>
          <DialogContent>
            <Alert variant="outlined" severity="error">
              <AlertTitle>Ett fel inträffade</AlertTitle>

              <p>Just nu kan din journalanteckning inte sparas. </p>
              <p>Felkod: {errorMessage}</p>
            </Alert>
          </DialogContent>{" "}
          <DialogActions>
            <Button
              onClick={() => setFormState("input")}
              color="primary"
              variant="contained"
            >
              Försök igen
            </Button>
            <Button
              onClick={() => onClose()}
              color="inherit"
              variant="contained"
            >
              Stäng fönster
            </Button>
          </DialogActions>
        </>
      )}
      {formState === "submitting" && (
        <DialogContent>
          <CircularProgress />
          <p>Skickar till webdoc</p>
        </DialogContent>
      )}
      {formState === "finished" && (
        <>
          <DialogContent>
            <Alert variant="outlined" severity="info">
              <AlertTitle>
                Journalantecknigen är nu sparad <b>men ej signerad</b> i WebDoc.
              </AlertTitle>

              <p>
                <a
                  href="https://webdoc.atlan.se/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Öppna webdoc (nytt fönster)
                </a>{" "}
                för att signera din journalanteckning.
              </p>
            </Alert>
          </DialogContent>{" "}
          <DialogActions>
            <Button
              onClick={() => onClose()}
              color="inherit"
              variant="outlined"
            >
              Stäng fönster
            </Button>
          </DialogActions>
        </>
      )}
      {formState === "input" && (
        <>
          <DialogContent>
            <FormControl fullWidth className={css.formControl}>
              <Typography variant="h5">Anamnes</Typography>
              <TextareaAutosize
                minRows={5}
                value={anamnesis}
                onChange={(evt) => setAnamnesis(evt.target.value)}
                style={{ width: "100%" }}
              />
            </FormControl>
            <FormControl fullWidth className={css.formControl}>
              <Typography variant="h5">Status</Typography>
              <TextareaAutosize
                minRows={5}
                value={status}
                onChange={(evt) => setStatus(evt.target.value)}
                style={{ width: "100%" }}
              />
            </FormControl>
            <FormControl fullWidth className={css.formControl}>
              <Typography variant="h5">Bedömning</Typography>
              <TextareaAutosize
                minRows={5}
                value={assessment}
                onChange={(evt) => setAssessment(evt.target.value)}
                style={{ width: "100%" }}
              />
            </FormControl>
            <FormControl fullWidth>
              <Typography variant="h5">Åtgärd</Typography>
              <TextareaAutosize
                minRows={5}
                value={action}
                onChange={(evt) => setAction(evt.target.value)}
                style={{ width: "100%" }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <ButtonGroup variant="contained" style={{ marginTop: "20px" }}>
              <Button color="primary" onClick={submitRecord}>
                Skicka till WebDoc
              </Button>
              <Button onClick={() => onClose()} color="inherit">
                Avbryt
              </Button>
            </ButtonGroup>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

const objectRegex = /({value.[a-zA-Z]+})/g;

const buildJournalLine = (
  q: QuestionnaireQuestion,
  response: QuestionnaireResponse
): string | null => {
  if (response.answers.hasOwnProperty(q.id) === false) {
    return null;
  }
  const answer = response.answers[q.id];
  if (q.journalWriter?.type === "stringFormat") {
    if (typeof answer === "object") {
      let format = q.journalWriter.stringFormat;
      let keys = [];
      let match = objectRegex.exec(format);
      while (match !== null) {
        keys.push(match[0].split(".")[1].replace("}", ""));
        match = objectRegex.exec(format);
      }
      for (const key of keys) {
        if (!answer[key]) {
          return null;
        }
      }
      Object.keys(answer).forEach((k) => {
        format = format.replace(`{value.${k}}`, answer[k]);
      });
      return format;
    }
    return q.journalWriter.stringFormat.replace("{value}", answer);
  }
  if (q.journalWriter?.type === "valueMatch") {
    const m = q.journalWriter.values[answer.toString()];
    return m || null;
  }
  return null;
};

const buildAnamnesis = (
  response: QuestionnaireResponse,
  questionnaire: Questionnaire
): string => {
  const flattenedQuestions: QuestionnaireQuestion[] = [];
  const flattenQuestions = (qs: QuestionnaireQuestion[]) => {
    qs.forEach((q) => {
      flattenedQuestions.push(q);
      if (q.questions) {
        flattenQuestions(q.questions);
      }
    });
  };
  flattenQuestions(questionnaire.questions);
  const lines = flattenedQuestions.map((q) => buildJournalLine(q, response));
  return lines.filter(Boolean).join("\n");
};

export default PatientRecordModal;
