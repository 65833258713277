import {TextareaAutosize, Button, ButtonGroup} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, {useState} from "react";
import {PostNotes} from "../api";
import UserAvatar from "./UserAvatar";
import {useClaimsMust} from "../hooks/useClaims";

interface CreateNoteFormParams {
  personalNumber: string;
  taskId?: string;
  onNotePosted: () => void;
}

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    float: "left",
    width: "40px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginRight: "20px",
  },
  formContainer: {
    float: "left",
    width: "calc(100% - 80px)",
  },
  noteForm: {
    width: "100%",
    content: "",
    clear: "both",
    display: "table",
  },
  speachBubble: {
    position: "absolute",
    marginLeft: "-10px",
    marginTop: "10px",
    width: "0",
    height: " 0",
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    borderRight: "10px solid #CCC",
  },
}));

const NoteForm: React.VFC<CreateNoteFormParams> = ({
  personalNumber,
  taskId,
  onNotePosted,
}) => {
  const claims = useClaimsMust();
  const styles = useStyles();
  const [body, setBody] = useState<string>("");
  const isValid = body !== "";

  const submitMessage = async () => {
    if (!isValid) {
      return;
    }
    const note = {body, personalNumber, taskId};
    await PostNotes(note);
    setBody("");
    onNotePosted();
  };

  return (
    <div className={styles.noteForm}>
      <div className={styles.avatarContainer}>
        <UserAvatar profilePicture={claims.profilePicture} size="40px" />
      </div>
      <div className={styles.formContainer}>
        <div className={styles.speachBubble}></div>
        <TextareaAutosize
          minRows={5}
          value={body}
          onChange={(evt) => setBody(evt.target.value)}
          placeholder="Skriv din anteckning här"
          style={{width: "100%"}}
        />
        <ButtonGroup>
          <Button
            color="primary"
            variant="contained"
            disabled={!isValid}
            onClick={submitMessage}>
            Spara anteckning
          </Button>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => setBody("")}
            disabled={!isValid}>
            Rensa
          </Button>
        </ButtonGroup>
        <span style={{paddingLeft: "20px", fontSize: "12px", color: "#666"}}>
          Anteckningar är endast synliga för personal på Womni.
        </span>
      </div>
    </div>
  );
};

export default NoteForm;
