import {Booking} from "../types";
import {Button, Card, Grid, Typography, ButtonGroup} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import {useState} from "react";
import PostMeetingChecklistModal from "./PostMeetingChecklistModal";
import {RatingStars} from "./RatingStars";
import {isFuture, isPast, localizeBookingReason} from "../util";
import {L10nDate} from "./Date";
import RescheduleModal from "./RescheduleModal";
import {Link} from "react-router-dom";

interface BookingListProps {
  title?: string;
  emptyTitle?: string;
  bookings: Booking[];
  showLinkToProfile?: boolean;
  showFollowUpSummary?: boolean;
  onReloadNeeded: () => void;
}

const useStyles = makeStyles((theme) => ({
  bookingCard: {},
  cancelledBookingCard: {
    "& h5": {
      textDecoration: "line-through",
    },
    "& time": {
      textDecoration: "line-through",
    },
    borderLeft: "4px solid red",
  },
  cancelledAlert: {
    color: "red",
    fontWeight: "bold",
  },
  bookedPerson: {
    marginTop: "5px",
  },
  futureBooking: {
    borderLeft: "4px solid #1976d2",
  },
  pastBooking: {
    borderLeft: "4px solid #CCC",
    backgroundColor: "#EFEFEF",
  },
  ratings: {},
  startsAt: {
    marginBottom: "5px",
  },
}));

const hasUnfinshedChecklistItems = (booking: Booking): boolean => {
  if (booking.cancelled) return false;
  return (
    !booking.hasHandledBloodSampleReferral ||
    !booking.hasHandledFollowUp ||
    !booking.isInvoiced
  );
};

const bookingKindTitle = (booking: Booking): string => {
  switch (booking.slotKind) {
    case "doctor":
      return "Nybesök, läkare";
    case "doctor-revisit":
      return "Återbesök, läkare";
    case "midwife":
      return "Nybesök, barnmorska";
    case "midwife-revisit":
      return "Återbesök, barnmorska";
    case "sexologist":
      return "Sexolog";
    case "therapist":
      return "Samtalsterapi";
    case "general-practitioner":
      return "Nybesök, allmänläkare";
    case "test":
      return "Test";
    case "health-coach":
      return "Klimakteriecoach";
    case "midwife-dynamic-code":
      return "Barnmorska, Dynamic Code";
    case "midwife-astellas":
      return "Barnmorska, Astellas";
    default:
      return booking.slotKind;
  }
};

const bookingDurationString = (booking: Booking): string => {
  const dtstart = new Date(booking.startsAt);
  const dtend = new Date(booking.endsAt);
  const diff = (dtend.getTime() - dtstart.getTime()) / 60 / 1000;
  return `${diff} minuter`;
};

const BookingList: React.VFC<BookingListProps> = ({
  bookings,
  title,
  emptyTitle,
  showFollowUpSummary,
  onReloadNeeded,
}) => {
  const [
    selectedBookingForFollowUpSummary,
    setSelectedBookingForFollowUpSummary,
  ] = useState<Booking | null>(null);
  const [selectedBookingForReschedule, setSelectedBookingForReschedule] =
    useState<Booking | null>(null);
  const styles = useStyles();

  return (
    <>
      {title && <Typography variant="h5">{title}</Typography>}
      {bookings.length === 0 && (
        <Card style={{marginBottom: "20px", padding: "20px"}}>
          {emptyTitle || "Inga bokningar"}
        </Card>
      )}
      {selectedBookingForFollowUpSummary && (
        <PostMeetingChecklistModal
          booking={selectedBookingForFollowUpSummary}
          onClose={() => {
            setSelectedBookingForFollowUpSummary(null);
            onReloadNeeded();
          }}
        />
      )}
      {selectedBookingForReschedule && (
        <RescheduleModal
          booking={selectedBookingForReschedule}
          onClose={() => {
            setSelectedBookingForReschedule(null);
            onReloadNeeded();
          }}
        />
      )}
      {bookings &&
        bookings.map((booking, index) => (
          <Card
            key={booking.id}
            style={{marginBottom: "20px", padding: "20px"}}
            className={clsx(styles.bookingCard, {
              [styles.cancelledBookingCard]: booking.cancelled,
              [styles.futureBooking]: isFuture(booking),
              [styles.pastBooking]: isPast(booking),
            })}>
            <Grid container key={index} spacing={2}>
              <Grid item md={6} xs={12}>
                <div className={styles.startsAt}>
                  <L10nDate dateTime={booking.startsAt} />
                </div>

                <Link to={`/profiles/${booking.personalNumber}`}>
                  <Typography variant="h5">
                    {booking.firstName} {booking.lastName}
                  </Typography>
                </Link>

                <div>
                  <RatingStars rating={booking.rating} />
                </div>
                {booking.cancelled && (
                  <div className={styles.cancelledAlert}>
                    Detta möte är inställt
                  </div>
                )}

                <div className={styles.bookedPerson}>
                  {booking.bookedPerson.firstName}{" "}
                  {booking.bookedPerson.lastName}, {booking.bookedPerson.role}
                </div>
                <div>
                  Patienten önskar:{" "}
                  <b>
                    {booking.meetingKind === "video"
                      ? "videomöte"
                      : "telefonmöte"}
                  </b>
                  {booking.meetingKind === "phone" && (
                    <b> {booking.telephoneNumber}</b>
                  )}
                </div>
                <div>
                  Besökstyp:{` `}
                  {bookingKindTitle(booking)}
                  {` `}({bookingDurationString(booking)})
                </div>
                <div>
                  Anledning:{` `}
                  {localizeBookingReason(booking.reason)}
                </div>
              </Grid>
              <Grid item md={6} xs={12} style={{textAlign: "right"}}>
                <ButtonGroup variant="contained" color="inherit">
                  {booking.meetingKind === "video" && (
                    <Button
                      color="primary"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_MEET_URL}/${booking.id}`,
                          "_blank"
                        )
                      }>
                      Starta videomöte
                    </Button>
                  )}
                  {showFollowUpSummary === true &&
                    hasUnfinshedChecklistItems(booking) && (
                      <Button
                        color="inherit"
                        onClick={() =>
                          setSelectedBookingForFollowUpSummary(booking)
                        }>
                        Checklista efter mötet
                      </Button>
                    )}
                  {isFuture(booking) && booking.cancelled === false && (
                    <Button
                      color="inherit"
                      onClick={() => setSelectedBookingForReschedule(booking)}>
                      Avboka/Omboka besök
                    </Button>
                  )}
                </ButtonGroup>
              </Grid>
            </Grid>
          </Card>
        ))}
    </>
  );
};

export default BookingList;
