import {
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import {useEffect} from "react";
import {useContext, useState} from "react";
import {GetMyTasks, GetProfiles, GetTasks, GetOpenTasksStats} from "../api";
import {TasksContext} from "../contexts/TasksContext";
import {useClaimsMust} from "../hooks/useClaims";
import {OpenTasksStatsAggregate, Profile, Task, TaskQuery} from "../types";
import Nav from "../components/Nav";
import TaskList from "../components/TaskList";

const Tasks = () => {
  const claims = useClaimsMust();
  const endpoint = claims.role === "admin" ? GetTasks : GetMyTasks;
  const {setTasks: setContextTasks} = useContext(TasksContext);
  const [tasks, setTasks] = useState<Task[] | null>(null);
  const [taskQuery, setTaskQuery] = useState<TaskQuery>({
    skip: 0,
    limit: 50,
    closed: false,
    assignedTo: claims.id,
    sortBy: "createdat",
    sortAscending: true,
  });

  const reloadTasks = () => {
    // Reload user tasks to update dependant components
    GetMyTasks({}).then(setContextTasks);
    endpoint(taskQuery).then(setTasks);
  };

  const onSortChanged = (column: string, sortAscending: boolean) => {
    setTaskQuery({
      ...taskQuery,
      sortBy: column,
      sortAscending,
    });
  };

  useEffect(() => {
    endpoint(taskQuery).then(setTasks);
  }, [taskQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!tasks) {
    return <Nav highlightedSection="tasks" content={<CircularProgress />} />;
  }

  const handlePrevPageClicked = () => {
    const [s, l] = [taskQuery.skip || 0, taskQuery.limit || 0];
    setTaskQuery({
      ...taskQuery,
      skip: s - l,
    });
  };

  const handleNextPageClicked = () => {
    const [s, l] = [taskQuery.skip || 0, taskQuery.limit || 0];
    setTaskQuery({
      ...taskQuery,
      skip: s + l,
    });
  };

  return (
    <Nav
      highlightedSection="tasks"
      content={
        <>
          <TaskFilters onChange={setTaskQuery} taskQuery={taskQuery} />
          <TaskList
            sortAscending={taskQuery.sortAscending}
            sortBy={taskQuery.sortBy}
            onTaskReloadRequired={reloadTasks}
            onSortChanged={onSortChanged}
            tasks={tasks}
          />
          <Grid container justifyContent="flex-end" style={{marginTop: "20px"}}>
            <ButtonGroup variant="outlined">
              <Button
                variant="outlined"
                onClick={handlePrevPageClicked}
                disabled={taskQuery.skip === 0}>
                &laquo; Föregående sida
              </Button>
              <Button
                variant="outlined"
                onClick={handleNextPageClicked}
                disabled={tasks.length < (taskQuery.limit || 0)}>
                Nästa sida &raquo;
              </Button>
            </ButtonGroup>
          </Grid>
        </>
      }
    />
  );
};

interface TaskFiltersProps {
  onChange: (f: TaskQuery) => void;
  taskQuery: TaskQuery;
}

const TaskFilters: React.VFC<TaskFiltersProps> = ({taskQuery, onChange}) => {
  const {role} = useClaimsMust();
  const [profiles, setProfiles] = useState<Profile[] | null>(null);
  const [openTasksStats, setOpenTasksStats] = useState<
    OpenTasksStatsAggregate[] | null
  >(null);
  useEffect(() => {
    if (role === "admin") {
      GetProfiles({
        roles: ["admin", "medical"],
        limit: 999999, // TODO: This should probably be an autocomplete in the future.
      }).then(setProfiles);
      GetOpenTasksStats({}).then(setOpenTasksStats).catch();
    }
  }, [role]);

  const sumByKind = (
    openTasksStats: OpenTasksStatsAggregate[],
    taskKind: string
  ) => {
    return openTasksStats
      .filter((o) => o.taskKind === taskKind)
      .map((o) => o.count)
      .reduce((t, i) => t + i, 0);
  };

  const sumExpiredByKind = (
    openTasksStats: OpenTasksStatsAggregate[],
    taskKind: string
  ) => {
    return openTasksStats
      .filter((o) => o.taskKind === taskKind && o.expired === true)
      .map((o) => o.count)
      .reduce((t, i) => t + i, 0);
  };

  return (
    <div style={{marginBottom: "10px"}}>
      {role === "admin" && (
        <FormControl>
          <Select
            value={taskQuery.assignedTo === "" ? "all" : taskQuery.assignedTo}
            fullWidth
            size="small"
            variant="outlined"
            labelId="booked-person-label"
            onChange={(evt: any) =>
              onChange({
                ...taskQuery,
                assignedTo: evt.target.value === "all" ? "" : evt.target.value,
              })
            }>
            <MenuItem value={"all"}>Allas ärenden</MenuItem>
            {profiles &&
              profiles.map((profile) => (
                <MenuItem key={profile.id} value={profile.id}>
                  {profile.givenName} {profile.surname}, {profile.title}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      <FormControl style={{marginLeft: "20px"}}>
        <FormControlLabel
          control={
            <Checkbox
              checked={taskQuery.closed}
              onChange={(evt) =>
                onChange({
                  ...taskQuery,
                  closed: evt.target.checked,
                })
              }
            />
          }
          label="Avslutade ärenden"
        />
      </FormControl>
      {role === "admin" && openTasksStats && (
        <div
          style={{
            padding: "7px 14px",
            border: "1px solid #ECECEC",
            background: "#FFF",
            float: "right",
          }}>
          <span>
            Recept: {sumByKind(openTasksStats, "prescription")} (
            {sumExpiredByKind(openTasksStats, "prescription")}) &nbsp;|&nbsp;
            Remiss: {sumByKind(openTasksStats, "referral")} (
            {sumExpiredByKind(openTasksStats, "referral")}) &nbsp;|&nbsp;
            Kontakt: {sumByKind(openTasksStats, "contact")} (
            {sumExpiredByKind(openTasksStats, "contact")})
          </span>
        </div>
      )}
    </div>
  );
};

export default Tasks;
