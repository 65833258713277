import React, { useEffect, useRef, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { SearchProfiles } from "../api";
import { Profile } from "../types";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    marginTop: "-25px",
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 500,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    height: "40px",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  searchResults: {},
  searchResultItem: {
    borderBottom: "1px solid #CCC",
    padding: "10px",
    cursor: "pointer",
  },
}));

const ProfileSearch: React.VFC = () => {
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const [searchInput, setSearchInput] = useState<string>("");
  const [profiles, setProfiles] = useState<Profile[] | null>(null);

  useEffect(() => {
    const escFunction = (evt: KeyboardEvent) => {
      if (evt.keyCode === 27) {
        setSearchInput("");
        setProfiles(null);
      }
    };

    const docuClick = (evt: MouseEvent | TouchEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(evt.target as Node)
      ) {
        setSearchInput("");
        setProfiles(null);
      }
    };
    document.addEventListener("mousedown", docuClick);
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("mousedown", docuClick);
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (searchInput !== "") {
      SearchProfiles(searchInput).then(setProfiles);
    } else {
      setProfiles(null);
    }
  }, [searchInput]);

  return (
    <div ref={containerRef}>
      <Paper component="form" className={classes.paper}>
        <div className={classes.root}>
          <InputBase
            className={classes.input}
            value={searchInput}
            onChange={(evt) => setSearchInput(evt.target.value)}
            placeholder="Sök patient på personnummer, e-post eller telefonnummer"
          />
          <SearchIcon />
        </div>
        {profiles && (
          <div className={classes.searchResults}>
            {profiles.length === 0 && (
              <div>Inga patienter matchar din sökning</div>
            )}
            {profiles.map((profile) => (
              <div
                key={profile.id}
                className={classes.searchResultItem}
                onClick={() => {
                  window.location.href = `/profiles/${profile.personalNumber}`;
                }}
              >
                <Typography variant="h5">
                  {profile.givenName} {profile.surname}
                </Typography>
                <div>
                  E-postaddress: {profile.email}
                  <br /> Telefonnummer: {profile.telephoneNumber}
                  <br /> Personnummer: {profile.personalNumber}
                </div>
              </div>
            ))}
          </div>
        )}
      </Paper>
    </div>
  );
};

export default ProfileSearch;
