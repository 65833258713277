import {
  Booking,
  Rating,
  Profile,
  FileWithProfile,
  InvoiceItemWithQuantity,
  Message,
  GetProfilesOptions,
  CreateProfileRequest,
  PatchProfileRequest,
  BookingQuery,
  FollowUpQueueItem,
  FollowUpQueueWeeklyCount,
  Note,
  MapPoint,
  InvoicingByPersonAggregate,
  QuizResultAggregate,
  Slot,
  CreateRecordRequest,
  QuestionnaireResponse,
  Questionnaire,
  TaskTemplate,
  Task,
  CreateMessageRequest,
  CreateInvoiceRequest,
  Invoice,
  BookingsStats,
  PointOfInterest,
  TaskQuery,
  Lab,
  LabAnalyses,
  CreateReferralRequest,
  CreateReferralResponse,
  Referral,
  QuizType,
  Quiz,
  QuizResult,
  CreateFollowUpQueueItemRequest,
  NotesQuery,
  CreateNoteRequest,
  MessageTemplate,
  PostProfilesBanRequest,
  OpenTasksStatsAggregate,
  GetRebatesQuery,
  Rebate,
  CreateRebateRequest,
  PatchRebateRequest,
  RatingsQuery,
  QuizResultsStatsQuery,
  RatingsStatsQuery,
  RatingsAggregate,
  SamplesQuery,
  Sample,
  TasksStats,
  TaskStatsQuery,
  BookingPageConfiguration,
  BankIDPhoneRequest,
} from "./types";

export const ValidateAuth = async (): Promise<Boolean> => {
  try {
    const response = await request(
      `${process.env.REACT_APP_API_URL}/auth/validate`
    );
    return response.ok;
  } catch (error) {
    return false;
  }
};

export const PostBookingPageConfigurations = async (
  bookingPageConfiguration: BookingPageConfiguration
): Promise<BookingPageConfiguration> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/booking-page-configurations`,
    "POST",
    JSON.stringify(bookingPageConfiguration)
  );
  return response.json();
};

export const PutBookingPageConfiguration = async (
  id: string,
  bookingPageConfiguration: BookingPageConfiguration
): Promise<BookingPageConfiguration> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/booking-page-configurations/${id}`,
    "PUT",
    JSON.stringify(bookingPageConfiguration)
  );
  return response.json();
};

export const GetBookingPageConfigurations = async (): Promise<
  BookingPageConfiguration[]
> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/booking-page-configurations`,
    "GET"
  );
  return response.json();
};

export const GetBookingPageConfigurationFromId = async (
  id: string
): Promise<BookingPageConfiguration> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/booking-page-configurations/${id}`,
    "GET"
  );
  return response.json();
};

export const GetQuiz = async (quizType: QuizType): Promise<Quiz> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/quiz/${quizType}`,
    "GET"
  );
  return response.json();
};

export const GetReferralLabsAnalyses = async (
  ediCode: string
): Promise<LabAnalyses> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/referral/labs/${ediCode}/analyses`
  );
  return response.json();
};

export const GetPointsOfInterest = async (): Promise<PointOfInterest[]> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/public/points-of-interest`
  );
  return response.json();
};

export const SearchProfiles = async (query: string): Promise<Profile[]> => {
  const params = serializeQueryString({q: query});
  const response = await request(
    `${process.env.REACT_APP_API_URL}/profiles/search?${params}`
  );
  return response.json();
};

export const GetSlots = async (options: any): Promise<Slot[]> => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/slots?${params}`
  );
  return response.json();
};

export const GetMyFollowedProfiles = async (): Promise<Profile[]> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/me/followed-profiles`
  );
  return response.json();
};

export const GetProfiles = async (
  options: GetProfilesOptions
): Promise<Profile[]> => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/profiles?${params}`
  );
  return response.json();
};

export const PostProfiles = async (
  profile: CreateProfileRequest
): Promise<void> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/profiles`,
    "POST",
    JSON.stringify(profile)
  );
  return response.json();
};

export const PostReferrals = async (
  r: CreateReferralRequest
): Promise<CreateReferralResponse> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/referrals`,
    "POST",
    JSON.stringify(r)
  );
  if (!response.ok) {
    const responseText = await response.text();
    throw new Error(`unable to POST /referrals ${responseText}`);
  }
  return response.json();
};

export const PostProfileRefreshAddress = async (
  id: string
): Promise<Profile> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/profiles/${id}/refresh-address`,
    "POST"
  );
  return response.json();
};

export const PatchProfile = async (profile: PatchProfileRequest) => {
  const {id, ...rest} = profile;
  const response = await request(
    `${process.env.REACT_APP_API_URL}/profiles/${id}`,
    "PATCH",
    JSON.stringify(rest)
  );
  return response.json();
};

export const PostProfilesBan = async (profile: PostProfilesBanRequest) => {
  const {id, ...rest} = profile;
  const response = await request(
    `${process.env.REACT_APP_API_URL}/profiles/${id}/ban`,
    "POST",
    JSON.stringify(rest)
  );
  return response.json();
};

export const PostPatientRecords = async (
  createRecordRequest: CreateRecordRequest
): Promise<void> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/patientRecords`,
    "POST",
    JSON.stringify(createRecordRequest)
  );
  return response.json();
};

export const PutProfilePicture = async (
  id: string,
  file: File
): Promise<void> => {
  const formData = new FormData();
  formData.append("picture", file);
  await request(
    `${process.env.REACT_APP_API_URL}/profiles/${id}/profile-picture`,
    "PUT",
    formData
  );
};

export const GetIsCalendarConnected = async (): Promise<Boolean> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/me/is-calendar-connected`
  );
  return response.json();
};

export const ConfirmFile = async (id: string): Promise<Profile[]> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/files/${id}/confirm`,
    "POST"
  );
  return response.json();
};

export const GetQuizResults = async (options: any) => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/quiz-results?${params}`
  );
  return response.json();
};

export const GetQuestionnaireResponses = async (
  options: any
): Promise<QuestionnaireResponse[]> => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/questionnaire-responses?${params}`
  );
  return response.json();
};

export const GetQuizResult = async (id: string): Promise<QuizResult> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/quiz-results/${encodeURIComponent(id)}`
  );
  return response.json();
};

export const GetInvoiceItems = async (): Promise<InvoiceItemWithQuantity[]> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/invoice-items`
  );
  return response.json();
};

export const GetQuestionnaire = async (id: string): Promise<Questionnaire> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/questionnaires/${id}`
  );
  return response.json();
};

export const PostInvoice = async (
  req: CreateInvoiceRequest
): Promise<Invoice> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/invoices`,
    "POST",
    JSON.stringify(req)
  );
  return response.json();
};

export const GetBookingReferrals = async (
  bookingId: string
): Promise<Referral[]> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/bookings/${bookingId}/referrals`
  );
  return response.json();
};

export const GetBookings = async (query: BookingQuery): Promise<Booking[]> => {
  const params = serializeQueryString(query);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/bookings?${params}`
  );
  return response.json();
};

export const PutBookingHasHandledBloodSampleReferral = async (
  id: string
): Promise<Booking> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/bookings/${id}/has-handled-blood-sample-referral`,
    "PUT"
  );
  return response.json();
};

export const GetRoles = async (): Promise<string[]> => {
  const response = await request(`${process.env.REACT_APP_API_URL}/roles`);
  return response.json();
};

export const GetMedicalRoles = async (): Promise<string[]> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/medical-roles`
  );
  return response.json();
};

export const GetRatings = async (query: RatingsQuery): Promise<Rating[]> => {
  const params = serializeQueryString(query);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/ratings?${params}`
  );
  return response.json();
};

export const GetFiles = async (options: any): Promise<FileWithProfile[]> => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/files?${params}`
  );
  return response.json();
};

export const GetInvoices = async (options: any): Promise<Invoice[]> => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/invoices?${params}`
  );
  return response.json();
};

export const GetMyProfile = async (): Promise<Profile> => {
  const response = await request(`${process.env.REACT_APP_API_URL}/me/profile`);
  return response.json();
};

export const GetProfile = async (personalNumber: string): Promise<Profile> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/profiles/${personalNumber}`
  );
  return response.json();
};

export const GetMessageTemplates = async (): Promise<MessageTemplate[]> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/message-templates`
  );
  return response.json();
};

export const DeleteMessageTemplate = async (key: string): Promise<void> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/message-templates/${key}`,
    "DELETE"
  );
  return response.json();
};

export const PostMessageTemplates = async (
  m: MessageTemplate
): Promise<void> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/message-templates`,
    "POST",
    JSON.stringify(m)
  );
  return response.json();
};

export const GetProfileLocation = async (
  personalNumber: string
): Promise<MapPoint> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/profiles/${personalNumber}/location`
  );
  return response.json();
};

export const GetNotes = async (options: NotesQuery): Promise<Note[]> => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/notes?${params}`
  );
  return response.json();
};

export const PostNotes = async (data: CreateNoteRequest): Promise<void> => {
  await request(
    `${process.env.REACT_APP_API_URL}/notes`,
    "POST",
    JSON.stringify(data)
  );
};

export const GetMessages = async (options: any): Promise<Message[]> => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/messages?${params}`
  );
  return response.json();
};

export const DeleteMessage = async (id: string): Promise<void> => {
  await request(`${process.env.REACT_APP_API_URL}/messages/${id}`, "DELETE");
};

export const DeleteNote = async (id: string): Promise<void> => {
  await request(`${process.env.REACT_APP_API_URL}/notes/${id}`, "DELETE");
};

export const PostMessage = async (
  message: CreateMessageRequest
): Promise<void> => {
  await request(
    `${process.env.REACT_APP_API_URL}/messages`,
    "POST",
    JSON.stringify(message)
  );
};

export const GetReferralLabs = async (options: any): Promise<Lab[]> => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/referral/labs?${params}`
  );
  return response.json();
};

export const PostFollowUpQueue = async (
  req: CreateFollowUpQueueItemRequest
): Promise<void> => {
  await request(
    `${process.env.REACT_APP_API_URL}/follow-up-queue`,
    "POST",
    JSON.stringify(req)
  );
};

export const GetFollowUpQueue = async (
  options: any
): Promise<FollowUpQueueItem[]> => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/follow-up-queue?${params}`
  );
  return response.json();
};

export const GetTaskTemplate = async (
  taskKind: string
): Promise<TaskTemplate> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/task-templates/${taskKind}`
  );
  return response.json();
};

export const PutTask = async (id: string, data: any): Promise<Task> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/tasks/${id}`,
    "PUT",
    JSON.stringify(data)
  );
  return response.json();
};

export const PostTaskAssignees = async (
  id: string,
  assigneeId: string[]
): Promise<Task> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/tasks/${id}/assignees`,
    "POST",
    JSON.stringify(assigneeId)
  );
  return response.json();
};

export const GetSamples = async (options: SamplesQuery): Promise<Sample[]> => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/samples?${params}`
  );
  return response.json();
};

export const GetMyTasks = async (options: TaskQuery) => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/me/tasks?${params}`
  );
  return response.json();
};

export const GetTasks = async (options: TaskQuery): Promise<Task[]> => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/tasks?${params}`
  );
  return response.json();
};

export const GetFollowUpQueueStats = async (
  options: any
): Promise<FollowUpQueueWeeklyCount[]> => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/stats/follow-up-queue?${params}`
  );
  return response.json();
};

export const GetTasksStats = async (
  options: TaskStatsQuery
): Promise<TasksStats[]> => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/stats/tasks?${params}`
  );
  return response.json();
};

export const GetRatingsStats = async (
  options: RatingsStatsQuery
): Promise<RatingsAggregate[]> => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/stats/ratings?${params}`
  );
  return response.json();
};

export const GetOpenTasksStats = async (
  options: any
): Promise<OpenTasksStatsAggregate[]> => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/stats/open-tasks?${params}`
  );
  return response.json();
};

export const GetQuizResultsStats = async (
  options: QuizResultsStatsQuery
): Promise<QuizResultAggregate[]> => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/stats/quiz-results?${params}`
  );
  return response.json();
};

export const GetInvoicingStats = async (
  options: any
): Promise<InvoicingByPersonAggregate[]> => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/stats/invoicing?${params}`
  );
  return response.json();
};

export const GetStatsBookings = async (
  options: any
): Promise<BookingsStats> => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/stats/bookings?${params}`
  );
  return response.json();
};

export const DeleteFollowUpQueueItem = async (id: string) => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/follow-up-queue/${id}`,
    "DELETE"
  );
  return response.json();
};

export const PostVoiceGrant = async (): Promise<{token: string}> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/voice/grant`,
    "POST"
  );
  return response.json();
};

export const PostAuthBankIDPhone = async (req: BankIDPhoneRequest) => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/auth/bankid-phone`,
    "POST",
    JSON.stringify(req)
  );
  return response.json();
};

export interface CollectResponse {
  status?: string;
  error?: string;
}

export const PostAuthBankIDCollect = async (
  orderRef: string
): Promise<CollectResponse> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/auth/bankid/collect`,
    "POST",
    JSON.stringify({orderRef})
  );
  return response.json();
};

export const PostAuthBankIDCancel = async (orderRef: string) => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/auth/bankid/cancel`,
    "POST",
    JSON.stringify({orderRef})
  );
  return response.json();
};

export const GetRebates = async (
  options: GetRebatesQuery
): Promise<Rebate[]> => {
  const params = serializeQueryString(options);
  const response = await request(
    `${process.env.REACT_APP_API_URL}/rebates?${params}`
  );
  return response.json();
};

export const PostRebates = async (
  createRebateRequest: CreateRebateRequest
): Promise<Rebate> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/rebates`,
    "POST",
    JSON.stringify(createRebateRequest)
  );
  return response.json();
};

export const DeleteRebate = async (id: string): Promise<void> => {
  await request(`${process.env.REACT_APP_API_URL}/rebates/${id}`, "DELETE");
};

export const PatchRebate = async (
  id: string,
  patchRebateRequest: PatchRebateRequest
): Promise<void> => {
  await request(
    `${process.env.REACT_APP_API_URL}/rebates/${id}`,
    "PATCH",
    JSON.stringify(patchRebateRequest)
  );
};

const request = async (
  url: string,
  method = "GET",
  body: string | FormData | null = null
) => {
  const response = await fetch(url, {
    credentials: "include",
    method,
    body,
  });
  if (response.status === 401) {
    window.location.assign(
      `${process.env.REACT_APP_APP_URL}/login?redirectURL=${encodeURIComponent(
        window.location.href
      )}`
    );
  }
  if (response.status === 403) {
    window.location.assign(`${process.env.REACT_APP_APP_URL}/forbidden`);
  }
  if (!response.ok) {
    throw new Error(
      `Unable to execture ${method} to ${url} - status: ${response.status}`
    );
  }
  return response;
};

const serializeQueryString = (obj: any) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      const value = obj[p];
      if (Array.isArray(value)) {
        for (var i = 0; i < value.length; i++) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(value[i]));
        }
      } else {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(value));
      }
    }
  return str.join("&");
};
