export const Divider = () => (
  <div
    style={{
      clear: "both",
      borderBottom: "1px solid #CCC",
      margin: "0px 0px 10px 0px",
      paddingTop: "10px",
    }}
  ></div>
);
