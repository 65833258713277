import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import {Link} from "react-router-dom";
import {Divider} from "../../components/Divider";
import Nav from "../../components/Nav";

const SettingsIndex = () => {
  return (
    <Nav
      highlightedSection={"settings"}
      content={
        <>
          <Typography variant="h4">Inställningar</Typography>
          <Divider />
          <Grid container spacing={2} alignItems={"stretch"}>
            <Grid item sm={3} style={{display: "flex"}}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Meddelandemallar
                  </Typography>
                  <Typography variant="body1">
                    Sammanställning av bokade möten.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    component={Link}
                    to="/settings/message-templates">
                    Visa
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item sm={3} style={{display: "flex"}}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Rabattkoder
                  </Typography>
                  <Typography variant="body1">
                    Visa/skapa rabattkoder
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" component={Link} to="/settings/rebates">
                    Visa
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item sm={3} style={{display: "flex"}}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Bokningssidor
                  </Typography>
                  <Typography variant="body1">
                    Visa/skapa bokningssidor
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    component={Link}
                    to="/settings/booking-page-configurations">
                    Visa
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default SettingsIndex;
