import {
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogActions,
  Alert,
} from "@mui/material";
import {useEffect, useState} from "react";
import {PostInvoice, GetInvoiceItems} from "../api";
import {InvoiceItemWithQuantity, Profile} from "../types";
import InvoicingForm from "./InvoicingForm";
import {nonMeeingInvoiceItem} from "../util";

export interface InvoiceProfileModalProps {
  profile: Profile;
  onClose: (modified: boolean | undefined) => void;
}

const InvoiceProfileModal: React.VFC<InvoiceProfileModalProps> = ({
  profile,
  onClose,
}) => {
  const [invoiceItems, setInvoiceItems] = useState<
    InvoiceItemWithQuantity[] | null
  >(null);

  useEffect(() => {
    GetInvoiceItems().then(setInvoiceItems);
  }, []);

  const createInvoice = async () => {
    if (!invoiceItems) {
      return;
    }
    if (
      !window.confirm(
        "Är du säker på att du vill skapa faktura med dessa tjänster/produkter?"
      )
    ) {
      return;
    }
    try {
      await PostInvoice({
        bookingId: null,
        items: invoiceItems,
        personalNumber: profile.personalNumber,
      });
    } catch (error) {
      alert("något gick fel, försök igen");
      return;
    }
    onClose(true);
    alert("Fakturan är nu skapad");
  };

  if (!invoiceItems) return <Dialog open={true} fullScreen={false}></Dialog>;

  return (
    <Dialog
      fullScreen={false}
      fullWidth
      maxWidth="lg"
      open={true}
      onClose={() => onClose(false)}>
      <DialogContent>
        <Alert color="warning">
          <h3>Skapa faktura för extra-tjänster</h3>
          OBS: Om du skall skapa en faktura för ett <b>möte</b> så använd
          checklistan efter mötet.
          <br />
          <br />
          Här kan du endast skapa faktura för extratjänster som har utförts
          utanför ordinarie möten (t ex förskrivning av recept).
        </Alert>
        <InvoicingForm
          invoiceItemsFilter={nonMeeingInvoiceItem}
          onInvoiceItemsChanged={setInvoiceItems}
        />
      </DialogContent>
      <DialogActions>
        <ButtonGroup variant="contained" style={{marginTop: "20px"}}>
          <Button color="primary" onClick={createInvoice}>
            Skapa faktura
          </Button>
          <Button onClick={() => onClose(false)} color="inherit">
            Avbryt
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceProfileModal;
