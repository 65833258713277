import { useCookies } from "react-cookie";
import { Claims } from "../types";
import { decodeBase64Unicode } from "../util";
import useLogger from "./useLogger";

const useClaims = (): Claims | undefined => {
  const [cookies] = useCookies(["user"]);
  const logger = useLogger();
  try {
    return JSON.parse(decodeBase64Unicode(cookies.user)) as Claims;
  } catch (error) {
    logger.error(error);
    return;
  }
};

export const useClaimsMust = (): Claims => {
  const claims = useClaims();
  if (!claims) {
    throw new Error("Claims not available");
  }
  return claims;
};

export default useClaims;
