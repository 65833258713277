import { Alert, AlertTitle, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { GetIsCalendarConnected } from "../api";

const GoogleCalendarConnect: React.VFC = () => {
  const [isConnected, setIsConnected] = useState<Boolean | null>(null);

  useEffect(() => {
    GetIsCalendarConnected().then(setIsConnected).catch();
  }, []);

  if (isConnected === null || isConnected === true) {
    return <></>;
  }
  return (
    <Alert
      variant="outlined"
      severity="warning"
      style={{ marginBottom: "20px" }}
    >
      <AlertTitle>Koppla din kalender</AlertTitle>
      <p>
        För kunna använda Womni Admin behöver du koppla din Google Kalender. Du
        kommer behöva godkänna att Womni får tillgång till din kalender.
      </p>
      <p>
        Säkerställ att du är inloggad med din @womni.se-address innan du
        påbörjar kopplingen.
      </p>
      <Button
        size="small"
        variant="contained"
        color="primary"
        href={`${
          process.env.REACT_APP_API_URL
        }/google-calendar/grant?returnURL=${encodeURIComponent(
          window.location.href
        )}`}
      >
        Koppla kalender
      </Button>
    </Alert>
  );
};

export default GoogleCalendarConnect;
