import React from "react";

export interface Props extends React.ComponentProps<"time"> {
  dateTime: string;
  options?: Intl.DateTimeFormatOptions;
}

export const L10nDate: React.FC<Props> = ({
  dateTime: dateTimeString,
  options: dtOptions,
  ...rest
}) => {
  let options: Intl.DateTimeFormatOptions = dtOptions || {
    dateStyle: "full",
    timeStyle: "medium",
  };
  let date: Date | undefined;
  try {
    date = new Date(dateTimeString);
  } catch (error) {}

  return (
    <time {...rest} dateTime={date?.toISOString() || dateTimeString}>
      {date && `${date.toLocaleString("sv-SE", options)}`}
      {!date && dateTimeString}
    </time>
  );
};
