import {
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {GetMessageTemplates} from "../api";
import {MessageTemplate} from "../types";

interface MessageTemplateSelectorDialogParams {
  onTemplateSelected: (template: string) => void;
}

export const MessageTemplateSelectorDialog: React.VFC<
  MessageTemplateSelectorDialogParams
> = ({onTemplateSelected}) => {
  const [messageTemplates, setMessageTemplates] = useState<MessageTemplate[]>(
    []
  );

  useEffect(() => {
    GetMessageTemplates().then(setMessageTemplates);
  }, []);

  return (
    <Dialog open={true} onClose={() => onTemplateSelected("")}>
      <DialogContent>
        <List>
          {messageTemplates.map((messageTemplate, i) => (
            <ListItem
              key={i}
              onClick={() => onTemplateSelected(messageTemplate.message)}>
              <ListItemButton>{messageTemplate.title}</ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default MessageTemplateSelectorDialog;
