import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DownloadingIcon from "@mui/icons-material/Downloading";
import {useEffect, useState} from "react";
import {GetBookingPageConfigurations, GetProfiles, GetRatings} from "../api";
import {
  BookingPageConfiguration,
  Profile,
  Rating,
  RatingsQuery,
} from "../types";
import Nav from "../components/Nav";
import {useClaimsMust} from "../hooks/useClaims";
import DateRangeSelector from "../components/dates/DateRangeSelector";
import {L10nDate} from "../components/Date";

const Ratings = () => {
  const [ratings, setRatings] = useState<Rating[] | null>(null);
  const {role, id} = useClaimsMust();
  const [ratingsQuery, setRatingsQuery] = useState<RatingsQuery>({
    skip: 0,
    limit: 100,
    bookingPageId: "all",
    bookedPersonId: role === "admin" ? "all" : id,
    from: "",
    to: "",
  });

  useEffect(() => {
    GetRatings(ratingsQuery).then(setRatings);
  }, [ratingsQuery]);

  if (!ratings) {
    return <Nav highlightedSection="ratings" content={<CircularProgress />} />;
  }

  return (
    <Nav
      highlightedSection="ratings"
      content={
        <>
          <RatingsFilters
            ratings={ratings}
            ratingsQuery={ratingsQuery}
            onChange={setRatingsQuery}
          />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Datum</TableCell>
                  <TableCell>Patient</TableCell>
                  <TableCell>Vårdgivare</TableCell>
                  <TableCell>Betyg</TableCell>
                  <TableCell>Text</TableCell>
                  <TableCell>Rekommenderar Womni?</TableCell>
                  <TableCell>Hur hörde du talas om Womni?</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ratings.map((rating, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <L10nDate
                        dateTime={rating.createdAt}
                        options={{dateStyle: "short", timeStyle: "short"}}
                      />
                    </TableCell>
                    <TableCell>
                      <a href={`/profiles/${rating.personalNumber}`}>
                        {rating.firstName} {rating.lastName}
                      </a>
                    </TableCell>
                    <TableCell>
                      {rating.bookedPerson.firstName}{" "}
                      {rating.bookedPerson.lastName}
                    </TableCell>
                    <TableCell>{rating.rating} / 5</TableCell>
                    <TableCell>{rating.text}</TableCell>
                    <TableCell>
                      {rating.wouldRecommend ? "Ja" : "Nej"}
                    </TableCell>
                    <TableCell>{rating.source}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
    />
  );
};

interface RatingsFiltersProps {
  onChange: (f: RatingsQuery) => void;
  ratingsQuery: RatingsQuery;
  ratings: Rating[];
}

const RatingsFilters: React.VFC<RatingsFiltersProps> = ({
  ratingsQuery,
  onChange,
  ratings,
}) => {
  const {role} = useClaimsMust();
  const [profiles, setProfiles] = useState<Profile[] | null>(null);
  const [bookingPageconfigurations, setBookingPageConfigurations] = useState<
    BookingPageConfiguration[] | null
  >(null);
  useEffect(() => {
    if (role === "admin") {
      GetProfiles({
        roles: ["admin", "medical"],
        limit: 999999, // TODO: This should probably be an autocomplete in the future.
      }).then(setProfiles);
    }
    GetBookingPageConfigurations().then(setBookingPageConfigurations);
  }, [role]);

  const csvCleanString = (s: string): string => {
    return `"${s.replace(/"/g, '""')}"`;
  };

  const downloadCsv = () => {
    if (!ratings) return;
    const convertToCSV = () => {
      let str =
        "Patient name,Booked person,created at,rating,would recommend,source,text\r\n";

      for (let i = 0; i < ratings.length; i++) {
        const rating = ratings[i];
        str +=
          `${csvCleanString(
            rating.firstName + " " + rating.lastName
          )},${csvCleanString(
            rating.bookedPerson.firstName + " " + rating.bookedPerson.lastName
          )},` +
          `${rating.createdAt},${rating.rating},${
            rating.wouldRecommend
          },${csvCleanString(rating.source)},${csvCleanString(rating.text)}` +
          `\r\n`;
      }
      return str;
    };
    const csvData = new Blob([convertToCSV()], {type: "text/csv"});
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = `ratings.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Grid container style={{marginBottom: "20px"}}>
      <Grid item sm={6}>
        {role === "admin" && (
          <FormControl>
            <Select
              value={
                ratingsQuery.bookedPersonId === ""
                  ? "all"
                  : ratingsQuery.bookedPersonId
              }
              fullWidth
              size="small"
              variant="outlined"
              labelId="booked-person-label"
              onChange={(evt: any) =>
                onChange({
                  ...ratingsQuery,
                  bookedPersonId: evt.target.value,
                })
              }>
              <MenuItem value={"all"}>Allas recensioner</MenuItem>
              {profiles &&
                profiles.map((profile) => (
                  <MenuItem key={profile.id} value={profile.id}>
                    {profile.givenName} {profile.surname}, {profile.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        <FormControl>
          <Select
            value={
              ratingsQuery.bookingPageId === ""
                ? "all"
                : ratingsQuery.bookingPageId
            }
            fullWidth
            size="small"
            variant="outlined"
            labelId="booking-page-id"
            onChange={(evt: any) =>
              onChange({
                ...ratingsQuery,
                bookingPageId: evt.target.value,
              })
            }>
            <MenuItem value={"all"}>Alla kalendrar</MenuItem>
            {bookingPageconfigurations &&
              bookingPageconfigurations.map((bpc) => (
                <MenuItem key={bpc.id} value={bpc.id}>
                  {bpc.friendlyName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        {role === "admin" && (
          <FormControl style={{marginLeft: "20px"}}>
            <Button
              variant="outlined"
              onClick={downloadCsv}
              startIcon={<DownloadingIcon />}>
              CSV
            </Button>
          </FormControl>
        )}
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" justifyContent="flex-end">
          <DateRangeSelector
            defaultMode="month"
            onChange={(range) => {
              onChange({
                ...ratingsQuery,
                from: range.start.toISOString(),
                to: range.end.toISOString(),
              });
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Ratings;
