import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import {Link} from "react-router-dom";
import {Divider} from "../../components/Divider";
import Nav from "../../components/Nav";

const ReportTypes = () => {
  return (
    <Nav
      highlightedSection={"reports"}
      content={
        <>
          <Typography variant="h4">Rapporter</Typography>
          <Divider />
          <Grid container spacing={2} alignItems={"stretch"}>
            <Grid item sm={3} style={{display: "flex"}}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Mötesrapport
                  </Typography>
                  <Typography variant="body1">
                    Sammanställning av bokade möten.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" component={Link} to="/reports/bookings">
                    Visa rapport
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item sm={3} style={{display: "flex"}}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Faktureringsrapport
                  </Typography>
                  <Typography variant="body1">
                    Sammanställning av faktureringsbelopp, månadsvis.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" component={Link} to="/reports/invoicing">
                    Visa rapport
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item sm={3} style={{display: "flex"}}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Beläggningsgrad
                  </Typography>
                  <Typography variant="body1">
                    Sammanställning av beläggningsgrad per medarbetare
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    component={Link}
                    to="/reports/booking-rate">
                    Visa rapport
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item sm={3} style={{display: "flex"}}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Självskattningstest
                  </Typography>
                  <Typography variant="body1">
                    Statistik ifrån självskattningstestet.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" component={Link} to="/reports/quiz">
                    Visa rapport
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item sm={3} style={{display: "flex"}}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Recensioner
                  </Typography>
                  <Typography variant="body1">
                    Statistik ifrån recensioner.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" component={Link} to="/reports/ratings">
                    Visa rapport
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item sm={3} style={{display: "flex"}}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Ärenden
                  </Typography>
                  <Typography variant="body1">
                    Statistik ifrån ärenden.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" component={Link} to="/reports/tasks">
                    Visa rapport
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default ReportTypes;
