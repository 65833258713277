import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {Divider} from "../../components/Divider";
import Nav from "../../components/Nav";
import {YearSelector} from "../../components/dates/YearSelector";
import {useEffect, useState} from "react";
import {GetRatingsStats} from "../../api";
import {RatingsAggregate} from "../../types";
import {MonthNames} from "../../util";

type GroupedRatings = Record<string, Record<string, RatingsAggregate>>;

const RatingsReport = () => {
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [groupedRatings, setGroupedRatings] = useState<GroupedRatings>({});

  useEffect(() => {
    const from = new Date(year, 0, 0, 0, 0, 0, 0).toISOString();
    const to = new Date(year + 1, 0, 0, 0, 0, 0, 0).toISOString();
    GetRatingsStats({from, to}).then((ratingStats) => {
      const groupedRatings: GroupedRatings = {};
      ratingStats.forEach((v) => {
        if (!groupedRatings[v.id.email]) {
          groupedRatings[v.id.email] = {};
        }
        groupedRatings[v.id.email][v.id.month.toString()] = v;
      });
      setGroupedRatings(groupedRatings);
    });
  }, [year]);

  return (
    <Nav
      highlightedSection="reports"
      content={
        <>
          <Grid container>
            <Grid item sm={8}>
              <Typography variant="h4">Recensioner</Typography>
            </Grid>
            <Grid item xs={4} justifyContent="flex-end">
              <div style={{float: "right"}}>
                <YearSelector
                  onChange={setYear}
                  yearMin={2022}
                  yearMax={new Date().getFullYear()}
                  value={year}
                />
              </div>
            </Grid>
          </Grid>
          <Divider />
          <RatingsPersonTable groupedRatings={groupedRatings} />
        </>
      }
    />
  );
};

const RatingsPersonTable: React.FC<{groupedRatings: GroupedRatings}> = ({
  groupedRatings,
}) => {
  const sumTotalCount = (month: number): number => {
    const mk = month.toString();
    return Object.keys(groupedRatings)
      .map((k) => groupedRatings[k][mk]?.count || 0)
      .reduce((p, c) => p + c, 0);
  };

  const sumAvgRating = (month: number): number => {
    const totalCount = sumTotalCount(month);
    if (totalCount === 0) return totalCount;
    const mk = month.toString();
    const totalRating = Object.keys(groupedRatings)
      .map((k) => {
        const v = groupedRatings[k][mk];
        if (!v) return 0;
        return v.avg * v.count;
      })
      .reduce((p, c) => p + c, 0);
    return totalRating / totalCount;
  };
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width={100}>Person</TableCell>
            {MonthNames.map((v, i) => (
              <TableCell width={70} key={i}>
                {v.substring(0, 3)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(groupedRatings).map((k) => (
            <TableRow key={k}>
              <TableCell>{k}</TableCell>
              {MonthNames.map((_, i) => (
                <TableCell style={{borderRight: "1px solid #CCC"}}>
                  {groupedRatings[k][(i + 1).toString()]?.avg.toFixed(2) || ""}/
                  {groupedRatings[k][(i + 1).toString()]?.count || ""}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>Total</TableCell>
            {MonthNames.map((_, i) => (
              <TableCell style={{borderRight: "1px solid #CCC"}}>
                {sumAvgRating(i + 1).toFixed(2)}/{sumTotalCount(i + 1)}
              </TableCell>
            ))}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
export default RatingsReport;
