import {Button, Dialog, DialogContent, Grid, Typography} from "@mui/material";
import {Divider} from "../../components/Divider";
import Nav from "../../components/Nav";
import {useCallback, useEffect, useState} from "react";
import {DeleteRebate, GetRebates} from "../../api";
import {Rebate} from "../../types";
import {CreateOrEditRebateForm} from "../../components/CreateOrEditRebateForm";
import RebatesList from "../../components/RebatesList";

const Rebates = () => {
  const [rebates, setRebates] = useState<Rebate[] | null>(null);
  const [selectedRebate, setSelectedRebate] = useState<Rebate | null>(null);
  const loadRebates = useCallback(async () => {
    const rebates = await GetRebates({});
    setRebates(rebates);
  }, []);
  const [createRebateFormModalOpen, setCreateRebateFormModalOpen] =
    useState(false);
  useEffect(() => {
    loadRebates();
  }, [loadRebates]);

  const onRebateSelectedForDelete = async (rebate: Rebate) => {
    if (
      !window.confirm(
        "Är du säker på att du vill ta bort denna rabattkoden? Åtgärden är permanent och kan inte ångras."
      )
    ) {
      return;
    }
    await DeleteRebate(rebate.id);
    loadRebates();
  };

  return (
    <Nav
      highlightedSection={"settings"}
      content={
        <>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h4">Rabattkoder</Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setCreateRebateFormModalOpen(true)}>
                  Skapa ny rabattkod
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Divider />
          {rebates && (
            <RebatesList
              rebates={rebates}
              onRebateSelectedForDelete={onRebateSelectedForDelete}
              onRebateSelected={setSelectedRebate}
            />
          )}
          {(createRebateFormModalOpen || selectedRebate) && (
            <Dialog
              open={true}
              fullScreen={false}
              fullWidth
              maxWidth="md"
              onClose={() => setCreateRebateFormModalOpen(false)}>
              <DialogContent>
                <CreateOrEditRebateForm
                  rebateToEdit={selectedRebate}
                  onSubmitted={() => {
                    loadRebates();
                    setSelectedRebate(null);
                    setCreateRebateFormModalOpen(false);
                  }}
                />
              </DialogContent>
            </Dialog>
          )}
        </>
      }
    />
  );
};

export default Rebates;
