import {TextareaAutosize, Button, ButtonGroup} from "@mui/material";
import React, {useEffect, useState} from "react";
import {GetMessageTemplates, PostMessage} from "../api";
import {useClaimsMust} from "../hooks/useClaims";
import {MessageReferenceType} from "../types";
import MessageTemplateSelectorDialog from "./MessageTemplateSelectorDialog";
import ArticleIcon from "@mui/icons-material/Article";

interface CreateMessageFormParams {
  personalNumber: string;
  referenceType?: MessageReferenceType;
  referenceId?: string;
  preselectedTemplate?: string;
  onMessagePosted: () => void;
}

export const MessageForm: React.VFC<CreateMessageFormParams> = ({
  personalNumber,
  referenceType,
  referenceId,
  onMessagePosted,
  preselectedTemplate,
}) => {
  const claims = useClaimsMust();
  const [messageTemplateSelectorVisible, setMessageTemplateSelectorVisible] =
    useState(false);

  const [body, setBody] = useState<string>("");
  const isValid = body !== "";

  const submitMessage = async () => {
    if (!isValid) {
      return;
    }
    const message = {body, personalNumber, referenceType, referenceId};
    await PostMessage(message);
    onMessagePosted();
  };

  const setBodyWithReplacedParameters = (value: string) => {
    setBody(
      value.replace("{sender_name}", claims.givenName + " " + claims.surname)
    );
    setMessageTemplateSelectorVisible(false);
  };

  const messageTemplateSelected = (template: string) => {
    if (template !== "") {
      setBodyWithReplacedParameters(template);
    }
    setMessageTemplateSelectorVisible(false);
  };

  const setPredefinedMessage = async () => {
    const messageTemplates = await GetMessageTemplates();
    const preselectedMessageTemplate = messageTemplates.find(
      (p) => p.key === preselectedTemplate
    );
    setBodyWithReplacedParameters(preselectedMessageTemplate?.message || "");
  };

  useEffect(() => {
    if (preselectedTemplate) {
      setPredefinedMessage();
    }
  }, []);

  return (
    <>
      {messageTemplateSelectorVisible && (
        <MessageTemplateSelectorDialog
          onTemplateSelected={messageTemplateSelected}
        />
      )}
      <Button
        startIcon={<ArticleIcon />}
        onClick={() => setMessageTemplateSelectorVisible(true)}>
        Använd en mall
      </Button>
      <TextareaAutosize
        minRows={5}
        value={body}
        onChange={(evt) => setBody(evt.target.value)}
        placeholder="Skriv ett nytt meddelande här"
        style={{width: "100%", marginTop: "5px"}}
      />
      <ButtonGroup>
        <Button
          color="primary"
          variant="contained"
          disabled={!isValid}
          onClick={submitMessage}>
          Skicka meddelande
        </Button>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => setBody("")}
          disabled={!isValid}>
          Rensa
        </Button>
      </ButtonGroup>
      <span style={{paddingLeft: "20px", fontSize: "12px", color: "#666"}}>
        Meddelanden skickas till patient via "Mina sidor".
      </span>
    </>
  );
};
