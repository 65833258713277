export interface RatingStarsProps {
  rating?: number;
}

export const RatingStars: React.VFC<RatingStarsProps> = ({ rating }) => {
  if (!rating) return <></>;
  let stars = "";
  for (let i = 0; i < rating; i++) {
    stars += "⭐";
  }
  return <>{stars}</>;
};
