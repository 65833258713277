import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {InvoiceItem, Rebate} from "../types";
import {L10nDate} from "./Date";
import {useEffect, useState} from "react";
import {GetInvoiceItems} from "../api";

interface RebatesListProps {
  rebates: Rebate[];
  onRebateSelected: (rebate: Rebate) => void;
  onRebateSelectedForDelete: (rebate: Rebate) => void;
}

const RebatesList: React.VFC<RebatesListProps> = ({
  rebates,
  onRebateSelected,
  onRebateSelectedForDelete,
}) => {
  const [invoiceItems, setInvoiceItems] = useState<InvoiceItem[]>([]);
  useEffect(() => {
    GetInvoiceItems().then(setInvoiceItems);
  }, []);

  const getInvoiceItemTitle = (productId: string): string => {
    return (
      invoiceItems.find((p) => p.itemNumber === productId)?.title || productId
    );
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Rabattkod</TableCell>
              <TableCell>Giltig f om</TableCell>
              <TableCell>Giltig t om</TableCell>
              <TableCell>Värde</TableCell>
              <TableCell>Produkter</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rebates.map((rebate, index) => (
              <TableRow key={index}>
                <TableCell>{rebate.code}</TableCell>
                <TableCell>
                  <L10nDate
                    dateTime={rebate.startsAt}
                    options={{dateStyle: "short", timeStyle: "short"}}
                  />
                </TableCell>
                <TableCell>
                  <L10nDate
                    dateTime={rebate.endsAt}
                    options={{dateStyle: "short", timeStyle: "short"}}
                  />
                </TableCell>
                <TableCell>
                  {rebate.value}
                  {rebate.isPercentage ? "%" : " kr"}
                </TableCell>{" "}
                <TableCell>
                  {invoiceItems &&
                    rebate.allowedProductIds.map((productId) => (
                      <div key={productId}>
                        {getInvoiceItemTitle(productId)}
                      </div>
                    ))}
                </TableCell>
                <TableCell align="right">
                  <Button
                    color="error"
                    onClick={() => onRebateSelectedForDelete(rebate)}>
                    Ta bort
                  </Button>
                  <Button color="info" onClick={() => onRebateSelected(rebate)}>
                    Ändra
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RebatesList;
