import {useState} from "react";
import {useLocation} from "react-router-dom";
import CallModal from "../components/CallModal";
import Nav from "../components/Nav";

const Call = () => {
  const [callModalOpen, setCallModalOpen] = useState(true);
  const search = useLocation().search;
  const telephoneNumber = new URLSearchParams(search).get("telephoneNumber");
  return (
    <Nav
      highlightedSection="none"
      disableDrawer={true}
      disableBarItems={true}
      content={
        <>
          {callModalOpen && (
            <CallModal
              telephoneNumber={telephoneNumber}
              onClose={() => setCallModalOpen(false)}
            />
          )}
          {!callModalOpen && (
            <p>Samtal avslutades, du kan stänga denna fliken</p>
          )}
        </>
      }></Nav>
  );
};

export default Call;
