import { Button, FormControl, Grid } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { PutProfilePicture } from "../api";
import { Profile } from "../types";

interface ProfilePIctureFormProps {
  profile: Profile;
  onSubmitted: () => void;
}

const ProfilePictureForm: React.VFC<ProfilePIctureFormProps> = ({
  profile,
  onSubmitted,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [valid, setValid] = useState(false);
  const [previewSource, setPreviewSource] = useState<string | null>(null);

  const onFileChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    const file = evt.target.files?.[0] || null;
    if (!file) return;
    if (!file.name.endsWith("jpeg") && !file.name.endsWith("jpg")) {
      alert("Välj en jpeg-fil");
      return;
    }
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewSource(reader.result as string | null);
    };
    reader.readAsDataURL(file);
  };

  const validateImage = (evt: React.SyntheticEvent<HTMLImageElement>) => {
    if (evt.currentTarget.width !== 500) {
      alert("bilden måste vara 500 pixlar bred");
      setPreviewSource(null);
      return;
    }
    if (evt.currentTarget.width !== 500) {
      alert("bilden måste vara 500 pixlar hög");
      setPreviewSource(null);
      return;
    }
    setValid(true);
  };

  const submitFile = async () => {
    if (!selectedFile) return;
    try {
      await PutProfilePicture(profile.id, selectedFile);
      onSubmitted();
    } catch (error) {
      alert("Något gick fel, var god försök igen");
      setPreviewSource(null);
    }
  };
  return (
    <Grid container>
      {previewSource && (
        <>
          <Grid container>
            <img
              src={previewSource}
              alt="Förhandsgranska profilbild"
              style={{
                maxWidth: "500px",
                maxHeight: "500px",
                marginBottom: "20px",
              }}
              onLoad={validateImage}
            />
          </Grid>
          <Button variant="contained" disabled={!valid} onClick={submitFile}>
            Ladda upp
          </Button>
        </>
      )}
      {!previewSource && (
        <FormControl fullWidth>
          <Button variant="contained" component="label">
            Välj ny profilbild
            <input type="file" hidden onChange={onFileChanged} />
          </Button>
          <p>Bilden måste vara 500x500 pixlar och i JPEG format</p>
        </FormControl>
      )}
    </Grid>
  );
};

export default ProfilePictureForm;
