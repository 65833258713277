import {
  Typography,
  Card,
  FormControl,
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {GetMessages} from "../api";
import {Message} from "../types";
import {MessageForm} from "./MessageForm";
import MessageList from "./MessageList";

interface MessageFormAndListComboProps {
  personalNumber: string;
}

const MessageFormAndList: React.VFC<MessageFormAndListComboProps> = ({
  personalNumber,
}) => {
  const [messages, setMessages] = useState<Message[] | null>(null);
  const [messagePosted, setMessagePosted] = useState<boolean>(false);
  const [includeDeleted, setIncludeDeleted] = useState<boolean>(false);

  const loadMessages = useCallback(() => {
    GetMessages({personalNumber, includeDeleted}).then(setMessages);
  }, [personalNumber, includeDeleted]);

  useEffect(() => {
    loadMessages();
  }, [personalNumber, loadMessages]);

  return (
    <>
      <Card style={{padding: "20px", marginBottom: "20px"}}>
        {!messagePosted && (
          <MessageForm
            personalNumber={personalNumber}
            onMessagePosted={() => {
              setMessagePosted(true);
              loadMessages();
            }}
          />
        )}
        {messagePosted && <span>Ditt meddelande är nu skickat</span>}
      </Card>
      <Card style={{padding: "20px"}}>
        <Grid container>
          <Grid item sm={7}>
            <Typography variant="h5">Tidigare meddelanden</Typography>
          </Grid>
          <Grid item sm={5}>
            <Box display="flex" justifyContent="right">
              <FormControl style={{marginLeft: "20px"}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={includeDeleted}
                      onChange={(evt) => setIncludeDeleted(evt.target.checked)}
                    />
                  }
                  label="Visa borttagna meddelanden"
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        {messages && (
          <>
            {messages.length === 0 && <span>Inga meddelanden</span>}
            {messages.length > 0 && (
              <>
                <MessageList
                  messages={messages}
                  onMessageDeleted={loadMessages}
                />
              </>
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default MessageFormAndList;
