import React, {useEffect, useState} from "react";

import {BoxModal} from "./BoxModal";
import {
  Booking,
  BookingPageConfiguration,
  BookingReason,
  Profile,
} from "../types";
import {Button, ButtonGroup, LinearProgress} from "@mui/material";
import {GetBookingPageConfigurations, GetProfiles} from "../api";
import {
  MedicalRolesFriendlyName,
  isPast,
  localizeBookingReason,
  unique,
} from "../util";

interface Props {
  profile: Profile;
  bookings: Booking[];
  onClose: () => void;
}

const BookingLinksModal: React.VFC<Props> = ({profile, bookings, onClose}) => {
  const [bookingPageConfigurations, setBookingPageConfigurations] = useState<
    BookingPageConfiguration[] | null
  >(null);
  const [bookingPageConfiguration, setBookingPageConfiguration] =
    useState<BookingPageConfiguration | null>(null);
  const [reason, setReason] = useState<BookingReason | null>(null);
  const pastUncancelledBookingReasons = bookings
    .filter(isPast)
    .filter((b) => b.cancelled === false)
    .map((b) => b.reason)
    .filter(unique);
  const [staff, setStaff] = useState<Profile[] | null>(null);

  useEffect(() => {
    GetBookingPageConfigurations().then(setBookingPageConfigurations);
  }, []);

  useEffect(() => {
    if (!bookingPageConfiguration) {
      return;
    }
    GetProfiles({
      medicalRoles: bookingPageConfiguration.medicalRoles,
      limit: 100, // TODO: Pagination or search when we have many doctors
    }).then(setStaff);
  }, [bookingPageConfiguration]);

  const gotoUrlAndClose = (url: string) => {
    window.open(url, "_blank");
    onClose();
  };

  const openStaffURL = (profileId: string) => {
    const url = `${process.env.REACT_APP_APP_URL}/calendar/${bookingPageConfiguration?.urlSlug}?reason=${reason}&utm_source=admin&showProfile=true&patientProfileId=${profile.id}&profileId=${profileId}`;
    gotoUrlAndClose(url);
  };

  const openSlotKindURL = () => {
    const url = `${process.env.REACT_APP_APP_URL}/calendar/${bookingPageConfiguration?.urlSlug}?reason=${reason}&utm_source=admin&showProfile=true&patientProfileId=${profile.id}`;
    gotoUrlAndClose(url);
  };

  return (
    <BoxModal open={true} onClose={onClose}>
      {bookingPageConfiguration && reason && (
        <>
          {!staff && <LinearProgress />}
          {staff && (
            <>
              <ButtonGroup
                variant="contained"
                orientation="vertical"
                color="inherit">
                {staff.map((staffMember) => (
                  <Button
                    onClick={() => openStaffURL(staffMember.id)}
                    style={{
                      borderBottom: "1px solid #CCC",
                      borderTopLeftRadius: "0",
                      borderTopRightRadius: "0",
                    }}>
                    {staffMember.givenName} {staffMember.surname},{" "}
                    {MedicalRolesFriendlyName(staffMember.medicalRoles)}
                  </Button>
                ))}
                <Button
                  onClick={() => openSlotKindURL()}
                  color="primary"
                  style={{
                    borderBottom: "1px solid #CCC",
                    borderTopLeftRadius: "0",
                    borderTopRightRadius: "0",
                  }}>
                  Visa samtliga{" "}
                  {MedicalRolesFriendlyName(
                    bookingPageConfiguration.medicalRoles
                  )}
                </Button>
              </ButtonGroup>
              <Button
                onClick={() => setReason(null)}
                fullWidth
                style={{marginTop: "20px"}}>
                &laquo; Välj anlednig
              </Button>
            </>
          )}
        </>
      )}
      {bookingPageConfiguration && !reason && (
        <>
          <h3>Anledning för besök</h3>
          <ButtonGroup
            variant="contained"
            orientation="vertical"
            color="inherit"
            fullWidth>
            <Button
              onClick={() => setReason("menopause")}
              style={{
                borderBottom: "1px solid #CCC",
                borderTopLeftRadius: "0",
                borderTopRightRadius: "0",
              }}>
              Klimakteriet
            </Button>
            <Button
              onClick={() => setReason("pms")}
              style={{
                borderBottom: "1px solid #CCC",
                borderTopLeftRadius: "0",
                borderTopRightRadius: "0",
              }}>
              PMS
            </Button>
          </ButtonGroup>
          {pastUncancelledBookingReasons.length > 0 && (
            <div style={{marginTop: "20px"}}>
              {profile.givenName} har tidigare sökt vård för{" "}
              {pastUncancelledBookingReasons
                .map(localizeBookingReason)
                .join(", ")}
            </div>
          )}
          <Button
            onClick={() => setBookingPageConfiguration(null)}
            fullWidth
            style={{marginTop: "20px"}}>
            &laquo; Välj besökstyp
          </Button>
        </>
      )}
      {!bookingPageConfiguration && !reason && bookingPageConfigurations && (
        <ButtonGroup
          variant="contained"
          orientation="vertical"
          color="inherit"
          fullWidth>
          {bookingPageConfigurations
            .filter((b) => b.isRevisit)
            .map((bpc) => (
              <Button
                key={bpc.id}
                onClick={() => setBookingPageConfiguration(bpc)}
                style={{
                  borderBottom: "1px solid #CCC",
                  borderTopLeftRadius: "0",
                  borderTopRightRadius: "0",
                }}
                color="primary">
                {bpc.friendlyName}
              </Button>
            ))}

          {bookingPageConfigurations
            .filter((b) => !b.isRevisit)
            .map((bpc) => (
              <Button
                key={bpc.id}
                onClick={() => setBookingPageConfiguration(bpc)}
                style={{
                  borderBottom: "1px solid #CCC",
                  borderTopLeftRadius: "0",
                  borderTopRightRadius: "0",
                }}
                color="inherit">
                {bpc.friendlyName}
              </Button>
            ))}
        </ButtonGroup>
      )}
    </BoxModal>
  );
};

export default BookingLinksModal;
