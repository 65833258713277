import {
  Button,
  ButtonGroup,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {useEffect, useState} from "react";
import {ConfirmFile, GetFiles} from "../api";
import {FileWithProfile} from "../types";
import Nav from "../components/Nav";

const Files = () => {
  const [files, setFiles] = useState<FileWithProfile[] | null>(null);

  useEffect(() => {
    GetFiles({
      acknowledged: false,
    }).then(setFiles);
  }, []);

  const confirmFile = async (id: string) => {
    if (!window.confirm("Markera filen som hanterad?")) return;
    await ConfirmFile(id);
    window.location.href = "/files";
  };

  if (!files) {
    return <Nav highlightedSection="files" content={<CircularProgress />} />;
  }

  return (
    <Nav
      highlightedSection="files"
      content={
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Uppladdad av</TableCell>
                <TableCell>Info</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map((file, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {file.profile && (
                      <>
                        <a href={`/profiles/${file.file.personalNumber}`}>
                          {" "}
                          {file.profile.givenName} {file.profile.surname}
                        </a>
                        <br />
                        {file.profile.email} <br />
                        {file.profile.telephoneNumber}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    <time>{file.file.uploadedAt}</time>
                    <br />
                    {file.file.name}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    <ButtonGroup variant="contained">
                      <Button
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_API_URL}/file-serve/${file.file.id}/${file.file.name}`,
                            "_blank"
                          )
                        }
                        color="inherit"
                        style={{display: "block"}}>
                        Visa filen
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => confirmFile(file.file.id)}>
                        Bekräfta hanterad
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    />
  );
};

export default Files;
