import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Nav from "../../components/Nav";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {BookingPageConfiguration} from "../../types";
import {GetBookingPageConfigurations} from "../../api";

const BookingPageConfigurations = () => {
  const [bookingPageConfigurations, setBookingPageConfigurations] = useState<
    BookingPageConfiguration[] | null
  >(null);
  useEffect(() => {
    GetBookingPageConfigurations().then(setBookingPageConfigurations);
  }, []);
  return (
    <Nav
      highlightedSection={"settings"}
      content={
        <>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h4">Bokningssidor</Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/settings/booking-page-configurations/create">
                  Skapa ny bokingssida
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{marginBottom: "20px"}} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>URL</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bookingPageConfigurations &&
                bookingPageConfigurations.map((bpc) => (
                  <TableRow key={bpc.id}>
                    <TableCell>{bpc.urlSlug}</TableCell>
                    <TableCell>{bpc.title}</TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={`/settings/booking-page-configurations/${bpc.id}`}>
                        Redigera
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </>
      }
    />
  );
};

export default BookingPageConfigurations;
