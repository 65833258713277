import {
  Button,
  ButtonGroup,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {
  getDatesFromWeekNumber,
  getWeekNumber,
  getYearsInRange,
  maxWeekNumber,
} from "../../util";

const buildWeekList = (year: number) => {
  let max = maxWeekNumber(year);
  const numbers = [];
  for (let i = 1; i <= max; i++) {
    numbers.push(i);
  }
  return numbers;
};

interface WeekSelectorChangedEvent {
  year: number;
  week: number;
  start: Date;
  end: Date;
}

interface WeekSelectorProps {
  yearMin?: number;
  yearMax?: number;
  onChange: (evt: WeekSelectorChangedEvent) => void;
}

const WeekSelector: React.VFC<WeekSelectorProps> = ({
  onChange,
  yearMin,
  yearMax,
}) => {
  const currentYear = new Date().getFullYear();
  const currentWeekNumber = getWeekNumber(new Date());
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedWeek, setSelectedWeek] = useState(currentWeekNumber);
  const weeks = buildWeekList(selectedYear);
  const years = getYearsInRange(
    yearMin || 2021,
    yearMax || new Date().getFullYear() + 1
  );
  const handleYearChanged = (evt: SelectChangeEvent<number>) => {
    setSelectedYear(Number(evt.target.value));
    setSelectedWeek(1);
  };

  const navigateToNextWeek = () => {
    let nextWeek = selectedWeek + 1;
    if (nextWeek > maxWeekNumber(selectedYear)) {
      const nextYear = selectedYear + 1;
      setSelectedYear(nextYear);
      nextWeek = 1;
    }
    setSelectedWeek(nextWeek);
  };

  const navigateToPrevWeek = () => {
    let prevWeek = selectedWeek - 1;
    if (prevWeek === 0) {
      const prevYear = selectedYear - 1;
      setSelectedYear(prevYear);
      prevWeek = maxWeekNumber(selectedYear);
    }
    setSelectedWeek(prevWeek);
  };

  const navigateToNow = () => {
    setSelectedWeek(currentWeekNumber);
    setSelectedYear(currentYear);
  };

  useEffect(
    () => {
      const [start, end] = getDatesFromWeekNumber(selectedYear, selectedWeek);
      onChange({
        start,
        end,
        year: selectedYear,
        week: selectedWeek,
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedWeek, selectedYear]
  );

  return (
    <>
      <FormControl variant="outlined">
        <Select
          size="small"
          value={selectedWeek}
          onChange={(evt) => setSelectedWeek(Number(evt.target.value))}>
          {weeks.map((week) => (
            <MenuItem key={week} value={week}>
              Vecka {week}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined">
        <Select size="small" value={selectedYear} onChange={handleYearChanged}>
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ButtonGroup
        style={{marginLeft: "20px", height: "38px"}}
        variant="outlined">
        <Button color="inherit" onClick={navigateToPrevWeek}>
          &laquo;
        </Button>
        <Button
          color="primary"
          disabled={
            selectedYear === currentYear && selectedWeek === currentWeekNumber
          }
          onClick={navigateToNow}>
          Denna vecka
        </Button>
        <Button color="inherit" onClick={navigateToNextWeek}>
          &raquo;
        </Button>
      </ButtonGroup>
    </>
  );
};

export default WeekSelector;
