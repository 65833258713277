const TabPanel = (props: any) => {
  const {children, value, index} = props;

  return (
    <div role="tabpanel" style={{display: value === index ? "block" : "none"}}>
      {children}
    </div>
  );
};

export default TabPanel;
