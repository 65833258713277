import {FormControl, FormLabel, MenuItem, Select} from "@mui/material";
import {useEffect} from "react";
import {useState} from "react";
import {GetProfiles} from "../api";
import {Profile} from "../types";

interface PersonPickerProps {
  selectedId: string;
  labelTitle: string;
  onChange: (id: string) => void;
}

const PersonPicker: React.VFC<PersonPickerProps> = ({
  onChange,
  selectedId,
  labelTitle,
}) => {
  const [profiles, setProfiles] = useState<Profile[] | null>(null);
  useEffect(() => {
    GetProfiles({
      roles: ["admin", "medical"],
      limit: 999999, // TODO: This should probably be an autocomplete in the future.
    }).then(setProfiles);
  }, []);

  return (
    <FormControl>
      <FormLabel htmlFor="person-picker">{labelTitle}</FormLabel>
      <Select
        id="person-picker"
        fullWidth
        value={selectedId}
        size="small"
        variant="outlined"
        labelId="booked-person-label"
        displayEmpty
        onChange={(evt: any) => onChange(evt.target.value)}>
        <MenuItem value={""}>
          <em>Välj person</em>
        </MenuItem>
        {profiles &&
          profiles.map((profile) => (
            <MenuItem key={profile.id} value={profile.id}>
              {profile.givenName} {profile.surname}, {profile.title}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default PersonPicker;
