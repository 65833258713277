import Nav from "../../components/Nav";
import {useState} from "react";
import {BookingPageConfiguration} from "../../types";
import BookingPageConfigurationForm from "../../components/BookingPageConfigurationForm";
import {Button, ButtonGroup, CircularProgress, Typography} from "@mui/material";
import {Link} from "react-router-dom";

import {Divider} from "../../components/Divider";
import {PostBookingPageConfigurations} from "../../api";

const BookingPageConfigurationCreate = () => {
  const [isPosting, setIsPosting] = useState(false);
  const [bookingPageConfiguration, setBookingPageConfiguration] =
    useState<BookingPageConfiguration>({
      id: "",
      urlSlug: "",
      friendlyName: "",
      kind: "doctor",
      isRevisit: false,
      increment: 0,
      price: 0,
      duration: 0,
      title: "",
      subTitleHTML: "",
      billingProductId: "",
      minNoticeNs: 0,
      minCancellationNoticeNs: 0,
      medicalRoles: [],
      uiSettings: {
        hideProfileTitle: false,
        termsText: "",
        cancellationText: "",
        confirmationPageURL: "",
        disableInvoicing: false,
      },
      emailTemplates: {
        newBooking: "womni-new-booking",
        feedback: "womni-booking-feedback",
        reminder: "womni-booking-reminder",
        rescheduled: "womni-rescheduled-booking",
        cancelled: "womni-cancelled-booking",
      },
      defaultBookingReason: "menopause",
      eventSummaryTitle: "WOMNI",
      revisitRecommendedBookingPageConfigurationId: null,
      revisitInformationText: "",
    });

  const saveBookingPageConfiguration = async () => {
    setIsPosting(true);
    try {
      await PostBookingPageConfigurations(bookingPageConfiguration);
      window.location.href = "/settings/booking-page-configurations";
    } catch (error) {
      window.alert(error);
      setIsPosting(false);
    }
  };

  return (
    <Nav
      highlightedSection={"settings"}
      content={
        <>
          {isPosting && <CircularProgress />}
          {!isPosting && (
            <>
              <Typography variant="h4">Skapa ny bokningssida</Typography>
              <Divider />
              <BookingPageConfigurationForm
                bookingPageConfiguration={bookingPageConfiguration}
                onChange={setBookingPageConfiguration}
              />
              <ButtonGroup variant="contained">
                <Button color="primary" onClick={saveBookingPageConfiguration}>
                  Skapa bokningssida
                </Button>
                <Button
                  color="inherit"
                  component={Link}
                  to="/settings/booking-page-configurations">
                  Avbryt
                </Button>
              </ButtonGroup>
            </>
          )}
        </>
      }
    />
  );
};

export default BookingPageConfigurationCreate;
