import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Input,
  Alert,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {useEffect, useState} from "react";
import {GetBookingPageConfigurations, GetProfiles} from "../api";
import {
  Booking,
  BookingPageConfiguration,
  BookingReason,
  FollowUpQueueItem,
  Profile,
} from "../types";
import {arrayIntersects} from "../util";

export interface FollowUpFormEntry {
  isValid: boolean;
  skipRevisit: boolean;
  after: Date | null;
  notes: string;
  bookingPageId: string | null;
  bookedPersonProfileId: string | null;
  reason: BookingReason;
}

export interface FollowUpFormProps {
  prefillWith?: FollowUpQueueItem;
  bookings: Booking[];
  onChange: (fe: FollowUpFormEntry) => void;
}

const getDateString = (date: Date) => {
  const y = date.getFullYear().toString();
  const m = (date.getMonth() + 1).toString().padStart(2, "0");
  const d = date.getDate().toString().padStart(2, "0");
  return `${y}-${m}-${d}`;
};

interface RevisitRecommendation {
  text: string;
  bookingPageConfiguration: BookingPageConfiguration;
}

const getRevisitRecommendation = (
  bookings: Booking[],
  bookingPageConfigurations: BookingPageConfiguration[]
): RevisitRecommendation | null => {
  const mostRecentBooking = bookings
    .sort(
      (a, b) => new Date(b.startsAt).getTime() - new Date(a.startsAt).getTime()
    )
    .filter((b) => b.cancelled === false)
    .find(Boolean);
  if (!mostRecentBooking) {
    return null;
  }
  const recommendedRevisitBookingPageConfiguration =
    bookingPageConfigurations.find(
      (bpc) =>
        mostRecentBooking.bookingPageConfiguration
          .revisitRecommendedBookingPageConfigurationId === bpc.id
    );
  if (!recommendedRevisitBookingPageConfiguration) {
    return null;
  }
  return {
    text: mostRecentBooking.bookingPageConfiguration.revisitInformationText,
    bookingPageConfiguration: recommendedRevisitBookingPageConfiguration,
  };
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: "20px",
  },
}));

const FollowUpForm: React.VFC<FollowUpFormProps> = ({
  onChange,
  bookings,
  prefillWith,
}) => {
  const css = useStyles();
  const [isValid, setIsValid] = useState(false);
  const [revisitRecommendation, setRevisitRecommendation] =
    useState<RevisitRecommendation | null>(null);
  const [bookedPersonProfileId, setBookedPersonProfileId] = useState<
    string | null
  >(prefillWith?.bookedPersonProfileId || null);
  const [notes, setNotes] = useState(prefillWith?.notes || "");
  const [isDateSelectorVisible, setIsDateSelectorVisible] = useState(
    prefillWith ? true : false
  );
  const [profiles, setProfiles] = useState<Profile[] | null>(null);
  const [selectableProfiles, setSelectableProfiles] = useState<
    Profile[] | null
  >(null);
  const [bookingPageConfigurations, setBookingPageConfigurations] = useState<
    BookingPageConfiguration[] | null
  >(null);
  const [after, setAfter] = useState<Date | null>(
    prefillWith ? new Date(prefillWith.after) : null
  );
  const [reason, setReason] = useState<BookingReason>("menopause");
  const [skipRevisit, setSkipRevisit] = useState<boolean>(false);
  const [selectableFollowUpDatesIndex, setSelectableFollowUpDatesIndex] =
    useState<Number | null>(prefillWith?.after !== undefined ? 5 : null);
  const [bookingPageId, setBookingPageId] = useState<string | null>(
    prefillWith?.bookingPageId || null
  );

  const setAfterByDays = (days: number) => {
    return () => {
      const dt = new Date();
      dt.setDate(dt.getDate() + days);
      setAfter(dt);
      setSkipRevisit(false);
      setIsDateSelectorVisible(false);
    };
  };
  const selectableFollowUpDates = [
    {
      action: setAfterByDays(0),
      label: "Snarast",
    },
    {
      action: setAfterByDays(30),
      label: "Om en månad",
    },
    {
      action: setAfterByDays(30 * 2.5),
      label: "Om två och en halv månad",
    },
    {
      action: setAfterByDays(30 * 9),
      label: "Om 9 månader",
    },
    {
      action: setAfterByDays(345),
      label: "Om 11.5 månader",
    },
    {
      action: () => {
        setIsDateSelectorVisible(true);
        setSkipRevisit(false);
        setAfter(null);
      },
      label: "Välj annat datum",
    },
    {
      action: () => {
        setSkipRevisit(true);
        setIsDateSelectorVisible(false);
        setAfter(new Date());
      },
      label: "Ingen uppföljning krävs",
    },
  ];

  const onFollowUpDaysChanged = (evt: any) => {
    const index = Number(evt.target.value);
    setSelectableFollowUpDatesIndex(index);
    selectableFollowUpDates[index].action();
  };

  useEffect(() => {
    setIsValid(
      skipRevisit === true || (after != null && bookingPageId !== null)
    );
  }, [after, skipRevisit, bookingPageId]);

  useEffect(
    () => {
      onChange({
        after,
        bookedPersonProfileId,
        bookingPageId,
        isValid,
        notes,
        reason,
        skipRevisit,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [after, bookedPersonProfileId, bookingPageId, isValid, notes, skipRevisit]
  );

  useEffect(() => {
    GetProfiles({
      roles: ["admin", "medical"],
      limit: 999999, // TODO: This should probably be an autocomplete in the future.
    }).then(setProfiles);
    GetBookingPageConfigurations().then((bpcs) => {
      const recommendation = getRevisitRecommendation(bookings, bpcs);
      setRevisitRecommendation(recommendation);
      setBookingPageConfigurations(
        bpcs.filter((bpc) => bpc.isRevisit === true)
      );
      if (!bookingPageId && recommendation) {
        setBookingPageId(recommendation.bookingPageConfiguration.id);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!profiles || !bookingPageId) {
      return;
    }
    setBookedPersonProfileId(null);
    setSelectableProfiles(null);
    const selectedBookingPageConfiguration = bookingPageConfigurations?.find(
      (bpc) => bpc.id === bookingPageId
    );
    if (!selectedBookingPageConfiguration) {
      return;
    }
    const filteredProfiles = profiles.filter((profile) =>
      arrayIntersects(
        profile.medicalRoles,
        selectedBookingPageConfiguration.medicalRoles
      )
    );
    setSelectableProfiles(filteredProfiles);
  }, [profiles, bookingPageId, bookingPageConfigurations]);

  if (!bookingPageConfigurations) {
    return <></>;
  }

  return (
    <>
      <FormControl fullWidth className={css.formControl}>
        <InputLabel id="time-label">När skall uppföljning ske</InputLabel>
        <Select
          labelId="time-label"
          variant="standard"
          value={
            selectableFollowUpDatesIndex === null
              ? ""
              : selectableFollowUpDatesIndex
          }
          onChange={onFollowUpDaysChanged}>
          {selectableFollowUpDates.map(({label}, index) => (
            <MenuItem key={index} value={index}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {isDateSelectorVisible && (
        <FormControl fullWidth className={css.formControl}>
          <InputLabel shrink={true}>Välj datum</InputLabel>
          <Input
            type="date"
            value={after ? getDateString(after) : ""}
            onChange={(evt: any) => setAfter(new Date(evt.target.value))}
          />
          <Alert color="info">
            OBS: När mötet bokas kan datum variera beroende på tillgänglighet
            med upp till en vecka före och efter det valda datumet.
          </Alert>
        </FormControl>
      )}
      {skipRevisit === false && (
        <>
          <FormControl fullWidth className={css.formControl}>
            <FormLabel id="booking-page-configuration-label">
              Uppföljningstyp
            </FormLabel>
            <Select
              labelId="booking-page-configuration-label"
              variant="standard"
              value={bookingPageId}
              onChange={(evt) => setBookingPageId(evt.target.value)}>
              {bookingPageConfigurations.map((bpc) => (
                <MenuItem key={bpc.id} value={bpc.id}>
                  {bpc.friendlyName}
                </MenuItem>
              ))}
            </Select>
            {revisitRecommendation && (
              <Alert color="info">
                För patienten rekommenderas återbesök med kalendern{" "}
                {revisitRecommendation.bookingPageConfiguration.friendlyName}{" "}
                <br />
                {revisitRecommendation.text}
              </Alert>
            )}
          </FormControl>
          <FormControl fullWidth className={css.formControl}>
            <FormLabel id="service-type-label">Anledning</FormLabel>
            <RadioGroup
              value={reason}
              onChange={(evt: any) => setReason(evt.target.value)}
              aria-labelledby="service-type-label"
              name="service-type">
              <FormControlLabel
                value="menopause"
                control={<Radio />}
                label="Klimakteriet"
              />
              <FormControlLabel value="pms" control={<Radio />} label="PMS" />
            </RadioGroup>
          </FormControl>
          {selectableProfiles && selectableProfiles.length > 0 && (
            <FormControl fullWidth className={css.formControl}>
              <InputLabel id="booked-person-label">
                Vilken kollega bör hantera uppföljningen (valfri)
              </InputLabel>
              <Select
                variant="standard"
                labelId="booked-person-label"
                value={bookedPersonProfileId || ""}
                onChange={(evt: any) =>
                  setBookedPersonProfileId(evt.target.value)
                }>
                {selectableProfiles.map((profile) => (
                  <MenuItem key={profile.id} value={profile.id}>
                    {profile.givenName} {profile.surname}, {profile.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <FormControl fullWidth className={css.formControl}>
            <TextField
              variant="standard"
              label="Övriga upplysningar i samband med uppföljning (valfri)"
              rows={4}
              multiline
              value={notes}
              onChange={(evt) => setNotes(evt.target.value)}
            />
          </FormControl>
        </>
      )}
    </>
  );
};

export default FollowUpForm;
