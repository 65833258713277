import {
  Tabs,
  Tab,
  Badge,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {
  GetMessages,
  GetNotes,
  GetTaskTemplate,
  GetTasks,
  PostTaskAssignees,
  PutTask,
} from "../api";
import {
  Message,
  Note,
  Task,
  TaskTemplate,
  TaskTemplateField,
  WorkflowAction,
} from "../types";
import {localizeTaskKind} from "../util";
import React from "react";
import {Person} from "@mui/icons-material";
import PersonPicker from "./PersonPicker";
import {L10nDate} from "./Date";
import MessageList from "./MessageList";
import {MessageForm} from "./MessageForm";
import TabPanel from "./TabPanel";
import Notes from "./Notes";
import TaskList from "./TaskList";

export interface TaskModalProps {
  task: Task;
  onClose: () => void;
}

const ActionMessages = {
  message: "Skicka meddelande till patient",
};

const useStyles = makeStyles({
  dialog: {
    position: "absolute",
    left: 10,
    top: 10,
    right: 20,
  },
  taskDetails: {
    borderRadius: "5px",
    background: "#EFEFEF",
    padding: "20px",
  },
  assignments: {
    padding: "10px",
    border: "1px solid #EEE",
    marginBottom: "20px",
  },
});

const TaskModal: React.VFC<TaskModalProps> = (props) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [notes, setNotes] = useState<Note[] | null>(null);
  const [task, setTask] = useState<Task>(props.task);
  const [relatedTasks, setRelatedTasks] = useState<Task[]>([]);
  const [isAssigneeModalOpen, setIsAsigneeModalOpen] = useState(false);
  const {onClose} = props;
  const [taskTemplate, setTaskTemplate] = useState<TaskTemplate | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);

  const loadNotes = useCallback(async () => {
    const notes = await GetNotes({
      personalNumber: task.createdBy.personalNumber,
      taskId: task.id,
    });
    setNotes(notes);
  }, [task]);

  const loadRelatedTasks = useCallback(async () => {
    GetTasks({createdBy: task.createdBy.id, closed: false}).then((tasks) =>
      setRelatedTasks(tasks.filter((t) => t.id !== task.id))
    );
  }, [task]);

  useEffect(() => {
    GetTaskTemplate(task.taskKind).then(setTaskTemplate);

    GetMessages({
      referenceType: "task",
      referenceId: task.id,
    }).then(setMessages);
    loadNotes();
    loadRelatedTasks();
  }, [task, loadNotes, loadRelatedTasks]);

  if (!taskTemplate) {
    return (
      <Dialog
        open={true}
        classes={{
          paper: classes.dialog,
        }}>
        <CircularProgress />
      </Dialog>
    );
  }

  const taskIsCompleted = taskTemplate.workflow.actions.every(
    (a) => task.completedWorkflowActions[a.key] === true
  );

  const unfinishedActions = taskTemplate.workflow.actions.filter(
    (action) => task.completedWorkflowActions[action.key] !== true
  );

  const closeTask = async () => {
    if (!taskIsCompleted) {
      if (
        !window.confirm(
          "Du har inte slutfört ärendet. Är du säker på att du vill avlsuta det?"
        )
      ) {
        return;
      }
    }
    task.closed = true;
    task.closedAt = new Date().toISOString();
    const taskResponse = await PutTask(task.id, task);
    setTask(taskResponse);
  };

  const repoenTask = async () => {
    task.closed = false;
    task.closedAt = undefined;
    const taskResponse = await PutTask(task.id, task);
    setTask(taskResponse);
  };

  const onActionCompleted = async (action: WorkflowAction) => {
    task.completedWorkflowActions[action.key] = true;
    const taskResponse = await PutTask(task.id, task);
    setTask(taskResponse);
  };

  const fieldValue = (field: TaskTemplateField): string => {
    const value = task.fields[field.key];
    return field.translations?.[value] || value;
  };

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth={false}
      onClose={onClose}
      classes={{
        paper: classes.dialog,
      }}>
      <DialogTitle>
        {localizeTaskKind(task.taskKind)}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {isAssigneeModalOpen && (
          <ChangeAssigneeModal
            task={task}
            onClose={(task) => {
              setIsAsigneeModalOpen(false);
              setTask(task);
            }}
          />
        )}
        <Grid container spacing={2}>
          <Grid item sm={8}>
            <div className={classes.taskDetails}>
              <div style={{marginBottom: "5px"}}>
                <Typography variant="subtitle1" sx={{fontWeight: "bold"}}>
                  Datum
                </Typography>

                <L10nDate
                  dateTime={task.createdAt}
                  options={{dateStyle: "short", timeStyle: "short"}}
                />
              </div>
              {taskTemplate.fields
                .filter((field) => task.fields[field.key] !== undefined)
                .map((field, index) => (
                  <div key={index} style={{marginBottom: "5px"}}>
                    <Typography variant="subtitle1" sx={{fontWeight: "bold"}}>
                      {field.title}
                    </Typography>
                    <div>{fieldValue(field)}&nbsp;</div>
                  </div>
                ))}
            </div>

            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                marginBottom: "10px",
              }}>
              <Tabs
                centered
                value={selectedTab}
                onChange={(evt, newValue) => setSelectedTab(newValue)}>
                <Tab
                  label={
                    <>
                      <Badge
                        badgeContent={messages && messages.length}
                        color="info">
                        <div
                          style={{marginRight: "12px", fontSize: "0.875rem"}}>
                          Meddelanden
                        </div>
                      </Badge>
                    </>
                  }
                />
                <Tab
                  label={
                    <>
                      <Badge badgeContent={notes && notes.length} color="info">
                        <div
                          style={{marginRight: "12px", fontSize: "0.875rem"}}>
                          Anteckningar
                        </div>
                      </Badge>
                    </>
                  }
                />
              </Tabs>
            </Box>
            <TabPanel value={selectedTab} index={0}>
              {!task.closed && (
                <>
                  {unfinishedActions.map((action) => (
                    <div key={action.key}>
                      <TaskAction
                        messages={messages}
                        action={action}
                        onActionCompleted={onActionCompleted}
                        task={task}
                      />
                    </div>
                  ))}
                </>
              )}
              {messages.length > 0 && (
                <MessageList messages={messages} onMessageDeleted={() => {}} />
              )}
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              {notes && (
                <div style={{marginTop: "20px"}}>
                  <Notes
                    personalNumber={task.createdBy.personalNumber}
                    notes={notes}
                    taskId={task.id}
                    loadNotes={loadNotes}
                  />
                </div>
              )}
            </TabPanel>
          </Grid>
          <Grid item sm={4}>
            <div className={classes.assignments}>
              <Typography variant="body1">
                Skapad av:
                <br />
                <Link to={`/profiles/${task.createdBy.personalNumber}`}>
                  {task.createdBy.givenName} {task.createdBy.surname}
                </Link>{" "}
                {task.createdBy.personalNumber}
                <br />
                <br />
              </Typography>
              <Typography variant="body1">
                Tilldelad till:
                <br />
                {task.assignees.map((assignee, index) => (
                  <React.Fragment key={index}>
                    <Link
                      key={index}
                      to={`/profiles/${assignee.personalNumber}`}>
                      {assignee.givenName} {assignee.surname}
                    </Link>
                    {` `}
                  </React.Fragment>
                ))}
              </Typography>

              <Button
                startIcon={<Person />}
                onClick={() => setIsAsigneeModalOpen(true)}>
                Ändra tilldelad person
              </Button>
            </div>
            <h3>Checklista för ärende</h3>
            <List>
              {taskTemplate.workflow.actions.map((action) => (
                <ListItem key={action.key} disablePadding>
                  <Checkbox
                    disabled
                    checked={task.completedWorkflowActions[action.key] === true}
                  />
                  <ListItemText primary={ActionMessages[action.key]} />
                </ListItem>
              ))}
            </List>
            {relatedTasks.length > 0 && (
              <>
                <h3>Fler öppna ärenden av {task.createdBy.givenName}</h3>
                <TaskList
                  onSortChanged={() => {}}
                  tasks={relatedTasks}
                  hideColumns={["created-by", "assigned-to", "date"]}
                  onTaskReloadRequired={loadRelatedTasks}
                />
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {!task.closed && (
          <Button variant="contained" color="primary" onClick={closeTask}>
            Avsluta ärende
          </Button>
        )}

        {task.closed && (
          <Button variant="contained" color="primary" onClick={repoenTask}>
            Öppna ärendet
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

interface ChangeAssigneeModalProps {
  onClose: (task: Task) => void;
  task: Task;
}

const ChangeAssigneeModal: React.VFC<ChangeAssigneeModalProps> = ({
  onClose,
  task,
}) => {
  const [selectedProfileId, setSelectedProfileId] = useState<string | null>(
    task.assignees.length === 0 ? null : task.assignees[0].id
  );

  const submit = async () => {
    if (!selectedProfileId) {
      return;
    }
    const taskResponse = await PostTaskAssignees(task.id, [selectedProfileId]);
    onClose(taskResponse);
  };

  return (
    <Dialog open={true} fullWidth maxWidth="sm" onClose={() => onClose(task)}>
      <DialogTitle>
        Tilldela ärende{" "}
        <IconButton
          aria-label="close"
          onClick={() => onClose(task)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <PersonPicker
          labelTitle="Välj person att tilldela ärendet till"
          selectedId={selectedProfileId || ""}
          onChange={(v) =>
            v === "" ? setSelectedProfileId(null) : setSelectedProfileId(v)
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={submit}
          disabled={selectedProfileId === null}>
          Tilldela
        </Button>
        <Button onClick={() => onClose(task)}>Avbryt</Button>
      </DialogActions>
    </Dialog>
  );
};

interface TaskActionProps {
  action: WorkflowAction;
  messages: Message[];
  task: Task;
  onActionCompleted: (action: WorkflowAction) => void;
}
const TaskAction: React.VFC<TaskActionProps> = (props) => {
  switch (props.action.key) {
    case "message":
      return <MessageAction {...props} />;
  }
};

const MessageAction: React.VFC<TaskActionProps> = ({
  action,
  onActionCompleted,
  task,
}) => {
  return (
    <>
      <MessageForm
        referenceType={"task"}
        referenceId={task.id}
        personalNumber={task.createdBy.personalNumber}
        preselectedTemplate={action.params["template"]}
        onMessagePosted={() => onActionCompleted(action)}
      />
    </>
  );
};

export default TaskModal;
